import { useState } from 'react';
import styles from './profile-form-input.module.scss';
import { ProfileFormInputProps } from './profile-form-input.types';
import { Button } from '../../common';


export const ProfileFormInput = ({ 
  isPassword = false, 
  label, 
  placeholder, 
  value = '', 
  type = 'text', 
  name,
  autoComplete = 'off', 
  onChange,
  ...props }: ProfileFormInputProps) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const passwordShow = isPasswordShow ? 'text' : 'password';


  return (
    <label className={styles['profile-form-input-container']}>
      {label}
      <input
        className={styles['profile-form-input']}
        type={isPassword ? passwordShow : type}
        placeholder={placeholder}
        value={value}
        name={name}
        autoComplete={autoComplete}
        onChange={onChange}
        {...props}
      />
      {isPassword && (
        <>
          <Button
            onlyIcon
            className={styles['profile-form-input-icon-password']}
            typeBtn={'uncolored'}
            onClick={() => setIsPasswordShow(!isPasswordShow)}
            showLeftIcon
            leftIcon={
              isPasswordShow ? (
                <i className={'icon icon-show icon-middle'} />
              ) : <i className={'icon icon-hide icon-middle'} />
            } 
          />
        </>
      )}
    </label>
  );
};
