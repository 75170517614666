import React, { forwardRef, useRef, useState } from 'react';
import { BottomSheet as ReactBottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import './bottom-sheet.scss';
import { ButtonSheetHeader } from './button-sheet-header';
import type { BottomSheetProps, ISnapPoints } from './bottom-sheet.types';


const bottomIndex = 0.48;
const paddingTop = 16;
const defaultMinHeightValue = 300;

export const BottomSheet = forwardRef(({
  children, 
  open, 
  title, 
  backButton,
  closeButton,
  subtitle,
  snapPoints, 
  blocking = false,
  defaultSnap,
  knob = true,
  initialHeight = 'min',
  isActive = true,
  header,
  expandOnContentDrag = true,
  scrollLocking = true,
  onSpringEnd = () => {},
  onBack = () => {},
  ...props 
}: BottomSheetProps, ref: any) => {
  const bottonSheetRef = useRef<BottomSheetRef | null>(null);
  const [
    isBottomSheetBlockingAfterMaxScroll, 
    setBottomSheetBlockingAfterMaxScroll,
  ] = useState<boolean>(false);
  const [isFullScreenOpened, setFullScreenOpened] = useState<boolean>(false);
  
  const defaultPropsBehavior = () => {
    if (!defaultSnap && !snapPoints && initialHeight === 'min') return true;
    return false;
  };

  const isBlocking = () => {
    if (blocking) return true;
    if (isBottomSheetBlockingAfterMaxScroll) return true;
    return false;
  };

  const defaultInitialPoints = ({ minHeight, maxHeight }: ISnapPoints) => {

    if (initialHeight === 'min' || initialHeight === 'minimum') {
      return minHeight * bottomIndex;
    }
    if (initialHeight === 'mid' || initialHeight === 'middle') {
      return maxHeight / 2.2;
    }
    if (initialHeight === 'max' || initialHeight === 'maximum') {
      return maxHeight - paddingTop;
    }
  };

  const defaultSnapPoints = ({ minHeight, maxHeight }: ISnapPoints) => {
    if (initialHeight === 'min' || initialHeight === 'minimum') {
      return [minHeight, !isActive ? defaultMinHeightValue : maxHeight - paddingTop];
    }
    if (initialHeight === 'mid' || initialHeight === 'middle') {
      return [
        isActive ? defaultMinHeightValue : maxHeight / 2.2, 
        isActive ? maxHeight - paddingTop : maxHeight / 2.2,
      ];
    }
    if (initialHeight === 'max' || initialHeight === 'maximum') {
      setBottomSheetBlockingAfterMaxScroll(true);
      return [
        isActive ? defaultMinHeightValue : maxHeight - paddingTop,
        maxHeight - paddingTop,
      ];
    }
    return [maxHeight * bottomIndex, maxHeight - paddingTop];
  };

  const endedSpringHandler = () => {
    if ((bottonSheetRef.current || ref.current) && window) {
      if ((bottonSheetRef.current || ref.current).height >= window.innerHeight - paddingTop) {
        setFullScreenOpened(true);
        setBottomSheetBlockingAfterMaxScroll(true);
      } else {
        setFullScreenOpened(false);
        setBottomSheetBlockingAfterMaxScroll(false);
      }
    }
  };

  return (
    <ReactBottomSheet 
      className={!knob ? 'knob-disabled' : ''}
      ref={ref || bottonSheetRef}
      open={open} 
      expandOnContentDrag={expandOnContentDrag}
      onSpringEnd={() => {
        endedSpringHandler();
        onSpringEnd();
      }}
      scrollLocking={scrollLocking}
      defaultSnap={defaultSnap || defaultInitialPoints}
      snapPoints={snapPoints || defaultSnapPoints}
  
      header={
        header ? header :
        <ButtonSheetHeader
          fullscreen={isFullScreenOpened}
          title={title}
          backButton={backButton}
          closeButton={closeButton}
          subtitle={subtitle}
          onClose={props.onDismiss}
          onBack={onBack}
        />
        
      }
      blocking={isBlocking()}
      {...props} 
    >
      {children}
    </ReactBottomSheet>
  );
});
