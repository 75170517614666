import { useState } from 'react';
import styles from './profile-menu-user-page.module.scss';
import { BottomSheet } from '../../common/bottom-sheet';  
import { ProfileMenuButtons } from './components';
import { Button } from '../../common';  


export const ProfileMenuUserPageMobile = () => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  
  return  (
    <div className={styles['profile-menu']}>
      <Button
        size={'s'}
        onlyIcon
        showLeftIcon
        leftIcon={<i className={'icon icon-list icon-tiny'} />}
        style={{ width: 'fit-content' }}
        onClick={() => {
          setIsMenuOpened(!isMenuOpened);
        }}
        elementType={'button'}
        typeBtn={'elevation'}
      />
      <BottomSheet
        open={isMenuOpened}
        onDismiss={() => {
          setIsMenuOpened(false);
        }}
        blocking
      >
        <div className={styles['container-mobile-content']}>
          <ProfileMenuButtons />
        </div>
      </BottomSheet>
    </div>
  );

};
