import { LinesItemProps } from './monitoring-view-item.types';
import styles from './monitoring-view-item.module.scss';
import { PTag, SpanTag } from '../common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  LINK_GROUP_LINE_PATH,
  LINK_LINE_PATH,
  LINK_MAP_OBJECT_GROUP_PATH,
  ScreenSize,
} from '../../constants';
import emptyPhotoListImage from '../../images/object-bg-image.png';
import { formatDate } from '../../utils';
import { useBreakpointsComparison } from '../../hooks';
import { SELECT, SELECT_GROUP } from './constants';

export const MonitoringViewItem = ({
  object,
  isGroup,
  isActive,
  lastDate = null,
}: LinesItemProps) => {
  const { objectID, groupID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  const openLine = () => {
    if (isGroup) {
      navigate({ pathname: LINK_GROUP_LINE_PATH(String(objectID), object.id) });
    } else {
      navigate({
        pathname: LINK_LINE_PATH(String(objectID), object.projectId, object.id),
      });
    }
  };

  const openGroup = () => {
    if (isGroup) {
      navigate({
        pathname: String(LINK_MAP_OBJECT_GROUP_PATH(String(objectID), String(object.id))),
        search: location.search.toString(),
      });
    }
  };

  const updateDate =
    lastDate ||
    ((object.contentLastUpdated.length
      ? object.contentLastUpdated
      : object.updatedAt) as string);

  return (
    <li
      className={`
      ${styles['lines-item']} 
      ${isActive ? styles['lines-item_active'] : ''}
    `}
      onClick={() => {
        isGroup ? openGroup() : openLine();
      }}
    >
      <div
        className={styles['lines-item__image']}
        style={{
          backgroundImage:
            object.image && object.image.tmb
              ? `url(${String(object.image.tmb)})`
              : `url(${String(emptyPhotoListImage)})`,
        }}
      >
        <div className={styles['lines-item__image-overlay']}>
          {!isTabletLandscape && isGroup && (
            <>
              <i className={'icon icon-map-pin icon-middle'} />
              <SpanTag>{SELECT_GROUP}</SpanTag>
            </>
          )}
          {!isTabletLandscape && !isGroup && (
            <>
              <i className={'icon icon-show icon-middle'} />
              <SpanTag>{SELECT}</SpanTag>
            </>
          )}
        </div>
        <div className={styles['lines-item__image-date-label']}>
          {!isTabletLandscape ? (
            <SpanTag
              className={styles['lines-item__image-date-label-span']}
              type={'caption'}
              x={'3'}
              weight={'1'}
            >
              Обновлено {formatDate(updateDate)}
            </SpanTag>
          ) : (
            <SpanTag className="" type={'caption'} x={2.75} weight={2}>
              {isTabletLandscape && isGroup && `${SELECT_GROUP}`}
              {isTabletLandscape && !isGroup && `${SELECT}`}
            </SpanTag>
          )}
        </div>
      </div>
      <PTag
        className={`
        ${styles['lines-item__name']}
        ${isActive ? styles['lines-item__name_active'] : ''}
        `}
        x={3.5}
        weight={2}
      >
        {object.name}
      </PTag>
      {isTabletLandscape && (
        <SpanTag
          className={styles['lines-item__mobile-date']}
          type={'caption'}
          x={3}
          weight={1}
        >
          {`Обновлено ${formatDate(
            lastDate ||
              (object.contentLastUpdated.length
                ? object.contentLastUpdated
                : object.updatedAt),
          )}`}
        </SpanTag>
      )}
    </li>
  );
};
