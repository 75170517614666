import { ProfileMenuMapPageProps } from './profile-menu-map-page.types';
import { useBreakpointsComparison } from '../../../hooks';
import { ProfileMenuMapPageDesktop } from './profile-menu-map-page.desktop';
import { ProfileMenuMapPageMobile } from './profile-menu-map-page.mobile';
import { ScreenSize } from '../../../constants';


export const ProfileMenuMapPage = ({ 
  src = 'https://avatars.mds.yandex.net/get-zen_doc/5262161/pub_61cb1853c97cb1018f443f83_61cb43b819129b335b14c1d9/scale_1200', 
  name = 'Name',
  email = 'johndoe@example.com' }: ProfileMenuMapPageProps) => {
  const isTablet = useBreakpointsComparison(ScreenSize.TP);

 
  return isTablet 
    ? (
          <ProfileMenuMapPageMobile src={src} name={name} email={email} />
    ) : (
          <ProfileMenuMapPageDesktop src={src} name={name} email={email} />
    );
};
