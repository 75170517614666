import { useAppSelector } from './selector';


export const useGalleryObjectIdSelector = () => {
  return useAppSelector(({ windows }) => windows.galleryObjectId);
};

export const useCreateObjectModalSelector = () => useAppSelector(({ windows }) => windows.isCreateObjectModalOpen);

export const useOpenCoordsEditor = () => useAppSelector(({ windows }) => windows.isOpenCoordsEditor);

export const useIsMonitoringOpenSelector = () => useAppSelector(({ windows }) => windows.isMonitoringOpen);

export const useIsAnalyticsViewOpenSelector = () => useAppSelector(({ windows }) => windows.isAnalyticsViewOpen);

export const useEditFieldSelector = () => useAppSelector(({ windows }) => windows.editField);

export const useDeleteObjectSelector = () => useAppSelector(({ windows }) => windows.isDeleteObject);
