import { useEffect, lazy, Suspense, useCallback } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import styles from './map-page.module.scss';
import {
  clearProject,
  useAppDispatch,
  useEditFieldSelector,
  useEditorModeStateSelector,
  useGetBaseObjectsMutation,
  useGetObjectsMutation,
  useGetObjectsTypesMutation,
  useGetProjectMutation,
  useGetUserInfoMutation,
  useObjectMonitoringProjectIdSelector,
  useOjectByIdSelector,
  useGetEnvMutation,
  useGetEnv,
} from '../../state';
import {
  AnalyticsViewWrapper,
  CardView,
  EditorView,
  Footer,
  ObjectCreationView,
} from '../../components';
import { ZoomButtons } from '../../components/zoom-buttons/zoom-buttons';
import { SidebarFilterWrapper } from '../../components/sidebar-filter';
import { useGetFavoriteUserItemsMutation } from '../../state/api/user-api';
import { SidebarWrapper } from '../../components/sidebar/sidebar-wrapper';
import { useAppSelector } from '../../state/selector';
import { HeaderMapWrapper } from '../../components/header/header-map/header-map-wrapper';
import { CoordsPanel } from '../../components/coords-panel/coords-panel';
import { ImageGalleryMap } from '../../components/gallery';
import { MapPageTypes } from './map-page.types';
import { useMap } from '../../context';
import { Map as MapConstants, MAP_PATH } from '../../constants/map';
import {
  HistoryCreateObject,
  HistorySelectedFilters,
} from '../../components/history-components';
import { Loader } from '../../components/common';
import { useViewModeSelector } from '../../history-state/selectors';
import { useBreakpointsComparison, useFlyToObjectOnMap } from '../../hooks';
import { ScreenSize } from '../../constants';

const MapView = lazy(() =>
  import('../../components/view/map-view').then((module) => ({
    default: module.MapView,
  })),
);

export const MapPage = ({ children }: MapPageTypes): JSX.Element => {
  const [getObjects, { isLoading }] = useGetBaseObjectsMutation();
  const [getAllObjects, { isLoading: isLoadingAll }] = useGetObjectsMutation();
  const [getEnv, { isLoading: isEnvLoading, isSuccess: isEnvSuccess }] =
    useGetEnvMutation();
  const [getObjectsTypes] = useGetObjectsTypesMutation();
  const [getFavorites] = useGetFavoriteUserItemsMutation();
  const [getUserInfo] = useGetUserInfoMutation();
  const { objects, user, monitoring } = useAppSelector((state) => state);
  const { items, types } = objects;
  const { favoriteItems } = user;
  const dispatch = useAppDispatch();
  const editField = useEditFieldSelector();
  const params = useParams();
  const [getProject] = useGetProjectMutation();
  const monitoringProjectId = useObjectMonitoringProjectIdSelector(params.objectID);
  const isEditorMode = useEditorModeStateSelector();
  const [viewMode] = useViewModeSelector();
  const { map } = useMap();
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const flyTo = useFlyToObjectOnMap();
  const activeObject = useOjectByIdSelector(params.objectID);
  const publicEnv = useGetEnv();

  const goToCenterHandler = useCallback(() => {
    if (!!params.objectID) {
      const activeItem = items.find((item) => item.id === Number(params.objectID));
      activeItem && flyTo(activeItem.coords.coordinates);
    }
  }, [params]);

  const zoomInHandler = () => {
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  };

  const zoomOutHandler = () => {
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  };

  useEffect(() => {
    if (monitoringProjectId) {
      getProject(monitoringProjectId);
    } else {
      dispatch(clearProject());
    }
  }, [monitoringProjectId]);

  useEffect(() => {
    isEditorMode && getAllObjects();
  }, [isEditorMode]);

  useEffect(() => {
    !items.length && getObjects();
    !types.length && getObjectsTypes();
    !favoriteItems.length && getFavorites();
    !user.profile && getUserInfo();
  }, []);

  useEffect(() => {
    if (activeObject && !params.projectID && map) {
      activeObject && flyTo(activeObject.coords.coordinates as [number, number]);
    }
    !publicEnv && getEnv();
  }, [map]);

  return (
    <>
      {(isLoading || (isEditorMode && isLoadingAll)) && <Loader />}
      <div className={styles.container}>
        <div className={`${styles['map-wrapper']} map-container`}>
          {viewMode === 'map' && <HeaderMapWrapper isLoading={isLoading} />}
          {children}

          {publicEnv && !isEnvLoading ? (
            <Suspense fallback={<Loader />}>
              <MapView tileUrl={publicEnv?.REACT_APP_TILE_PATH} />
            </Suspense>
          ) : (
            <Loader />
          )}

          <ZoomButtons
            zoomInHandler={zoomInHandler}
            zoomOutHandler={zoomOutHandler}
            zoomInDisabled={map?.getZoom() === MapConstants.MAX_ZOOM}
            zoomOutDisabled={map?.getZoom() === MapConstants.MIN_ZOOM}
            showToTheCenterButton={isTabletLandscape && !!params.objectID}
            style={isTablet && !!params.objectID ? { top: '26vh' } : {}}
            toTheCenterOfTheMapHandler={goToCenterHandler}
          />
          <CardView isLoading={isLoading} />
          {editField && <EditorView />}
          <ObjectCreationView />

          <AnalyticsViewWrapper />
          <CoordsPanel />
          <ImageGalleryMap />
          <HistorySelectedFilters />
          <HistoryCreateObject />
          <Footer />
        </div>
        <SidebarFilterWrapper />
        <Routes>
          <Route path={'*'} element={<SidebarWrapper isEditorOpened={!!editField} />}>
            <Route path={'group/:groupID'} element={<SidebarWrapper />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};
