import { Media, ObjectInterface } from '../types';
import { getImageUrl } from './get-image-url';
import defaultImage from '../images/object-bg-image.png';


export const getMainSidebarImageUrl = (object: ObjectInterface) => {
  if (object.thumbnail) return object.thumbnail;
  if (object.media.length) return getImageUrl(object.media[0].path_thumbnail || object.media[0].path);
  return defaultImage;
};


export const getImage = (media: Media, name: string, objectId: number) => {
  const { id, path, path_thumbnail: pathThumbnail } = media;

  return {
    image: getImageUrl(pathThumbnail ? pathThumbnail : path),
    alt: `${name} фото`,
    id,
    object_id: objectId,
  };
};

export const getMappedGalleryObject = (
  media: Media[],
  name: string,
  objectId: number,
) => {
  return media && media.length > 0
    ? media.map((mediaItem) => {
      return getImage(mediaItem, name, objectId);
    })
    : [];
};
