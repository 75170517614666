import styles from './styles/styles.module.scss';
import type { 
  TextElementFabricProps, 
  InsTagAttributesType, 
  DelTagAttributesType, 
  TextType,
} from './text-element-fabric.types';


const elementClassNameString = (
  type?: TextType, 
  x?: number | string, 
  weight?: number | string,
) => {
  if (type === 'subheader') {
    return `subheader-weight-${weight || 2}`;
  }
  return `${String(type || 'text')}-x${String(x).replace('.', '-')}-weight-${weight || 3}`;
};

export const TextElementFabric = ({
  x = '3.5',
  className = '',
  weight,
  tag = 'p',
  type,
  children,
  ...props
}: TextElementFabricProps): JSX.Element => {

  if (tag === 'p') {
    return (
      <p
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </p>
    );
  }

  if (tag === 'span') {
    return (
      <span
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </span>
    );
  }

  if (tag === 'b') {
    return (
      <b
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </b>
    );
  }

  if (tag === 'strong') {
    return (
      <strong
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </strong>
    );
  }

  if (tag === 'em') {
    return (
      <em
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </em>
    );
  }

  if (tag === 'mark') {
    return (
      <mark
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </mark>
    );
  }

  if (tag === 'small') {
    return (
      <small
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </small>
    );
  }

  if (tag === 'del') {
    return (
      <del
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props as DelTagAttributesType}
      >
        {children}
      </del>
    );
  }

  if (tag === 'ins') {
    return (
      <ins
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props as InsTagAttributesType}
      >
        {children}
      </ins>
    );
  }

  if (tag === 'sub') {
    return (
      <sub
        className={`${styles[elementClassNameString(type, x, weight)]} ${className}`}
        {...props}
      >
        {children}
      </sub>
    );
  }

  if (tag === 'sup') {
    return (
      <sup
        className={`${styles[`${String(type || 'text-')}x${String(x).replace('.', '-')}-weight-${weight || '1'}`]} ${className}`}
        {...props}
      >
        {children}
      </sup>
    );
  }

  if (tag === 'i') {
    return (
      <i
        className={`${styles[`${String(type || 'text-')}x${String(x).replace('.', '-')}-weight-${weight || '1'}`]} ${className}`}
        {...props}
      >
        {children}
      </i>
    );
  }
  return <></>;
};
