import styles from './sidebar.module.scss';
import type { SidebarImageProps } from './sidebar.types';
import { Button } from '../common';


export const SidebarImage = ({
  goBackHandler = null,
  image,
  alt,
}: SidebarImageProps): JSX.Element => {
  return (
    <div 
      className={styles.image} 
    >
      {
          goBackHandler ? (
            <Button
              className={styles['image-go-back-button']}
              size={'m'}
              style={{ width: 'fit-content' }}
              elementType={'button'}
              typeBtn={'elevation'}
              text={'Назад'}
              onClick={goBackHandler}
              showLeftIcon={true}
              leftIcon={<i className="icon icon-arrow-2-left icon-middle" />}
            />
          ) : null
      }
      <img 
        src={String(image)} 
        alt={String(alt)} 
      />
    </div>
  );
};
