import styles from './object-info-card.module.scss';
import { ObjectInfoCardTypes } from './object-info-card.types';


export const ObjectInfoCard = ({
  title,
  footer,
  children,
  content,
}: ObjectInfoCardTypes) => {
  return (
    <div className={styles['object-info-card']}>
      <div
        className={styles['object-info-card-heading']}
      >
        {title && title}
      </div>
      {content ? (
        <div
          className={styles['object-info-card-content']}
        >
          {content}
        </div>
      ) : null}
      {children ? (
        <div 
          className={styles['object-info-card-content']}
        >
          {children}
        </div>
      ) : ''}
      {footer ? (
        <div className={styles['object-info-card-content']}>{footer}</div>
      ) : null}
    </div>
  );
};
