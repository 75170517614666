export const getHeatType = (rating: number, isDisable?: boolean) => {
  if (isDisable) {
    return 'blue';
  }
  if (rating < 100) {
    return 'veryBad';
  }
  if (rating === 100) {
    return 'badly';
  }
  if (rating >= 200 && rating < 500) {
    return 'satisfactory';
  }
  if (rating >= 500 && rating < 800) {
    return 'good';
  }
  if (rating >= 800) {
    return 'perfectly';
  }
  return 'gray';
};

export const getMacroType = (macro_region: number) => {
  if (macro_region === 0) {
    return 'gray';
  }

  if (macro_region === 1) {
    return 'pink';
  }
  if (macro_region === 2) {
    return 'orange';
  }
  if (macro_region === 3) {
    return 'blue';
  }
  if (macro_region === 4) {
    return 'darkGreen';
  }
  if (macro_region === 5) {
    return 'violet';
  }
  return 'pink';
};

const heatColors = {
  blue: '#94AAAF',
  perfectly: '#45f06b',
  good: '#b9f045',
  satisfactory: '#f0ca45',
  badly: '#f08345',
  veryBad: '#f04545',
  gray: 'gray',
};

const macroColors = {
  gray: '#94AAAF',
  pink: '#EE2D62',
  orange: '#F8931F',
  blue: '#4EC6DF',
  darkGreen: '#45A040',
  violet: '#8D2DEE',
};

const macroHoveredColors = {
  gray: '#94AAAF',
  pink: '#A50D37',
  orange: '#AD5F05',
  blue: '#1E91A8',
  darkGreen: '#265723',
  violet: '#612B9D',
};

export const getHeatColor = (rating: number, isDisable = false) =>
  heatColors[getHeatType(rating, isDisable)];

export const getMacroColor = (macro_region: number) =>
  macroColors[getMacroType(macro_region)];
export const getMacroHoveredColor = (macro_region: number):string =>
  macroHoveredColors[getMacroType(macro_region)];

export const getComplexNumber = (number: number, main_number: number | null = null) => {
  let resultNumber = String(number);
  if (main_number) {
    resultNumber = `${main_number}.${number}`;
  }
  return resultNumber;
};

function fold(input: string, lineSize: number, array: string[] = []): string[] {
  if (input.length <= lineSize) {
    array.push(input.trim());
    return array;
  }

  let line = input.substring(0, lineSize);
  const lastSpaceRgx = /\s(?!.*\s)/;
  const idx = line.search(lastSpaceRgx);
  let nextIdx = lineSize;
  if (idx > 0) {
    line = line.substring(0, idx);
    nextIdx = idx;
  }
  array.push(line.trim());

  return fold(input.substring(nextIdx), lineSize, array);
}

export const addLineFeed = (input: string, maxLineSize = 50) => {
  const inputWithoutDuplicateWhiteSpaces = input.replace(/\s+/g, ' ');

  const resultArray = fold(inputWithoutDuplicateWhiteSpaces, maxLineSize);
  const resultArraySize = resultArray.length;

  if (resultArray.length > 1 && resultArray[resultArraySize - 1].length < 10) {
    resultArray[resultArraySize - 2] += ` ${resultArray.pop() as string}`;
  }

  return resultArray.join('\n');
};

export const average = (array: any, defaultValue: any = 1000) => {
  let sum = 0;

  for (let i = 0; i < array.length; i++) {
    sum += parseInt(array[i], 10);
  }

  // !!array.length ? array.reduce((a, b) => a + b, 0) / array.length : 0

  return array.length ? sum / array.length : defaultValue;
};
