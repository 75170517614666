import { Button } from '../../common';
import { EditorMenuTriggerProps } from '../editor-menu.types';
import styles from '../editor-menu.module.scss';
import { useBreakpointsComparison } from '../../../hooks';
import { ScreenSize } from '../../../constants';


export const EditorMenuTrigger = ({ isMenuOpened = false } : EditorMenuTriggerProps ) => {
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  return (
    <div className={styles['editor-menu-trigger-wrapper']}>
      <Button
        size={!isTabletLandscape ? 'l' : 'm'}
        elementType={'button'}
        text={'Меню редактора'}
        className={styles['button-start']}
        typeBtn={'elevation'}
        showRightIcon={true}
        rightIcon={isMenuOpened ? <i className="icon icon-arrow-2-down icon-middle animateFade"></i> : <i className="icon icon-arrow-2-up icon-middle animateFade"></i>}
      />
    </div>
    
  );
};
