import { EditorMenuProps } from './editor-menu.types';
import { useBreakpointsComparison } from '../../hooks';
import { EditorMenuDesktop } from './editor-menu.desktop';
import { EditorMenuMobile } from './editor-menu.mobile';
import { memo } from 'react';
import { ScreenSize } from '../../constants';
  

export const EditorMenu = memo(({ className = '' }: EditorMenuProps) => {
  const isTablet = useBreakpointsComparison(ScreenSize.TP);

  return (
    <div className={className}>
      {
      isTablet
        ? (
          <EditorMenuMobile />
        ) : (
          <EditorMenuDesktop />
        )
      }
    </div>
  );
});
