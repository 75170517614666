import { useAppDispatch } from '../state';
import { useMap } from '../context';
import { Map as MapConstants } from '../constants';
import { useViewModeSelector } from '../history-state/selectors';


export const useFlyToObjectOnMap = () => {
  const { map } = useMap();
  const [view, setView] = useViewModeSelector();
  

  const flyTo = (
    coordinates: [number, number], 
    zoom?: number, 
    duration?: number) => {
    if (map) {
      view !== 'map' && setView('map');

      let mapZoom = MapConstants.FLY_TO_ZOOM_VALUE;

      if (zoom) {
        mapZoom = zoom;
      } else if (map.getZoom() > MapConstants.FLY_TO_ZOOM_VALUE) {
        mapZoom = map.getZoom();
      }

      map.flyTo([coordinates[0] - 0.004, coordinates[1]], mapZoom, { duration: duration || 0.5 });
    }
  };

  return flyTo;
};
