import React, { ReactElement } from 'react';
import { ContentTypes } from '../types/monitoring/monitoring-content-types';


interface ContentTypeObject {
  NAME: string;
  ICON: ReactElement;
  TYPE: ContentTypes;
}

interface PanoramaType extends ContentTypeObject {
  TYPE: ContentTypes.panorama;
}

interface VideoType extends ContentTypeObject {
  TYPE: ContentTypes.video;
}

interface AerialType extends ContentTypeObject {
  TYPE: ContentTypes.aerial;
}

interface ImageType extends ContentTypeObject {
  TYPE: ContentTypes.image;
}

interface AeropanoramaType extends ContentTypeObject {
  TYPE: ContentTypes.aeropanorama;
}

interface TimelapseType extends ContentTypeObject {
  TYPE: ContentTypes.timelapse;
}

interface ContentTypesObject {
  PANORAMA: PanoramaType;
  VIDEO: VideoType;
  IMAGE: ImageType;
  AERIAL: AerialType;
  AEROPANORAMA: AeropanoramaType;
  TIMELAPSE: TimelapseType; 
}


export const CONTENT_TYPES: ContentTypesObject = {
  PANORAMA: {
    TYPE: ContentTypes.panorama,
    NAME: 'Панорама',
    ICON: <i className="icon icon-panorama icon-middle"></i>,
  },
  VIDEO: {
    TYPE: ContentTypes.video,
    NAME: 'Видео',
    ICON: <i className="icon icon-video icon-middle"></i>,
  },
  AERIAL: {
    TYPE: ContentTypes.aerial,
    NAME: 'Аэросъемка',
    ICON:  <i className="icon icon-aerial-view icon-middle"></i>,
  },
  IMAGE: {
    TYPE: ContentTypes.image,
    NAME: 'Фото',
    ICON: <i className="icon icon-camera icon-middle"></i>,
  },
  AEROPANORAMA: {
    TYPE: ContentTypes.aeropanorama,
    NAME: 'Аэропанорама',
    ICON: <i className="icon icon-aeropanorama icon-middle"></i>,
  },
  TIMELAPSE: {
    TYPE: ContentTypes.timelapse,
    NAME: 'Таймлапс',
    ICON: <i className="icon icon-panorama icon-middle"></i>,
  },
};
