import { useState } from 'react';
import { Button } from '../common';
import { BottomSheet } from '../common/bottom-sheet';
import { EditorMenuButtons } from './components';
import styles from './editor-menu.module.scss';


export const EditorMenuMobile = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const editorMenuClickHandler = () => {
    setMobileMenuOpen(false);
  };

  return (
    <>
      <Button
        size={'s'}
        style={{ padding: '10px 12px' }}
        elementType={'button'}
        onClick={() => {
          setMobileMenuOpen(!isMobileMenuOpen);
        }}
        typeBtn={'elevation'}
      >
          Редактор
      </Button>
      <BottomSheet
        open={isMobileMenuOpen}
        onDismiss={() => setMobileMenuOpen(false)}
        title={'Меню редактора'}
        knob={false}
        blocking
        isActive={false}
      >
        <div 
          className={styles['mobile-container']}
          onClick={editorMenuClickHandler}
        >
          <EditorMenuButtons />
        </div>
      </BottomSheet>
    </>
  );
};
