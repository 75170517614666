import { ScreenSize } from '../../../constants';
import { useBreakpointsComparison } from '../../../hooks';
import { ProfileMenuUserPageDesktop } from './profile-menu-user-page.desktop';
import { ProfileMenuUserPageMobile } from './profile-menu-user-page.mobile';
import styles from './profile-menu-user-page.module.scss';


export const ProfileMenuUserPage = () => {
  const isMobile = useBreakpointsComparison(ScreenSize.MP);

  return  (
    <div className={styles.container}>
      {
        isMobile 
          ? (
          <ProfileMenuUserPageMobile />
          ) : (
          <ProfileMenuUserPageDesktop />
          )
      }
    </div>
  );

};
