import React, { useContext, useMemo, useCallback } from 'react';
import type { SlideProps } from './slide.types';
import { useSwiper } from 'swiper/react';
import { VideoPlayer } from '../../../video-player';
import { VideoPlayerRef } from '../../../video-player/video-player.types';
import { VideoGalleryContext } from '../context';
import styles from './slide.module.scss';
import { useBreakpointsComparison } from '../../../../hooks';
import { ScreenSize } from '../../../../constants';


export const Slide = React.memo(
  React.forwardRef<VideoPlayerRef, SlideProps>(
    (
      {
        original,
        mobileSize = 500,
        id,
        showVolumeChanger,
        onFullScreen,
        startFrom,
      },
      ref,
    ) => {
      const isMobile = useBreakpointsComparison(ScreenSize.MP);
      const swiper = useSwiper();

      const { isFullscreen, currentItem } = useContext(VideoGalleryContext);

      return (
        <VideoPlayer
          ref={ref}
          alwaysShowHUD={false}
          mobileSize={mobileSize}
          playing={id === currentItem?.id}
          showPauseHover={id === currentItem?.id}
          src={original}
          embedUrl={original}
          onNext={() => swiper.slideNext()}
          onPrevious={() => swiper.slidePrev()}
          showVolumeChanger={showVolumeChanger}
          customFullScreen={true}
          fullscreen={isFullscreen}
          onFullscreen={onFullScreen}
          containerClassName={styles['video-container']}
          videoWidth={isMobile ? '100%' : 'auto'}
          videoHeight={isMobile ? 'auto' : '100%'}
          startFrom={startFrom}
        />
      );
    },
  ),
);
