import { FC, useState, useRef, useEffect  } from 'react';
import type { MainImageProps } from '../main-image.types';

import styles from '../main-image.module.scss';
import classname from 'classnames';
import { Loader, PTag } from '../../../../common';


export const MainImage: FC<MainImageProps> = ({ original, fullScreenHandler }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    /**
     * Отслеживание загрузки
     * Стандартный лоадер swiper ломает верстку в safari, пришлось от него избавиться
     */
    if (imgRef.current) {
      const observer = new MutationObserver((mutationList, observer) => {
        mutationList.forEach(function (mutation) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'class'
          ) {
            if (
              (mutation.target as HTMLElement).classList.contains(
                styles['image-content_loaded'],
              )
            ) {
              setLoaded(true);
              observer.disconnect();
            }
          }
        });
      });

      observer.observe(imgRef.current, { attributes: true });

      return () => {
        observer.disconnect();
      };
    }
  }, [imgRef]);

  return (
    <div
      onClick={fullScreenHandler}
      className={styles['image-container']}
      style={{ width: !loaded ? '80%' : 'fit-content' }}
    >   
      {!loaded && (
        <Loader
          className={styles['image-container-loader']}
        />
      )}
      <img
        ref={imgRef}
        className={classname('swiper-lazy', styles['image-content'])}
        data-src={original}
        alt={''}
      />
      <div className={styles['image-content-actions']}>
      </div>
    </div>
  );
};
