import { ObjectInterface } from '../types';
import { realisationStatuses } from '../components/sidebar-filter/data';
import { demoObjectsInfo } from '../__demo';

export const commonFilterObject = (
  objects: ObjectInterface[],
  selectedRegions: number[],
  selectedPrograms: number[],
  selectedStatus: number[],
  start_of_building: number[],
  type_of_work: number[],
  favorites: number[],
  enableFavoriteFilters: boolean,
  activeObjectID?: number | null | undefined,
  isEditMode = false,
  demoObjectTypes?: number[],
  demoFund?: number[],
): ObjectInterface[] => {
  let result = [...objects];

  if (selectedRegions.length) {
    result = result.filter((el: ObjectInterface) => {
      return (
        selectedRegions.includes(Number(el.macro_region)) || el.id === activeObjectID
      );
    });
  }
  if (selectedPrograms.length) {
    result = result.filter((el: ObjectInterface) => {
      return selectedPrograms.includes(Number(el.program_id)) || el.id === activeObjectID;
    });
  }
  if (start_of_building.length) {
    result = result.filter((el: ObjectInterface) => {
      return (
        start_of_building.includes(Number(el.start_of_building)) ||
        el.id === activeObjectID
      );
    });
  }
  if (type_of_work.length) {
    result = result.filter((el: ObjectInterface) => {
      return type_of_work.includes(Number(el.type_of_work)) || el.id === activeObjectID;
    });
  }
  if (selectedStatus.length && isEditMode) {
    result = result.filter((el: ObjectInterface) => {
      return selectedStatus.includes(Number(el.real_status)) || el.id === activeObjectID;
    });
  }
  if (!isEditMode) {
    const activeStatus = realisationStatuses.find((el) => Number(el.id) === 2);
    if (activeStatus) {
      result = result.filter((el: ObjectInterface) => {
        return Number(el.real_status) === activeStatus.id || el.id === activeObjectID;
      });
    }
  }
  if (favorites.length && enableFavoriteFilters) {
    result = result.filter((el: ObjectInterface) => {
      return favorites.includes(Number(el.id)) || el.id === activeObjectID;
    });
  }
  if (demoObjectTypes && demoObjectTypes.length) {
    result = result.filter((el: ObjectInterface) => {
      // @ts-ignore
      // console.log(demoObjectsInfo[String(el.id)]);

      // return el;
      return (
        // @ts-ignore
        demoObjectsInfo[el.id] &&
        // @ts-ignore
        (demoObjectTypes.includes(Number(demoObjectsInfo[el.id].objectType)) ||
          el.id === activeObjectID)
      );
    });
  }
  if (demoFund && demoFund.length) {
    result = result.filter((el: ObjectInterface) => {
      return (
        // @ts-ignore
        demoObjectsInfo[el.id] &&
        // @ts-ignore
        (demoFund.includes(demoObjectsInfo[el.id].fund) || el.id === activeObjectID)
      );
    });
  }

  return result;
};
