import { SidebarHeaderProps } from './sidebar.types';
import { Button, SpanTag, H4Tag } from '../common';
import styles from './sidebar.module.scss';


export const SidebarHeader = ({
  title = '',
  onCloseClick,
  subtitle,
}: SidebarHeaderProps): JSX.Element => {

  return (
    <div className={styles['sidebar-header']}>
      <div className={styles['sidebar-header-top']}>
        <div className={styles['sidebar-header-top-title']}>
          <H4Tag 
            weight={3}
            className={styles['sidebar-header-top-title-text']}
          >
            {title}
          </H4Tag>
          <Button
            size={'l'}
            elementType={'button'}
            className={styles['sidebar-header-top-close-button']}
            onlyIcon={true}
            typeBtn={'uncolored'}
            onClick={onCloseClick}
            showLeftIcon 
            leftIcon={<i className={'icon icon-close-3 icon-middle'}/>}
          />
        </div>
        {
          subtitle && subtitle.length
            ? (
              <div className={styles['sidebar-header-top-subtitle']}>
                <SpanTag 
                  type={'text'}
                  x={3.5}
                  weight={3}
                  className={styles['sidebar-header-top-subtitle-text']}
                >
                  {subtitle}
                </SpanTag>
              </div>
            ) : null
        }
      </div>
    </div>
  );
};
