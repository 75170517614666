import 
React, 
{ 
  FC, 
  useState, 
  useCallback, 
  useEffect, 
  useMemo,
  memo,
} from 'react';
import type { Swiper as SwiperType } from 'swiper';
import type { GalleryProps } from './gallery.types';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation,
  Pagination,
  Mousewheel,
  Lazy,
  Thumbs,
  Keyboard,
} from 'swiper';
import { Button } from '../../common';

import { NavigationButton } from './navigation-button';

import 'swiper/scss';
import 'swiper/scss/lazy';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import styles from './gallery.module.scss';
import classnames from 'classnames';


export const Gallery: FC<GalleryProps> = memo(({
  items = [],
  loop = true,
  lazy = true,
  withThumbnail = true,
  withNavButtons = true,
  slide: Slide,
  mainWrapperClassName,
  thumbnail: Thumbnail,
  thumbnailGap = 30,
  slidesGap = 0,
  thumbnailVisableCount = 6,
  thumbnailWrapperClassName,
  withDrag = true,
  withEffect = true,
  initialSlide = 0,
  withCloseButton = false,
  slideLoadedClassName,
  slideLoadingClassName,
  visableNavButtons = true,
  onMainSwiperInit = () => {},
  onCloseButton = () => {},
  navigateButtonPrevClassName = '',
  navigateButtonNextClassName = '',
}) => {
  const [mainSwiper, setMainSwiper] = useState<SwiperType>();
  const [thumbnailsSwiper, setThumbnailsSwiper] = useState<SwiperType>();

  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const thumbsConfig = useMemo(
    () => ({
      swiper: thumbnailsSwiper,
    }),
    [thumbnailsSwiper, items],
  );

  const thumbnailsSwiperToSlide = useCallback(
    (idx: number) => {
      thumbnailsSwiper && thumbnailsSwiper.slideToLoop(idx);
    },
    [thumbnailsSwiper, items],
  );

  const onSlideChangeTransitionStart = useCallback(
    (swiper: SwiperType) => {
      thumbnailsSwiper?.slideToLoop(swiper.realIndex);
      setCurrentSlide(swiper.realIndex);
    },
    [thumbnailsSwiper, items],
  );

  const onMainSwiper = useCallback((swiper: SwiperType) => {
    setMainSwiper(swiper);
    onMainSwiperInit(swiper);
  }, [items]);

  const onThumbsSwiper = useCallback((swiper: SwiperType) => {
    setThumbnailsSwiper(swiper);
  }, [items]);

  /**
   * Для исправления бага с лейзилоадом
   *
   * Эффект срабатывает только один раз при установке mainSwiper
   */
  useEffect(() => {
    if (mainSwiper && lazy) {
      mainSwiper.lazy.load();

      /**
       * Перемещение к slideInit после загрузки изображения
       * Срабатывает только на первое изображение
       */
      const toFirstSlide = (swiper: SwiperType) => {
        thumbnailsSwiperToSlide(swiper.realIndex);
        mainSwiper.off('lazyImageReady', toFirstSlide);
      };

      mainSwiper.on('lazyImageReady', toFirstSlide);
    }
  }, [mainSwiper, items]);

  return (
    <div className={styles.gallery}>
      <Swiper
        modules={[Navigation, Pagination, Lazy, Thumbs, Keyboard]}
        slidesPerView={1}
        initialSlide={initialSlide}
        loop={loop}
        preloadImages={false}
        spaceBetween={slidesGap}
        lazy={{
          enabled: lazy,
          loadOnTransitionStart: true,
          loadPrevNext: true,
          loadedClass: slideLoadedClassName || 'loaded',
          loadingClass: slideLoadingClassName || 'loading',
        }}
        keyboard={{
          enabled: true,
        }}
        speed={withEffect ? 300 : 0}
        className={classnames(styles['gallery-swiper'], mainWrapperClassName)}
        onSwiper={onMainSwiper}
        onSlideChangeTransitionStart={onSlideChangeTransitionStart}
        allowTouchMove={withDrag}
        thumbs={thumbsConfig}
      >
        {items.map((el) => (
          <SwiperSlide
            className={styles['gallery-swiper-slide']}
            key={el.id}
            data-history={el.id}
          >
            <Slide {...el} />
          </SwiperSlide>
        ))}
        {withNavButtons && (
          <>
            <NavigationButton 
              className={navigateButtonNextClassName}
              type="next" 
              visable={visableNavButtons} 
            />
            <NavigationButton 
              className={navigateButtonPrevClassName}
              type="prev" 
              visable={visableNavButtons} 
            />
            {withCloseButton && (
              <Button
                className={
                  styles['gallery-swiper-button_fullscreen-close-button']
                }
                showLeftIcon
                onlyIcon
                typeBtn={'elevation'}
                leftIcon={
                  <i className={'icon icon-close-3 icon-middle'} />
                }
                size={'m'}
                onClick={onCloseButton}
              />
            )}
          </>
        )}
      </Swiper>

      {withThumbnail && (
        <Swiper
          modules={[Mousewheel, Thumbs, Lazy]}
          slidesPerView={thumbnailVisableCount}
          lazy={{
            enabled: true,
            loadOnTransitionStart: true,
            loadPrevNext: true,
          }}
          initialSlide={initialSlide}
          spaceBetween={thumbnailGap}
          mousewheel={{ sensitivity: 1 }}
          slideToClickedSlide={true}
          onSwiper={onThumbsSwiper}
          className={classnames(
            styles['gallery-thumbnails'],
            thumbnailWrapperClassName,
          )}
          centerInsufficientSlides={true}
        >
          {items.map((el, idx) => (
            <SwiperSlide key={el.id}>
              {React.createElement(Thumbnail, {
                ...el,
                isActive: currentSlide === idx,
              })}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}, (prevProps, nextProps): boolean => {
  if (prevProps.items.length !== nextProps.items.length) return false;
  return false;
});
