import { useCallback, useRef, useState } from 'react';
import { Sidebar } from './sidebar';
import {
  useAppDispatch,
  useEditorModeStateSelector,
  useGalleryObjectIdSelector,
  useGetProjectSelector,
  useIsAnalyticsViewOpenSelector,
  useObjectByIdSelector,
} from '../../state/selector';
import { useSelectObject } from '../../hooks/depended/useSelectObject';
import {
  clearProject,
  setAnalyticsViewOpen,
  setEditField,
  setGalleryObjectId,
  setMonitoringOpen,
  UpdateObjectDataType,
  useUpdateObjectDataMutation,
} from '../../state';
import { BottomSheet } from '../common/bottom-sheet';
import { SidebarContainer } from '../common';
import { useSidebarState } from '../../history-state/selectors';
import { batch } from 'react-redux';
import { useBreakpointsComparison, useFlyToObjectOnMap } from '../../hooks';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  LINK_MAP_OBJECT_PATH,
  LINK_PROJECT_PATH,
  Paths,
  ScreenSize,
} from '../../constants';
import type { Group as GroupType } from '../../types/monitoring';
import { BottomSheetRef } from 'react-spring-bottom-sheet';
import { SidebarWrapperProps } from './sidebar.types';

export const SidebarWrapper = ({ isEditorOpened = false }: SidebarWrapperProps) => {
  const [isSidebarOpen, handleChangeSidebarState] = useSidebarState();
  const isEditorMode = useEditorModeStateSelector();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const { groupID } = useParams();
  const flyTo = useFlyToObjectOnMap();
  const [selectedObjectId, setSelectedObjectId] = useSelectObject();
  const selectedObject = useObjectByIdSelector(selectedObjectId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [updateObjectData, { isLoading: isObjectDataLoaded }] =
    useUpdateObjectDataMutation();
  const project = useGetProjectSelector();
  const location = useLocation();
  const [group, setGroup] = useState<GroupType | null>(null);
  const bottonSheetRef = useRef<BottomSheetRef | null>(null);
  const galleryImgId = useGalleryObjectIdSelector();
  const isAnalyticsViewOpen = useIsAnalyticsViewOpenSelector();

  const handleUpdateObjectData = (values: UpdateObjectDataType) => {
    updateObjectData(values);
  };

  const goBackHandler = () => {
    if (selectedObjectId) {
      navigate({
        pathname: `${Paths.MAIN_PAGE}${Paths.OBJECT}/${selectedObjectId}`,
        search: location.search,
      });
    }
    setGroup(null);
  };

  const flyToObject = () => {
    const { coordinates } = selectedObject!.coords;
    flyTo([coordinates[0], coordinates[1]]);

    if (isTablet && bottonSheetRef.current) {
      bottonSheetRef.current.snapTo(({ snapPoints }: any) => {
        return snapPoints;
      });
    }
  };

  const handleOpenAnalytics = () => {
    if (galleryImgId !== null) {
      batch(() => {
        dispatch(setGalleryObjectId(null));
        dispatch(setMonitoringOpen(false));
        dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
      });
    }
    if (galleryImgId !== null && isAnalyticsViewOpen) {
      dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
    }
    handleChangeSidebarState(false);
    batch(() => {
      dispatch(setAnalyticsViewOpen(!isAnalyticsViewOpen));
      dispatch(setMonitoringOpen(false));
    });
  };

  const openMonitoringTabHandler = useCallback(() => {
    // if (project) {
    navigate(LINK_PROJECT_PATH(String(selectedObjectId), String(project?.id)));
    // }
  }, [selectedObjectId]);

  const opeInfoTabHandler = useCallback(() => {
    navigate(LINK_MAP_OBJECT_PATH(String(selectedObjectId)));
  }, [selectedObjectId]);

  const bottomIndex = 0.48;

  return (
    <>
      {isSidebarOpen && !!selectedObjectId && !isTablet && (
        <SidebarContainer>
          <Sidebar
            object={selectedObject}
            onCloseClick={() => {
              setSelectedObjectId(null);
              batch(() => {
                dispatch(setGalleryObjectId(null));
                dispatch(clearProject());
                dispatch(setMonitoringOpen(false));
              });
            }}
            onImageClick={(item) => dispatch(setGalleryObjectId(item))}
            editorMode={isEditorMode}
            goBackHandler={goBackHandler}
            group={group}
            setGroup={setGroup}
            onEditField={(field) => dispatch(setEditField(field))}
            onChangeObject={handleUpdateObjectData}
            isObjectUpdating={isObjectDataLoaded}
            isMonitoringActive={!!project}
            flyToObject={flyToObject}
            handleOpenAnalytics={handleOpenAnalytics}
          />
        </SidebarContainer>
      )}

      <BottomSheet
        ref={bottonSheetRef}
        open={isSidebarOpen && !isEditorOpened && !!selectedObjectId && isTablet}
        onDismiss={() => {
          handleChangeSidebarState(false);
          setSelectedObjectId(null);
        }}
        initialHeight={'mid'}
        title={selectedObject?.name}
        subtitle={selectedObject?.place}
        backButton={!!groupID}
        onBack={goBackHandler}
      >
        <Sidebar
          object={selectedObject}
          onCloseClick={() => {
            setSelectedObjectId(null);
          }}
          onImageClick={(item) => {
            dispatch(setGalleryObjectId(item));
            handleChangeSidebarState(false);
          }}
          group={group}
          setGroup={setGroup}
          editorMode={isEditorMode}
          onEditField={(field) => dispatch(setEditField(field))}
          onChangeObject={handleUpdateObjectData}
          isObjectUpdating={isObjectDataLoaded}
          isMonitoringActive={!!project}
          flyToObject={flyToObject}
          handleOpenAnalytics={handleOpenAnalytics}
        />
      </BottomSheet>
    </>
  );
};
