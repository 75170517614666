import { SwitchProps } from './switch.types';
import styles from './switch.module.scss';


export const Switch = ({
  showLabel,
  text = 'Label',
  isDisabled = false,
  checked = false,
  className = '',
  onToggle,
  id,
}: SwitchProps) => {

  return (
    <div className={styles['switch-container']}>
      <input
        disabled={isDisabled}
        className={`${styles['switch-checkbox']} ${className}`}
        id={id}
        checked={checked}
        type="checkbox"
        onChange={onToggle}
      />
      <label className={styles['switch-label']} htmlFor={id}>
        <span className={styles['switch-button']} />
      </label>
      {showLabel && <span className={styles['switch-label-text']}>{text}</span>}
    </div>
  );
};
