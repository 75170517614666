import React from 'react';
import styles from './search-section-title.module.scss';
import { SearchSectionTitleProps } from './search-section-title.types';
import { SpanTag } from '../text';


export const SearchSectionTitle = ({ className, children, text, showIcon = false, ...props }: SearchSectionTitleProps) => {
  return (
    <div
      className={styles.container}
    >
      {showIcon && <i className="icon icon-show icon-low"></i>}
      
      <SpanTag 
        type={'caption'} 
        x={3.25}
        weight={1}
      >
        {text}
      </SpanTag>
      
    </div>
  );
}; 
