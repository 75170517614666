import { useState } from 'react';
import { useDeviceWidthTracker } from './use-device-width-tracker';
import { Breakpoints, ScreenSize } from '../constants';


type BreakpointsKeyT = keyof typeof Breakpoints;

const bpKeys = Object.keys(Breakpoints) as BreakpointsKeyT[];
const getCurrentBreakpoint = () => {
  const currentKey = bpKeys.find((key) => {
    return window.innerWidth <= Breakpoints[key];
  });

  return currentKey || ScreenSize.DM;
};


export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(Breakpoints[getCurrentBreakpoint()]);
  useDeviceWidthTracker(() => {
    setBreakpoint(Breakpoints[getCurrentBreakpoint()]);
  });

  return breakpoint;
};
