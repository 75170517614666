import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapInitialState as initialState } from './initial-state';
import { mapApi } from '../api';


const mapSlice = createSlice({
  initialState,
  name: 'map',
  reducers: {
    getClickPosition: (state, { payload }: PayloadAction<any>) => {
      state.clickPosition = payload;
    },
    
    setMouseZoomActivity: (state, { payload }: PayloadAction<boolean>) => {
      state.isMouseZoomActive = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      mapApi.endpoints.getEnv.matchFulfilled,
      (state, { payload }: AnyAction) => {
        state.env = payload;
      },
    );
  },
});

export const { getClickPosition, setMouseZoomActivity } = mapSlice.actions;

export const { reducer: mapReducer } = mapSlice;
