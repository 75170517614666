import { useCallback, useEffect, useMemo, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import styles from './map-view.module.scss';
import 'proj4leaflet/src/proj4leaflet';
import { HistoryMapLatLng } from './history-map-lat-lng';
import { ContentWrapper } from './content';
import { MapContainer } from 'react-leaflet';
import 'leaflet.snogylop';
import { Map as MapConstants } from '../../../constants';
import {
  useCreateObjectModalSelector,
  useIsDemoUser,
  useOpenCoordsEditor,
} from '../../../state/selector';
import { useMap } from '../../../context';
import { GeoJsonWrapper } from './geo-json';
import { MapTileLayer } from './map-tile-layer';
import type { DistanceValueType, MapViewProps } from './map-view.types';
import L from 'leaflet';
import { useSidebarState, useViewModeSelector } from '../../../history-state/selectors';
import { getDistanceValue } from './utils';
import { useLocation } from 'react-router-dom';
import { useAppObjects } from '../../../state/selector/selector';

const MapContainerComponent = MapContainer as any;

export const MapView = ({ tileUrl }: MapViewProps) => {
  const { map, setMap } = useMap();
  const [isSidebarOpen] = useSidebarState();
  const isOpenCoordsEditor = useOpenCoordsEditor();
  const isCreateObjectModalOpen = useCreateObjectModalSelector();
  const [distanceValue, setDistanceValue] = useState<DistanceValueType>('far');
  const location = useLocation();
  const [viewMode] = useViewModeSelector();
  const objects = useAppObjects();
  const isDemoUser = useIsDemoUser();

  const documentResize = useCallback(() => {
    if (map) {
      try {
        map.invalidateSize && map.invalidateSize(true);
      } catch (e) {
        console.warn(e);
      }
    }
  }, [map]);

  const changeDistanceValueHandler = (zoomValue: number) => {
    const distance = getDistanceValue(zoomValue);

    if (distance !== distanceValue) {
      setDistanceValue(distance);
    }
  };

  useEffect(() => {
    if (map) {
      document.addEventListener('resize', documentResize);
    }
    return () => {
      document.removeEventListener('resize', documentResize);
    };
  }, [map]);

  useEffect(() => {
    if (viewMode === 'map') {
      documentResize();
    }
  }, [documentResize, isSidebarOpen, map]);

  useEffect(() => {
    if (isOpenCoordsEditor || isCreateObjectModalOpen) {
      map && map.getContainer().style.setProperty('cursor', 'crosshair');
    } else {
      map && map.getContainer().style.setProperty('cursor', 'grab');
    }
  }, [isOpenCoordsEditor, isCreateObjectModalOpen]);

  const mapContainer = useMemo(() => {
    return (
      <MapContainerComponent
        ref={setMap}
        className={styles['map-view']}
        doubleClickZoom={true}
        tap={false}
        center={[MapConstants.DEFAULT_LAT, MapConstants.DEFAULT_LNG]}
        zoom={MapConstants.DEFAULT_ZOOM}
        maxZoom={MapConstants.MAX_ZOOM}
        minZoom={MapConstants.MIN_ZOOM}
        zoomControl={false}
        attributionControl={false}
        continuousWorld={true}
        maxBounds={
          new L.LatLngBounds(new L.LatLng(-89.98, -160), new L.LatLng(89.98, 250))
        }
        maxBoundsViscosity={1}
      >
        <HistoryMapLatLng changeDistanceValueHandler={changeDistanceValueHandler} />
        <MapTileLayer tileUrl={tileUrl} />
        <ContentWrapper distanceValue={distanceValue} />
        <GeoJsonWrapper />
      </MapContainerComponent>
    );
  }, [
    setMap,
    map,
    distanceValue,
    location,
    isSidebarOpen,
    viewMode,
    tileUrl,
    isDemoUser,
  ]);

  return mapContainer;
};
