import { useNavigate } from 'react-router-dom';
import { useAppDispatch, logout } from '../../../../state'; 
import { Button } from '../../../common';
import styles from '../profile-menu-user-page.module.scss';


export const ProfileMenuButtons = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        className={`${styles['container-button']} ${styles['container-button-close']}`}
        elementType={'button'}
        size={'m'}
        typeBtn={'primary'}
      >
            Закрыть
      </Button>
      <Button
        elementType={'button'}
        className={`${styles['container-button']} ${styles['container-button-logout']}`}
        size={'m'}
        typeBtn={'danger'}
        showLeftIcon
        onClick={() => dispatch(logout())}
        leftIcon={<i className={'icon icon-log-out icon-middle'} />}
      >
            Выход
      </Button>
    </>
  );

};
