import type { IObjectInfoMobileProps } from './object-info.types';
import styles from './object-info.module.scss';
import { getContentValues } from './utils';
import { Heading } from '../../constants';
import { PTag, SpanTag } from '../common';


export const ObjectInfoMobile = ({
  object,
}: IObjectInfoMobileProps): JSX.Element => {
  const contentValues = getContentValues(object);

  return (
    <>
      {contentValues.passability ? (
            <PTag
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.passability}
              <SpanTag
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
              >
                {contentValues.passability}
              </SpanTag>
            </PTag>
      ) : null}
      {contentValues.employeeVisits ? (
            <PTag 
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.employeeVisits}
              <SpanTag
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
              >
                {contentValues.employeeVisits}
              </SpanTag>
            </PTag>
      ) : null}
      {contentValues.necessaryInvest ? (
            <PTag 
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.necessaryInvest}
              <SpanTag
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
              >
                {contentValues.necessaryInvest}
              </SpanTag>
            </PTag>
      ) : null}
      {contentValues.distanceDistrictCenter ? (
            <PTag 
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.distanceDistrictCenter}
              <SpanTag
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
              >
                {contentValues.distanceDistrictCenter}
              </SpanTag>
            </PTag>
      ) : null}
      {contentValues.owner ? (
            <PTag 
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.owner}
              <SpanTag
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
              >
                {contentValues.owner}
              </SpanTag>
            </PTag>
      ) : null}
      {contentValues.macroRegionValue ? (
            <PTag 
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.macroRegionValue}
              <SpanTag
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
              >
                {contentValues.macroRegionValue}
              </SpanTag>
            </PTag>
      ) : null}
      {contentValues.programValue ? (
            <PTag 
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.programValue}
              <SpanTag
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
              >
                {contentValues.programValue}
              </SpanTag>
            </PTag>
      ) : null}
      <PTag 
        type={'caption'} 
        x={2.75} 
        weight={2}
        className={styles['object-info-content-title']}
      >
        {Heading.typeOfWork}
        <SpanTag
          type={'caption'} 
          x={2.75}
          weight={1}
          className={styles['object-info-content-title-value']}
        >
          {contentValues.typeOfWork}
        </SpanTag>
      </PTag>
      {contentValues.realizationPeriod ? (
            <PTag 
              type={'caption'} 
              x={2.75} 
              weight={2}
              className={styles['object-info-content-title']}
            >
              {Heading.realizationPeriod}
              <SpanTag 
                type={'caption'} 
                x={2.75}
                weight={1}
                className={styles['object-info-content-title-value']}
                dangerouslySetInnerHTML={{ __html: contentValues.realizationPeriod.trim() }}
              />
            </PTag>
      ) : null}
    </>
  );
};
