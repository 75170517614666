import { useAppSelector } from './selector';

export const useEditorModeStateSelector = () => {
  return useAppSelector(({ user }) => user.isEditorMode);
};

export const useFavoriteObjectsSelector = () => {
  const favorites = useAppSelector(({ user }) => user.favoriteItems);

  return favorites.map((item) => Number(item));
};

export const useUserProfileInfo = () => useAppSelector(({ user }) => user.profile);

export const useAppStateUserRole = () => useAppSelector(({ user }) => user.role);

export const useIsUserAdmit = () =>
  useAppSelector(({ user }) => user.profile && Boolean(user.profile.is_admin));

export const useIsClusterMarkerViewSelector = () =>
  useAppSelector(({ user }): boolean => user.isClusterMarkerViewSelected);

export const useIsDemoUser = () =>
  useAppSelector(
    ({ user }) =>
      typeof user.profile?.email === 'string' &&
      Boolean(user.profile.email === 'demo@invest.ru'),
  );
