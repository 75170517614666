import { memo, useCallback } from 'react';
import './content.style.scss';
import { ObjectMarker } from '../marker';
import type { ObjectInterface } from '../../../../types';
import { useSelectObject } from '../../../../hooks/depended/useSelectObject';
import type { MarkerContentProps } from './content.types';
import { useBreakpointsComparison } from '../../../../hooks';
import { ScreenSize } from '../../../../constants';
import { useSidebarState } from '../../../../history-state/selectors';
import { useIsDemoUser } from '../../../../state';
import { demoObjectsInfo } from '../../../../__demo';

export const MarkerContent = memo(
  ({ items, types, dotsView = false, showName = true }: MarkerContentProps) => {
    const [selectedObjectId, handleMarkerClick] = useSelectObject();
    const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
    const [isSidebarOpen] = useSidebarState();
    const isDemoUser = useIsDemoUser();

    const markerHandler = useCallback(
      (id: number | null) => {
        handleMarkerClick(id);
      },
      [isSidebarOpen, selectedObjectId],
    );

    return (
      <>
        {items.length &&
          items.map((item: ObjectInterface) => (
            <ObjectMarker
              key={item.id}
              item={item}
              type={types.find(
                (el) =>
                  el.id ===
                  // @ts-ignore
                  (isDemoUser ? demoObjectsInfo[Number(item.id)].type : item.type_id),
              )}
              onClick={markerHandler}
              selected={item.id === selectedObjectId}
              macro={item.macro_region}
              dotsView={dotsView}
              showName={showName}
              isMobile={isTabletLandscape}
            />
          ))}
      </>
    );
  },
);
