import { useEffect, useRef } from 'react';
import styles from './search-dropdown.module.scss';
import { SearchResults } from '../search-results';
import { SearchItem } from '../search-item';
import { SearchInput } from '../search-input';
import { BottomSheet } from '../bottom-sheet'; 
import type { SearchDropdownMobileProps } from './search-dropdown.types';


export const SearchDropdownMobile = ({
  inputQueryValue,
  className,
  children,
  handleFilterClick,
  hideDropdown = () => {},
  items,
  activeSuggestion,
  handleMouseEnterOnSearchSuggestion,
  inputRef,
  onKeyDown,
  onFocus,
  resetInputValue,
  onChange,
  value,
  ...props
}: SearchDropdownMobileProps) => {
  const searchResultsContainerRef = useRef<HTMLDivElement | null>(null);
  const searchItemContainerRef = useRef<HTMLDivElement | null>(null);

  const fucusHandler = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  
  return (
    <BottomSheet
      open={true}
      closeButton={false}
      onDismiss={() => {
        hideDropdown();
      }}
      initialHeight={'max'}
      isActive={false}
      onSpringEnd={() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    > 
      <div className={styles.container}>
        <SearchInput
          inputRef={inputRef}
          onKeyDown={onKeyDown}
          resetInputValue={resetInputValue}
          onChange={onChange}
          value={value}
          placeholder={'Объект, инициатор , адрес'}
          onTouchStart={fucusHandler}
        />
        <SearchResults
          titleSuccess={items.length > 0}
          searchResultsContainerRef={searchResultsContainerRef}
        >
          {Array.isArray(items) &&
          items.length > 0 &&
          items.slice(0, 5).map((r, idx) => (
            <SearchItem
              inputQueryValue={inputQueryValue}
              handleMouseEnterOnSearchSuggestion={handleMouseEnterOnSearchSuggestion}
              searchItemContainerRef={searchItemContainerRef}
              name={r.name}
              initiator={r.owner}
              place={r.place}
              hideDropdown={hideDropdown}
              key={r.id}
              idx={idx}
              item={r}
              isActiveSuggestion={activeSuggestion === idx}
              activeSuggestion={activeSuggestion}
            />
          ))}
        </SearchResults>
      </div>
    </BottomSheet>
  );
};
