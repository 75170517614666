import { useEffect, useState } from 'react';
import styles from './object-creation-form.module.scss';
import CreationForm from '../creation-form/creation-form';
import { useAppSelector, useAppDispatch } from '../../../../state';
import { setCreateObjectModal } from '../../../../state';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CreationFormTextarea from '../creation-form/creation-form-textarea';
import CreationFormSelect from '../creation-form/creation-form-search-select';
import { useAddObjectMutation } from '../../../../state';
import { Input, Button } from '../../../common';


const handleFilter = (items) => {
  return (searchValue) => {
    if (searchValue.length === 0) {
      return items;
    }
    return items.filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()));
  };
};

const programId = [
  { value: 0, name:'Другое' }, 
  { value: 1, name:'Восстановление ОКН' }, 
  { value: 2, name:'Социальная программа' },
];


const macroRegionsArr = [
  { value: 0, name:'Другие регионы' }, 
  { value: 1, name:'Витрина' }, 
  { value: 2, name:'Юг и Черноземье' }, 
  { value: 3, name:'Коридор Этан' }, 
  { value: 4, name:'Дальний Восток' },
  { value: 5, name:'Коридор «Сибирь»' },
];

const validationSchema = Yup.object({
  name: Yup.string().required('Обязательное поле'),
  lat: Yup.number().required('Обязательное поле'),
  long: Yup.number().required('Обязательное поле'),
  place: Yup.string().required('Обязательное поле'),
  program_id: Yup.number().required('Обязательное поле'),
  macro_region: Yup.number().required('Обязательное поле'),
  distance: Yup.number(),
  common_popularity: Yup.number(),
  owner: Yup.string(),
  description: Yup.string(),
  realization_period: Yup.string(),
  info: Yup.string(),
  info_add: Yup.string(),
  needs: Yup.string(),
});

export const ObjectCreationForm = () => {
  const dispatch = useAppDispatch();
  const { clickPosition: mapClickPosition } = useAppSelector(({ map }) => map);
  const [addObject, { isLoaded, isSuccess }] = useAddObjectMutation();
  const [isHidden, setIsHidden] = useState(false);
  const isBlocked = false;

  const formik = useFormik({
    initialValues: {
      name: '',
      lat: '',
      long: '',
      program_id: 0,
      place: '',
      macro_region: 0,
      distance: '',
      common_popularity: '',
      owner: '',
      description: '',
      realization_period: '',
      info: '',
      info_add: '',
      needs: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const preparedObject = {
        coords: [parseFloat(values.lat), parseFloat(values.long)],
        distance: parseInt(values.distance) ? parseInt(values.distance) : 0,
        common_popularity: parseInt(values.common_popularity) ? parseInt(values.common_popularity) : 0,
        name: values.name,
        branch_id: 1,
        macro_region: parseInt(values.macro_region),
        program_id: parseInt(values.program_id),
        place: !values.place.length ? '.' : values.place,
        owner: !values.owner.length ? '.' : values.owner,
        description: !values.description.length ? '.' : values.description,
        realization_period: !values.realization_period.length ? '.' : values.realization_period,
        info: !values.info.length ? '.' : values.info, 
        info_add: !values.info_add.length ? '.' : values.info_add,
        needs: !values.needs.length ? '.' : values.needs,
        type_id: 2,
        geocoded: 4,
        monitoring_project_id: 0,
      };

      addObject(preparedObject);

      dispatch(setCreateObjectModal(false));
    },
  });

  useEffect(() => {
    if (mapClickPosition) {
      const { lat, lng } = mapClickPosition;
      formik.setFieldValue('lat', lat);
      formik.setFieldValue('long', lng);
      setIsHidden(false);
    }
  }, [mapClickPosition]);


  // for initial form validation
  useEffect(() => {
    formik.validateForm();
  }, []);


  return (
    <CreationForm 
      title={'Добавление объекта'} 
      onSubmit={formik.handleSubmit} 
      isBlocked={isBlocked} 
      onCancel={() => dispatch(setCreateObjectModal(false))} 
      isHidden={isHidden} 
      isActive={formik.isValid}
    >
      <form className={styles.form} noValidate>
        <Input
          label="Наименование*" 
          placeholder="Введите наименование" 
          value={formik.values.name} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="name" 
        />
        <Input
          label="Широта*" 
          placeholder="Введите широту" 
          value={formik.values.lat} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="lat" 
        />
        <Input
          label="Долгота*" 
          placeholder="Введите долготу" 
          value={formik.values.long} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="long" 
        />
        <Button
          elementType={'button'}
          typeBtn={'secondary'}
          onClick={() => setIsHidden(true)}
          children={'Задать координаты на карте'} 
          showLeftIcon={true}
          leftIcon={<i className="icon icon-edit-1 icon-middle"></i>}
          style={{ gap: 4, marginTop: 16 }}

        />
        <Input
          label="Местоположение*" 
          placeholder="Введите местоположение" 
          value={formik.values.place} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="place" 
        />
        <CreationFormSelect 
          label="Макрорегион*" 
          search 
          options={macroRegionsArr} 
          handleFilter={handleFilter} 
          name="macro_region" 
          onChange={(value) => formik.setFieldValue('macro_region', value)} 
          value={formik.values.macro_region} 
          placeholder="Укажите макрорегион" 
        />

        <CreationFormSelect 
          label="Программа*" 
          search 
          options={programId} 
          handleFilter={handleFilter} 
          name="program_id" 
          onChange={(value) => formik.setFieldValue('program_id', value)} 
          value={formik.values.program_id} 
          placeholder="Укажите программу" 
        />
        <Input
          label="Расстояние до центра" 
          placeholder="Введите расстояние до центра" 
          value={formik.values.distance} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="distance" 
        />
        <Input
          label="Общая посещаемость" 
          placeholder="Введите общую посещаемость" 
          value={formik.values.common_popularity} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="common_popularity" 
        />
        <Input
          label="Инициатор" 
          placeholder="Введите инициатора" 
          value={formik.values.owner} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="owner" 
        />
        <CreationFormTextarea 
          label="Описание" 
          placeholder="Введите описание" 
          value={formik.values.description} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="description" 
        />
        <Input
          label="Период реализации"
          placeholder="Введите период реализации" 
          value={formik.values.realization_period} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="realization_period" 
        />
        <CreationFormTextarea 
          label="Информация о социальной значимости объекта" 
          placeholder="Введите информацию о социальной значимости объекта" 
          value={formik.values.info} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="info" 
        />
        <CreationFormTextarea 
          label="Дополнительная информация" 
          placeholder="Введите дополнительную информацию" 
          value={formik.values.info_add} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="info_add" 
        />
        <CreationFormTextarea 
          label="Описание нужд объекта" 
          placeholder="Введите описание нужд" 
          value={formik.values.needs} 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          name="needs" 
        />
      </form>
    </CreationForm>
  );
};
