import { useState } from 'react';
import { IObjectInfoProps } from './object-info.types';
import styles from './object-info.module.scss';
import { ObjectDelete } from '../object-delete/object-delete';
import { ObjectInfoMobile } from './object-info.mobile';
import { ObjectInfoDesktop } from './object-info.desktop';
import { useBreakpointsComparison } from '../../hooks';
import { ScreenSize } from '../../constants';
import { useIsDemoUser } from '../../state';
import { DemoObjectInfoDesktop } from './demo-object-info.desktop';

export const DemoObjectInfo = ({
  object,
  dividerTextLength = 40,
  onCloseSidebar,
  className = '',
  ...props
}: IObjectInfoProps): JSX.Element => {
  const isDemoUser = useIsDemoUser();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const isTablet = useBreakpointsComparison(ScreenSize.TP);

  return (
    <div className={`${styles['object-info']} ${className}`} {...props}>
      {deleteModal && (
        <ObjectDelete
          title={object?.name}
          objectId={object?.id}
          onClose={(e) => setDeleteModal(e)}
          onCloseSidebar={onCloseSidebar}
        />
      )}
      <div className={styles['object-info-content']}>
        {isTablet ? (
          <ObjectInfoMobile object={object} />
        ) : !isDemoUser ? (
          <ObjectInfoDesktop object={object} dividerTextLength={dividerTextLength} />
        ) : (
          <DemoObjectInfoDesktop object={object} dividerTextLength={dividerTextLength} />
        )}
      </div>
    </div>
  );
};

{
  /* <div className={styles['object-info-content']}>
{isTablet ? (
  <ObjectInfoMobile object={object} />
) : !isDemoUser ? (
  <ObjectInfoDesktop object={object} dividerTextLength={dividerTextLength} />
) : (
  <DemoObjectInfoDesktop object={object} dividerTextLength={dividerTextLength} />
)}
</div> */
}
