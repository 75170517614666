import { useEffect, useMemo } from 'react';
import { filterObjects, useAppDispatch } from '../../state';
import { useEnableObjectFiltersSelector } from '../../state/selector';
import batchUpdateHistory from '../../utils/batch-update-history';
import { usePrevious } from '../../hooks';
import isEqual from 'lodash/isEqual';
import { useMapSearchParams } from '../../hooks';

export const HistorySelectedFilters = () => {
  const [searchParams, setSearchParams] = useMapSearchParams();
  const dispatch = useAppDispatch();
  const filters = useEnableObjectFiltersSelector();
  const prevFilters = usePrevious(filters);

  useEffect(() => {
    const getFilterValueFromQuery = (queryName: string): number[] => {
      if (searchParams.get(queryName)) {
        return searchParams
          .get(queryName)
          ?.split(',')
          .map((el) => Number(el)) as number[];
      }
      return [];
    };

    const macroRegions = getFilterValueFromQuery('macro_region');
    const program = getFilterValueFromQuery('program');
    const startDate = getFilterValueFromQuery('start_of_building');
    const typeOfWork = getFilterValueFromQuery('type_of_work');
    const status = getFilterValueFromQuery('status');
    // DEMO /////////////////////////////////////////////////////////
    const demoObjectTypes = getFilterValueFromQuery('object_types');
    const demoFund = getFilterValueFromQuery('fund');
    // DEMO /////////////////////////////////////////////////////////

    dispatch(
      filterObjects({
        macro_region: macroRegions,
        program,
        start_of_building: startDate,
        type_of_work: typeOfWork,
        status,
        showOnlyFavorite: false,
        // DEMO /////////////////////////////////////////////////////////
        demoObjectTypes,
        demoFund,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isEqual(filters, prevFilters)) {
      batchUpdateHistory(
        [
          ['macro_region', filters.macro_region],
          ['program', filters.program],
          ['start_of_building', filters.start_of_building],
          ['type_of_work', filters.type_of_work],
          ['status', filters.status],
          ['show_favorite', filters.showOnlyFavorite],
          // DEMO /////////////////////////////////////////////////////////
          ['object_types', filters.demoObjectTypes],
          ['fund', filters.demoFund],
        ],
        searchParams,
        setSearchParams,
      );
    }
  }, [filters, searchParams, setSearchParams, prevFilters]);

  return null;
};
