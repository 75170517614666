import { useEffect } from 'react';
import { useCreateObjectModalSelector } from '../../state/selector';
import { setCreateObjectModal, useAppDispatch } from '../../state';
import { usePrevious } from '../../hooks';
import { useMapSearchParams } from '../../hooks';


export const HistoryCreateObject = () => {
  
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useMapSearchParams();
  const isObjectModalOpen = useCreateObjectModalSelector();
  const prevIsObjectModalOpen = usePrevious(isObjectModalOpen);
  
  
  useEffect(() => {
    if (searchParams.get('create-object')) {
      dispatch(setCreateObjectModal(true));
    }
  }, [dispatch]);
  
  
  useEffect(() => {
    if (prevIsObjectModalOpen !== isObjectModalOpen) {
      if (isObjectModalOpen) {
        searchParams.set('create-object', 'opened');
      } else {
        searchParams.delete('create-object');
      }
      setSearchParams(searchParams);
    }
  }, [searchParams, isObjectModalOpen, setSearchParams, prevIsObjectModalOpen]);
  
  return null;
};
