import { memo } from 'react';
import './geo-json.styles.scss';
import { GeoJSON } from 'react-leaflet';
import type { GeoJsonProps } from './geo-json.types';
import L from 'leaflet';


const CustomGeoJson = GeoJSON as any;


export const GeoJson = memo(({ 
  data,
  fillColor = '#fff',
  fillOpacity = .4,
  weight = 1,
  color = '#fff',
  invert = false,
  stroke,
  className = '',
  ...props
}: GeoJsonProps) => {

  return (
    <div>
      <CustomGeoJson 
        className={`${className} geo-json`}
        data={data}
        invert={invert}
        style={{
          fillColor,
          fillOpacity,
          weight,
          color,
          stroke,
        }}
        {...props}
      />
    </div>
  );
});
