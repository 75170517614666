import styles from './loader.module.scss';
import Spinner from '../spinner/spinner';
import { LoaderProps } from './loader.types';


export  const Loader = ({ 
  className = '', 
  ...props 
}: LoaderProps) => {
  
  return <div 
    className={`${styles.loader} ${className}`}
    {...props}
  ><Spinner/></div>;
};
