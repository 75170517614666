import { ObjectInterface } from '../../../types';
import { program, macroRegion, TYPE_OF_WORK } from '../../../constants';

export const getContentValues = (
  object?: ObjectInterface,
): Record<string, string | null> => {
  return {
    passability: object?.common_popularity ? `${object.common_popularity} человек` : null,
    employeeVisits: object?.population ? `${object.population} человек` : null,
    necessaryInvest: object?.money_needs
      ? isNaN(Number(object.money_needs.replace(',', '.')))
        ? object.money_needs
        : `${object.money_needs} млн. ₽`
      : null,
    distanceDistrictCenter: object?.distance ? `${object.distance} км` : null,
    programValue:
      typeof object?.program_id === 'number' ? program[object.program_id] : null,
    macroRegionValue:
      typeof object?.macro_region === 'number' ? macroRegion[object.macro_region] : null,
    owner: object?.owner && object.owner.length ? object.owner : null,
    typeOfWork: object?.type_of_work
      ? Object.values(TYPE_OF_WORK)[object.type_of_work]
      : TYPE_OF_WORK['0'],
    realizationPeriod: object?.realization_period ? object.realization_period : null,
  };
};
