import React from 'react';
import styles from './search-results.module.scss';
import { SearchResultsProps } from './search-results.types';
import { SearchSectionTitle } from '../search-section-title';


export const SearchResults = ({
  className,
  children,
  titleSuccess,
  searchResultsContainerRef,
  ...props
}: SearchResultsProps) => {
  
  return (
    <div
      className={styles.container}

    >
      {titleSuccess ?
        <SearchSectionTitle showIcon={false} text={'Вот, что мы смогли найти'}/> :
        <SearchSectionTitle showIcon={false}
          text={'По данному запросу ничего не найдено'}/>}
      <div ref={searchResultsContainerRef}
        className={styles['search-results-wrapper']}>
        {children}
      </div>
    </div>
  );
}; 
