import { useEffect, useState } from 'react';
import { EditorMenuProps } from './editor-menu.types';
import styles from './editor-menu.module.scss';
import { EditorMenuContent, EditorMenuTrigger } from './components';
import { useDropdown } from '../../hooks';


export const EditorMenuDesktop = ({ children }: EditorMenuProps) => {
  const [editorMenu, setEditorMenu] = useState<JSX.Element>(<></>);

  const { renderDropdown, isDropdownOpened } = useDropdown({
    body: <EditorMenuContent />,
    trigger: editorMenu,
  });

  useEffect(() => {
    setEditorMenu(<EditorMenuTrigger isMenuOpened={isDropdownOpened}/>);
  }, [isDropdownOpened]);

  return (
    <div className={styles['editor-menu-wrapper']}>{renderDropdown()}</div>
  );
};
