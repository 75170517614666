/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createPathComponent, LeafletElement } from '@react-leaflet/core';
import L from 'leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet.markercluster';
// TODO: think about https://github.com/SINTEF-9012/PruneCluster
import { IL } from './react-leaflet-markercluster.types';


const Leaflet = L as unknown as IL;

Leaflet.MarkerClusterGroup.include({
  _flushLayerBuffer() {
    this.addLayers(this._layerBuffer);
    this._layerBuffer = [];
  },

  addLayer(layer: string) {
    if (this._layerBuffer.length === 0) {
      setTimeout(this._flushLayerBuffer.bind(this), 50);
    }
    this._layerBuffer.push(layer);
  },
});

Leaflet.MarkerClusterGroup.addInitHook(function () {
  // @ts-ignore
  this._layerBuffer = [];
});

interface IProp<T> {
  [key: string]: T
}

const MarkerClusterGroup = createPathComponent(
  ({ children: _c, ...props }, ctx) => {
    const clusterProps = {
      iconCreateFunction: function (cluster: any) {
        const childCount = cluster.getChildCount();
        const colors = cluster
          .getAllChildMarkers()
          .map((item: any) => item.color);
        const isClusterOneColor = colors.every(
          (val: string, i: number, arr: string[]) => val === arr[0],
        ) as boolean;

        return new L.DivIcon({
          html:
            `<div class="marker-cluster-wrapper"><div class="marker-cluster-circle" style="background-color:${
              isClusterOneColor && !!colors[0] ? colors[0] : ''
            }; z-index:9999;"><span>` +
            `</span></div><div style="background-color: ${
              isClusterOneColor && colors[0] ? colors[0] : ''
            };" class="marker-cluster-layout"></div><p class="marker-cluster-count">${childCount}</p></div>`,
          className: 'marker-cluster',
          iconSize: new L.Point(40, 40),
        });
      },
    } as IProp<string | boolean | L.PathOptions | L.LeafletEventHandlerFnMap>;
    const clusterEvents = {} as IProp<
    string | boolean | L.PathOptions | L.LeafletEventHandlerFnMap
    >;

    // Splitting props and events to different objects
    Object.entries(props).forEach(([propName, prop]) => {
      if (propName.startsWith('on')) {
        return (clusterEvents[propName] = prop);
      }
      return (clusterProps[propName] = prop);
    });
    // Creating markerClusterGroup Leaflet element
    // @ts-ignore
    const markerClusterGroup = new L.markerClusterGroup(clusterProps);
    // Initializing event listeners
    Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
      const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
      markerClusterGroup.on(clusterEvent, callback);
    });

    ctx.map.on('zoomend', function () {
      const layers = markerClusterGroup.getLayers();
      markerClusterGroup.clearLayers();
      markerClusterGroup.addLayers(layers);
    });
    return {
      instance: markerClusterGroup,
      context: { ...ctx, layerContainer: markerClusterGroup },
    };
  },
  (instance, { children, ...props }, prevProps) => {
    // delete all layers cos react create a new elements
    // if u don't need this use original react leaflet markercluster
    // instance.clearLayers();
  },
);

export { MarkerClusterGroup };
