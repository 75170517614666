import styles from './profile-page.module.scss';
import { UserProfileMenu as Menu } from '../../components';
import { ProfileHeader } from '../../components/profile-header';
import { ProfileForm } from '../../components';


export const ProfilePage = () => {
  return (
    <div className={styles['profile-page-wrapper']}>
      <div className={styles['profile-page-content']}>
        <header className={styles['profile-page-content-header']}>
          <Menu />
        </header>
        <body className={styles['profile-page-content-body']}>
          <ProfileHeader />
          <ProfileForm/>
        </body>
      </div>
    </div>
  );
};
