import { useBreakpointsComparison, useSearchParamsAsObject } from '../hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import {  Map as MapConstants, ScreenSize } from '../constants';

 
export const useSidebarState = () => {
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const [{ isSidebarOpen = !isTablet ? '1' : '0', ...rest }, setSearchParams] = useSearchParamsAsObject();
  
  const handleChangeSidebarState = useCallback((value : boolean) => {
    setSearchParams({ ...rest, isSidebarOpen: value ? '1' : '0' });
  }, [isSidebarOpen, setSearchParams]);

  return [isSidebarOpen === '1', handleChangeSidebarState] as const;
};

export const useViewModeSelector = (): ['map' | 'list' | 'table', any] => {
  const [{ viewMode = 'map', objectID, ...rest }, setSearchParams] = useSearchParamsAsObject();
  const navigate = useNavigate();

  const selectViewMode = useCallback((viewMode : string) => {
    switch (viewMode) {
      case 'list':
        return 'list';
      case 'table':
        return 'table';
      case 'map':
      default:
        return 'map';
    }
  }, []);
  
  const handleChangeViewModeState = useCallback((value : string) => {
    // const queryParams = new URLSearchParams(history.location.search.replace('?', ''));
    // queryParams.set('viewMode', selectViewMode(value));
    // if (objectID) {
    //   navigate({ pathname: LINK_MAP_OBJECT_PATH(objectID), search: queryParams.toString() });
    // } else {
    //   navigate({ pathname: LINK_MAP_PATH, search: queryParams.toString() });
    // }

    // }, [rest]);
    setSearchParams({ viewMode: selectViewMode(value), ...rest });
  }, [viewMode, setSearchParams]);
  
  return [selectViewMode(viewMode), handleChangeViewModeState];
  
};


export const useLatLngZoomSelector = ()=> {
  const [{ lat = MapConstants.DEFAULT_LAT, lng = MapConstants.DEFAULT_LNG, zoom = MapConstants.DEFAULT_ZOOM,  ...rest }, setSearchParams] = useSearchParamsAsObject();
  
  const handleChangeLatLngState = useCallback((lat :  string, lng:  string, zoom :  string) => {
    setSearchParams({ lat, lng, zoom, ...rest });
  }, [lat, lng, setSearchParams]);
  
  return [{ lat, lng, zoom }, handleChangeLatLngState] as const;
  
};


export const useSelectedObjectSelector = (): number | null => {
  const { objectID } = useParams();
  
  return objectID ? Number(objectID) : null;
};
