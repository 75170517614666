import * as Yup from 'yup';


export const profileValidationSchema = Yup.object({
  name: Yup.string().required('Обязательное поле').matches(/^([а-яА-ЯёЁ\s-]+)$/, 'Допустимы только русская кириллица и тире').min(2, 'Минимальное количество символов - 2'),
  surname: Yup.string().required('Обязательное поле').matches(/^([а-яА-ЯёЁ\s-]+)$/, 'Допустимы только русская кириллица и тире').min(2, 'Минимальное количество символов - 2'),
  password: Yup.string().required('Обязательное поле').matches(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g, 'Минимальное количество символов - 8, обязательны латинские буквы верхнего и нижнего регистра и спецсимволы'),
  confirmPassword: Yup.string().required('Обязательное поле').oneOf([Yup.ref('password')], 'Пароли не совпадают')
    .matches(/(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g, 'Минимальное количество символов - 8, обязательны - латинские буквы верхнего и нижнего регистра и спецсимволы'),
  email: Yup.string().required('Обязательное поле').min(2, 'Минимальное количество символов - 2')
    .matches(/^(([^А-Яа-я<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Введите e-mail в формате user@domain.tld'),
});
