import { api } from './api';
import type { UserType, TokenType, LoginValues } from '../../types';


export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation<TokenType, LoginValues>({
      query(body) {
        return {
          url: '/auth/login',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    getUserInfo: builder.mutation<UserType, void>({
      query() {
        return {
          url: '/auth/user',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    updateUserData: builder.mutation<any, any>({
      query(formValue) {

        const form = new FormData();
        form.append('id', formValue.id);
        form.append('name', formValue.name);
        form.append('surname', formValue.surname);
        form.append('password', formValue.password);
        form.append('email', formValue.email);

        return {
          url: '/auth/user/update-user-data',
          method: 'POST',
          body: form,
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    addUserProfileImage: builder.mutation<any, any>({
      query({ id, image }) {

        const body = new FormData();
        body.append('id', id);
        body.append('img', image);

        return {
          url: '/auth/user',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      // invalidatesTags: ['User'],
    }),
    refreshUserToken: builder.mutation<UserType, void>({
      query() {
        return {
          url: '/auth/user',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    getFavoriteUserItems: builder.mutation<number[], void>({
      query() {
        return {
          url: '/auth/user/objects/get',
          method: 'POST',
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
    toggleFavoriteUserItem: builder.mutation<{ id: number }, { id: number }>({
      query(body) {
        return {
          url: '/auth/user/object/toggle',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
      invalidatesTags: ['User'],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetUserInfoMutation,
  useUpdateUserDataMutation,
  useAddUserProfileImageMutation,
  useRefreshUserTokenMutation,
  useGetFavoriteUserItemsMutation,
  useToggleFavoriteUserItemMutation,
} = userApi;