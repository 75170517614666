import type { ObjectInterface, ObjectType } from '../../../../types';
import L from 'leaflet';
import { getMacroColor } from './';
import { getMacroHoveredColor } from './market';

export const checkObjMacro = (object: ObjectInterface) => {
  if (object?.macro_region !== '0') return true;
};

export const getObjectIcon = (
  objectType: ObjectType | undefined,
  item: ObjectInterface,
  radius = 24,
  selected = false,
  disabled = false,
  isHeat = false,
  macro_region: any = false,
  dotsView = false,
  showName = false,
) => {
  const color = getMacroColor(Number(macro_region));
  const hoveredColor = getMacroHoveredColor(Number(macro_region));
  const activeIcon = L.divIcon({
    className: 'marker-sphere active',
    html: `<div 
            class="marker-circle-base active-marker"
            style="
              margin-top: -${radius}px;  
              right: 0; 
              background-color: ${color};
              --hoveredcolor: ${color}; 
              "
            >
            <div class="marker-sphere-text">
              <img 
                src=${objectType ? objectType.iconSelect! : ''}
                alt=${objectType ? objectType.name : ''}
                class="object-logo" 
              />
              </div>
              <div 
                class="marker-sphere-corner"
                style="
                  left: 18px; //${radius / 5.5}px
                  border: ${radius / 2}px solid transparent;
                  border-top: ${radius}px solid ${color};
                  width: 21px;
                  height: 0px;
                  bottom: -24px;
                  border: 10px solid transparent;
                  border-top: 17px solid ${color};
                "> </div>
          </div>
      <span 
          style="
            position: absolute;
            display: flex!important;
            justify-content: center;
            width: 150px;
            margin-top: 28px;
            left: -45px;
            text-align: center;
            font-weight: 600;
            color: ${color};
            font-size: 12px;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            word-break: break-word;
            line-height: 14px;
            letter-spacing: -0.4px;
            cursor: pointer;
          "
        >
          ${
            item.name.length > 45
              ? `${item.name.trim().slice(0, 45)}...`
              : item.name.trim()
          }
      </span>`,
    iconSize: [radius * 0.5, radius * 1.5],
    iconAnchor: [(radius * 1.5) / 2, (radius * 1.5) / 2],
  });
  const icon = L.divIcon({
    className: 'marker-sphere',
    html: `<div class="marker-circle-base"
              style="
                display: flex;
                justify-content: center;
                ${
                  dotsView
                    ? `margin-left: ${radius * 0.3}px;
    margin-top: -${radius * 0.01}px;`
                    : `margin-top: -${radius * 0.4}px;`
                }
                background-color: ${color}; 
                width: ${dotsView ? 18 : radius}px; 
                height: ${dotsView ? 18 : radius}px;
                box-shadow: 0px 0px 0px 2px #e3e3e3 inset;
                --hoveredcolor: ${hoveredColor};
              "
              >
              ${
                !dotsView
                  ? `
                <div class="marker-sphere-text">
                <img 
                  src="${objectType ? objectType.iconSelect! : ''}"
                  alt="${objectType ? objectType.name : ''}"
                  class="object-logo"
                />
              </div>
                `
                  : ''
              }
            ${
              showName
                ? `<span
            class="marker-object-title" 
          style="
            position: absolute;
            display: flex!important;
            justify-content: center;
            width: 150px;
            margin-top: 48px;
            color: ${color};
            font-size: 12px;
            text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
            word-break: break-word;
             --hoveredcolor: ${hoveredColor};
          "
        >
          ${
            item.name.length > 45
              ? `${item.name.trim().slice(0, 45)}...`
              : item.name.trim()
          }
      </span>`
                : ''
            }
          </div>`,
    iconSize: [radius, radius],
    iconAnchor: [radius / 2, radius / 2],
  });

  return selected ? activeIcon : icon;
};
