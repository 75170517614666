import { Search } from './search';
import { SearchWrapperProps } from './search.types';
import { useAppSelector } from '../../state';


export const SearchWrapper = ({ children }: SearchWrapperProps) => {
  const { items } = useAppSelector((state) => state.objects);

  return (
    <Search items={items}/>
  );
};