import {
  useDeleteObjectSelector,
} from '../../../state';
import { useViewModeSelector } from '../../../history-state/selectors';
import { useBreakpointsComparison } from '../../../hooks';
import { CardViewMobile } from './card-view.mobile';
import { CardViewDesktop } from './card-view.desktop';
import { ScreenSize } from '../../../constants';


export const CardView = ({ isLoading = false }) => {
  const [viewMode] = useViewModeSelector();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const isDeleteObject = useDeleteObjectSelector();


  return viewMode === 'list' || isDeleteObject ? (
    isTablet ? <CardViewMobile isLoading={isLoading} /> : <CardViewDesktop isLoading={isLoading} />
  ) : null;
};
