import { useSearchParams } from 'react-router-dom';
import { throttle } from 'lodash';


export const useMapSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetSearchParams = throttle(setSearchParams, 15000);
  
  return [searchParams, handleSetSearchParams] as const;
};
