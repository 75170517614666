import { memo, useEffect } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import { Map as MapConstants } from '../../../../constants';
import type { 
  LeafletMouseEvent, 
  LatLngExpression,
} from 'leaflet';
import L, { LatLng as LatLngType } from 'leaflet';
import { getClickPosition, useAppDispatch } from '../../../../state';
import { useOpenCoordsEditor } from '../../../../state/selector';
import { useCreateObjectModalSelector } from '../../../../state/selector';
import { useLatLngZoomSelector } from '../../../../history-state/selectors';
import type { HistoryMapLatLngProps } from './history-map-lat-lng.types';


export const HistoryMapLatLng = memo(({ changeDistanceValueHandler }: HistoryMapLatLngProps) => {
  const map = useMap();
  const dispatch = useAppDispatch();
  const isCoordsEditorOpen = useOpenCoordsEditor();
  const isCreateObjectModalOpen = useCreateObjectModalSelector();
  const [{ lat, lng, zoom }, setLatLngZoom] = useLatLngZoomSelector();
  const getClickPositionHandler = (position: LatLngType) => {
    L.DomUtil.addClass((map as any)._container,  'editor-cursor-enabled');
    dispatch(getClickPosition(position));
  };
  
  useMapEvents({
    click(e: LeafletMouseEvent) {
      const position = e.latlng;
      if (position && (isCoordsEditorOpen || isCreateObjectModalOpen)) {
        getClickPositionHandler(position);
      }
    },
    moveend() {
      if (map) {
        const mapParamsChange = {
          zoom: map.getZoom(),
          center: map.getCenter(),
        };
        if (mapParamsChange.center) {
          changeDistanceValueHandler(mapParamsChange.zoom);
          setLatLngZoom( mapParamsChange.center.lat.toString(), mapParamsChange.center.lng.toString(), mapParamsChange.zoom.toString());
        }
      }
    },
  });

  useEffect(() => {
    if (map) {
      let zoomVal: number;
      let center: LatLngExpression;
      if (lat || lng || zoom) {
        const latVal = Number(lat)
          ? Number(lat)
          : MapConstants.DEFAULT_LAT;

        const lngVal = Number(lng)
          ? Number(lng)
          : MapConstants.DEFAULT_LNG;
  
        zoomVal = Number(zoom)
          ? Number(zoom)
          : MapConstants.DEFAULT_ZOOM;
          

        center = [latVal, lngVal];
      } else {
        zoomVal = map.getZoom();
        center = map.getCenter();
      }

      map.flyTo(center, zoomVal, { duration: 0.5 });
    }
    
  }, [map]);
  

  return  null;
});
