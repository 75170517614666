import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { setGalleryObjectId, useEditorModeStateSelector } from '../../../state';
import { Button, ToolTip } from '../../common';
import { useGalleryObjectIdSelector } from '../../../state/selector';
import { useAppObjects } from '../../../state/selector/selector';
import { useDispatch } from 'react-redux';
import styles from './image-gallery-map.module.scss';
import { useAddObjectImageMutation, useDeleteObjectImageMutation } from '../../../state';
import { ServerErrorModal } from '../../server-error-modal/server-error-modal';
import { getImageUrl } from '../../../utils';
import { ImageGallery } from '..';
import { useSidebarState } from '../../../history-state/selectors';


export const ImageGalleryMap = () => {
  const isEditMode = useEditorModeStateSelector();
  const objects = useAppObjects();
  const galleryImg = useGalleryObjectIdSelector();
  const galleryImgId = galleryImg ? galleryImg.id : null;
  const dispatch = useDispatch();
  const [objId, setObjId] = useState<number>();
  const [indexListen, setIndexListen] = useState<number>(0);
  const obj = objects ? objects.find((item) => item.id === Number(galleryImg?.object_id)) : false;
  const [addObjectImage, { isError }] = useAddObjectImageMutation();
  const [deleteObjectImage] = useDeleteObjectImageMutation();
  const [, handleChangeSidebarState] = useSidebarState();

  const media = !!obj && !!obj.media && obj.media;

  const [openModalError, setOpenModalError] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e?.target;
    if (field.value) {
      const img = field && field?.files !== null && field?.files[0];
      
      img && addObjectImage({ image: img, id: Number(objId) });
    }
  };
  
  const galleryMedia: any = useMemo(() =>
    media &&
    media.map((item: any) => {
      return {
        originalAlt: `${obj.name} фото`,
        original: getImageUrl(item.path),
        thumbnail: getImageUrl(item.path_thumbnail ? item.path_thumbnail : item.path),
        id: item.id,
      };
    }), [media, obj, addObjectImage]);

  const handleSlide = useCallback((image: { id: number }) => {
    setIndexListen(image.id);
  }, [setIndexListen]);

  const handleDelete = () => {
    try {
      if (galleryMedia) {
        const mediaIndexToDelite = galleryMedia.findIndex(({ id }: {id: number}) => id === indexListen);
        deleteObjectImage({ id: galleryMedia[mediaIndexToDelite].id });
      }
    } catch (err : any) {
      throw new Error('Error occured ', err.toString());
    }
  };

  const closeGalleryHandler = () => {
    dispatch(setGalleryObjectId(null));
    handleChangeSidebarState(true);
  };
   
  const tollText = (
    <div className={styles['gallery-popup-message']}>
      <div className={styles['gallery-popup-message-body']}>
        Допустимый формат фотографии <b>png, jpg, jpeg</b>. Размер файла не должен
        превышать <b>15МБ</b>.
      </div>
    </div>
  );


  useEffect(() => {
    setOpenModalError(openModalError);
  }, [openModalError]);

  useEffect(() => {
    if (obj) {
      setObjId(obj.id);
    }
  }, [obj, objId]);

  useEffect(() => {
    setOpenModalError(isError);
  }, [isError]);
  
  return (
    !!obj  ? <div className={styles['gallery-panel']}>
      {openModalError &&
        <ServerErrorModal 
          isError={isError}
          onClose={() => setOpenModalError(false)}
        />
      }
      <div className={styles['gallery-panel_btn-box']}>
        {isEditMode && (
          <>
            <form>
              <label
                htmlFor="file-upload"
                className={styles['gallery-panel_btn-box_add-btn']}
              >
                <ToolTip
                  isVisible
                  content={tollText}
                >
                  Добавить фото
                  <input
                    onChange={handleChange}
                    id="file-upload"
                    type="file"
                    name="img"
                    value=""
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </ToolTip>
              </label>
            </form>
            <button
              onClick={handleDelete}
              className={styles['gallery-panel_btn-box_delete-btn']}
            >
              Удалить фото
            </button>
          </>
        )}
        <Button
          style={{ paddingTop: 3 }}
          elementType={'button'}
          onlyIcon={true}
          typeBtn={'uncolored'}
          className={'ms-auto me-0'}
          onClick={closeGalleryHandler}
          showLeftIcon
          leftIcon={<i className={'icon icon-close-3 icon-middle'} />}
        />
      </div>
      {!!obj && ( 
        <ImageGallery
          items={galleryMedia}
          initIndex={galleryMedia.findIndex((item: { id: number }) => item.id === galleryImgId) as number}
          onSlideChange={handleSlide}
          thumbnailClassName={styles['gallery-panel-thumbnails-container']}
          navigateButtonPrevClassName={styles['gallery-panel-navigation-button-prev']}
          navigateButtonNextClassName={styles['gallery-panel-navigation-button-next']}
        />
      )}
    </div> : null
  );
};
