import { ObjectInterface } from '../../../types';
import type { Map } from 'leaflet';
import { LatLng as LatLngType } from 'leaflet';
import { Map as MapConstants } from '../../../constants';

export const buildSearchResults = (
  items: [] | ObjectInterface[],
  inputQueryValue: string,
  isEditorMode: boolean,
) => {
  const filterObjectsByQuery = (item: ObjectInterface, query = '') => {
    const string = `${item.name ? item.name.toLowerCase() + ' ' : ''}${
      item.place ? item.place.toLowerCase() + ' ' : ''
    }${item.owner ? item.owner.toLowerCase() + ' ' : ''}`;

    return string.includes(query.toLowerCase());
  };

  const filteredResults = items.filter((item) => {
    return isEditorMode
      ? filterObjectsByQuery(item, inputQueryValue.toLowerCase())
      : Number(item.real_status) === 2 &&
          filterObjectsByQuery(item, inputQueryValue.toLowerCase());
  });

  const getCurrentSuggestionResult = (
    item: ObjectInterface | undefined,
    query: string,
  ) => {
    if (item) {
      console.log('item.name: ', item);
      const nameString = item.name || '';
      const placeString = item.place || '';
      const ownerString = item.owner || '';
      const stringArr = [
        ...nameString.split(' '),
        ...placeString.split(' '),
        ...ownerString.split(' '),
      ];

      const string = stringArr.find((item) =>
        item.toLowerCase().startsWith(query.toLowerCase()),
      );

      return string && string.slice(query.length);
    }
  };

  return {
    firstSuggestionId: (filteredResults && filteredResults[0]?.id) || null,
    suggestions: filteredResults,
    currentStringResult: getCurrentSuggestionResult,
  };
};

export const flyToChoosenSuggestionItem = (coordinates: number[], map: Map | null) => {
  if (map) {
    map.flyTo(coordinates as unknown as LatLngType, MapConstants.FLY_TO_ZOOM_VALUE, {
      duration: 0.5,
    });
  }
};
