export const program = {
  0: 'Другое',
  1: 'Восстановление ОКН',
  2: 'Социальная программа',
};

export const macroRegion = {
  0: 'Другие регионы',
  1: 'Витрина',
  2: 'Юг-Черноземье',
  3: 'Северо-запад',
  4: 'Дальний Восток',
};

export const Heading = {
  passability: 'Вместимость храма: ',
  employeeVisits: 'Количество сотрудников ПАО «Газпром»: ',
  necessaryInvest: 'Общий объем пожертвований: ',
  distanceDistrictCenter: 'До административного здания филиала: ',
  owner: 'Инициатор: ',
  place: 'Местоположение: ',
  macroRegionValue: 'Макрорегион: ',
  programValue: 'Программа: ',
  typeOfWork: 'Тип строительства: ',
  realizationPeriod: 'Срок реализации: ',
};

export const DemoHeading = {
  passability: 'Вместимость храма: ',
  employeeVisits: 'Количество сотрудников ПАО «Газпром»: ',
  necessaryInvest: 'Объем финансирования: ',
  distanceDistrictCenter: 'До административного здания филиала: ',
  owner: 'Инициатор: ',
  place: 'Местоположение: ',
  macroRegionValue: 'Макрорегион: ',
  programValue: 'Программа: ',
  typeOfWork: 'Тип работ: ',
  realizationPeriod: 'Срок реализации: ',
  objectType: 'Тип объекта: ',
  fund: 'Фонд: ',
};

export const START_OF_BUILDING = {
  0: 'Дата не указана',
  1: '2021',
  2: '2022',
  3: '2023',
};

export const TYPE_OF_WORK = {
  0: 'Тип не указан',
  1: 'Новое строительство',
  2: 'Завершение строительства',
  3: 'Ремонт и восстановление',
};

export const DEMO_OBJECT_TYPE = {
  0: 'Социальные объекты',
  1: 'Культурные объекты',
  2: 'Объекты здравоохранения',
  3: 'Образовательные объекты',
  4: 'Спортивные объекты',
};

export const DEMO_FUND = {
  0: 'Фонд социальных инициатив',
  1: 'Фонд "СВОИК"',
};

export const DEMO_TYPE_OF_WORK = {
  0: 'Новое строительство',
  1: 'Ремонт и восстановление',
};

export const DEMO_PROGRAM = {
  0: 'Другое',
  1: 'Газпром Детям',
  2: 'Объекты социальной значимости',
};
