import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '../../../common';
import { logout, useAppDispatch } from '../../../../state'; 
import { MAIN_PATH, PROFILE_PATH } from '../../../../constants'; 
import styles from '../profile-menu-map-page.module.scss';


export const ProfileMenuButtons = ({ isMobile = false }: { isMobile?: boolean }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  return (
    <>
      <Button
        className={styles['profile-menu-mobile-content-button']}
        elementType={'button'}
        text={'Перейти в профиль'}
        justifyContent={'start'}
        typeBtn={'uncolored'}
        showLeftIcon={true}
        leftIcon={<i className={`icon icon-user icon-middle icon-${isMobile ? 'tiny' : 'middle'}`}></i>}
        onClick={() => navigate({ pathname: `${MAIN_PATH}${PROFILE_PATH}`, search: searchParams.toString() })}
      />
      <Button
        className={styles['profile-menu-mobile-content-button']}
        text={'Выйти'}
        elementType={'button'}
        typeBtn={'danger'}
        showLeftIcon={true}
        justifyContent={'start'}
        onClick={() => dispatch(logout())}
        leftIcon={<i className={`icon icon-log-out icon-${isMobile ? 'tiny' : 'middle'}`}></i>}
      />
    </>
  );
};
