import styles from '../editor-menu.module.scss';
import { Button, SpanTag, Switch } from '../../common';
import { EditorMenuContentProps } from '../editor-menu.types';
import {
  setCreateObjectModal,
  setDeleteObject,
  toggleEditorMode,
  useAppDispatch,
  useEditorModeStateSelector,
} from '../../../state';
import { useViewModeSelector } from '../../../history-state/selectors';
import { useBreakpointsComparison } from '../../../hooks';
import { ScreenSize } from '../../../constants';


export const EditorMenuButtons = ({ children, ...props } : EditorMenuContentProps) => {
  const dispatch = useAppDispatch();
  const isEditorMode = useEditorModeStateSelector();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const [, setViewMode] = useViewModeSelector();

  return (
    <>
      <div 
        className={styles['editor-menu-content-switch']} 
        {...props}
      >
        <Switch
          id={'editor-menu-content-switch-id'}
          showLabel={false}
          checked={isEditorMode}
          onToggle={() => dispatch(toggleEditorMode())}
        />
        <SpanTag>Отображение редактора</SpanTag>
      </div>
      <Button
        size={isTablet ? 's' : 'm'}
        text={'Добавить объект'}
        elementType={'button'}
        typeBtn={'uncolored'}
        justifyContent={'start'}
        showLeftIcon={true}
        onClick={() => dispatch(setCreateObjectModal(true))}
        leftIcon={<i className={`icon icon-plus icon-${isTablet ? 'tiny' : 'middle'}`}></i>}
      />
      <Button
        size={isTablet ? 's' : 'm'}
        text={'Удалить объект'}
        elementType={'button'}
        typeBtn={'uncolored'}
        justifyContent={'start'}
        showLeftIcon={true}
        onClick={() => {
          setViewMode('list');
          dispatch(setDeleteObject(true));
        }}
        leftIcon={<i className={`icon icon-trashcan icon-${isTablet ? 'tiny' : 'middle'}`}></i>}
      />
    </>
  );
};
