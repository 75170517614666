import type { IObjectInfoDesktopProps } from './object-info.types';
import styles from './object-info.module.scss';
import { ToolTip } from '../common';
import {
  DEMO_FUND,
  DEMO_OBJECT_TYPE,
  DEMO_PROGRAM,
  DEMO_TYPE_OF_WORK,
  DemoHeading,
  TYPE_OF_WORK,
  macroRegion,
} from '../../constants';
import { PTag, SpanTag } from '../common';
import { demoObjectsInfo } from '../../__demo';
import { ObjectInterface } from '../../types';

export const getContentValues = (
  object?: ObjectInterface,
): Record<string, string | null> => {
  return {
    passability: object?.common_popularity ? `${object.common_popularity} человек` : null,
    employeeVisits: object?.population ? `${object.population} человек` : null,
    necessaryInvest: object?.money_needs
      ? isNaN(Number(object.money_needs.replace(',', '.')))
        ? object.money_needs
        : `${object.money_needs} млн. ₽`
      : null,
    distanceDistrictCenter: object?.distance ? `${object.distance} км` : null,
    programValue:
      typeof object?.program_id === 'number' ? DEMO_PROGRAM[object.program_id] : null,
    macroRegionValue:
      typeof object?.macro_region === 'number' ? macroRegion[object.macro_region] : null,
    owner: object?.owner && object.owner.length ? object.owner : null,
    typeOfWork: object?.type_of_work
      ? Object.values(TYPE_OF_WORK)[object.type_of_work]
      : TYPE_OF_WORK['0'],
    realizationPeriod: object?.realization_period ? object.realization_period : null,
  };
};

export const DemoObjectInfoDesktop = ({
  object,
  dividerTextLength = 40,
}: IObjectInfoDesktopProps): JSX.Element => {
  const contentValues = getContentValues(object);

  return (
    <>
      {/* {contentValues.passability ? (
        <ToolTip
          isVisible={
            Number(contentValues?.passability?.length) +
              Number(DemoHeading?.passability?.length) >
            dividerTextLength
          }
          content={contentValues.passability}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.passability}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.passability}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null} */}
      {/* {contentValues.employeeVisits ? (
        <ToolTip
          isVisible={
            Number(contentValues?.employeeVisits?.length) +
              Number(DemoHeading?.employeeVisits?.length) >
            dividerTextLength
          }
          content={contentValues.employeeVisits}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.employeeVisits}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.employeeVisits}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null} */}
      {contentValues.necessaryInvest ? (
        <ToolTip
          isVisible={
            Number(contentValues?.necessaryInvest?.length) +
              Number(DemoHeading?.necessaryInvest?.length) >
            dividerTextLength
          }
          content={contentValues.necessaryInvest}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.necessaryInvest}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.necessaryInvest}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {/* {contentValues.distanceDistrictCenter ? (
        <ToolTip
          isVisible={
            Number(contentValues?.distanceDistrictCenter?.length) +
              Number(DemoHeading?.distanceDistrictCenter?.length) >
            dividerTextLength
          }
          content={contentValues.distanceDistrictCenter}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.distanceDistrictCenter}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.distanceDistrictCenter}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null} */}
      {/* {contentValues.owner ? (
        <ToolTip
          isVisible={
            Number(contentValues.owner.length) + Number(DemoHeading?.owner?.length) >
            dividerTextLength
          }
          content={contentValues.owner}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.owner}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.owner}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null} */}
      {contentValues.macroRegionValue ? (
        <ToolTip
          isVisible={
            Number(contentValues?.macroRegionValue?.length) +
              Number(DemoHeading?.macroRegionValue?.length) >
            dividerTextLength
          }
          content={contentValues.macroRegionValue}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.macroRegionValue}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.macroRegionValue}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {contentValues.programValue ? (
        <ToolTip
          isVisible={
            Number(contentValues.programValue.length) +
              Number(DemoHeading?.programValue?.length) >
            dividerTextLength
          }
          content={contentValues.programValue}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.programValue}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.programValue}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {/* <ToolTip
        isVisible={
          Number(contentValues.typeOfWork!.length) +
            Number(DemoHeading?.typeOfWork?.length) >
          dividerTextLength
        }
        content={contentValues.typeOfWork}
      >
        <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
          {DemoHeading.typeOfWork}
          <SpanTag
            x={3.5}
            weight={1}
            className={styles['object-info-content-title-value']}
          >
            {contentValues.typeOfWork}
          </SpanTag>
        </PTag>
      </ToolTip> */}

      {contentValues.realizationPeriod ? (
        <ToolTip
          isVisible={
            Number(contentValues.realizationPeriod.length) +
              Number(DemoHeading.realizationPeriod.length) >
            dividerTextLength
          }
          content={contentValues.realizationPeriod.replace(/<[^>]*>/g, '')}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.realizationPeriod}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
              dangerouslySetInnerHTML={{ __html: contentValues.realizationPeriod.trim() }}
            />
          </PTag>
        </ToolTip>
      ) : null}

      {/* @ts-ignore */}
      {object && typeof demoObjectsInfo[object.id].typeOfWork === 'number' ? (
        <ToolTip
          isVisible={
            // @ts-ignore
            Number(DEMO_TYPE_OF_WORK[demoObjectsInfo[object.id].typeOfWork].length) +
              Number(DemoHeading?.typeOfWork?.length) >
            dividerTextLength
          }
          content={contentValues.typeO}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.typeOfWork}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {/* @ts-ignore */}
              {DEMO_TYPE_OF_WORK[demoObjectsInfo[object.id].typeOfWork]}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}

      {/* @ts-ignore */}
      {object && typeof demoObjectsInfo[object.id].objectType === 'number' ? (
        <ToolTip
          isVisible={
            // @ts-ignore
            Number(DEMO_OBJECT_TYPE[demoObjectsInfo[object.id].objectType].length) +
              Number(DemoHeading?.objectType?.length) >
            dividerTextLength
          }
          //  @ts-ignore
          content={DEMO_OBJECT_TYPE[demoObjectsInfo[object.id].objectType]}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.objectType}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {/* @ts-ignore */}
              {DEMO_OBJECT_TYPE[demoObjectsInfo[object.id].objectType]}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}

      {/* @ts-ignore */}
      {object && typeof demoObjectsInfo[object.id].fund === 'number' ? (
        <ToolTip
          isVisible={
            // @ts-ignore
            Number(DEMO_FUND[demoObjectsInfo[object.id].fund].length) +
              Number(DemoHeading?.fund?.length) >
            dividerTextLength
          }
          //  @ts-ignore
          content={DEMO_FUND[demoObjectsInfo[object.id].fund]}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {DemoHeading.fund}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {/* @ts-ignore */}
              {DEMO_FUND[demoObjectsInfo[object.id].fund]}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
    </>
  );
};
