import styles from '../editor-menu.module.scss';
import { EditorMenuContentProps } from '../editor-menu.types';
import { EditorMenuButtons } from './editor-menu-buttons';


export const EditorMenuContent = ({ children } : EditorMenuContentProps) => {
  return (
    <div className={styles['editor-menu-content']}>
      <EditorMenuButtons />
    </div>
  );
};
