export const DEMO_OBJECTS_IDS = [
  1553, 1554, 1555, 1469, 1556, 1557, 1558, 1559, 1560, 1561, 1562, 1563, 1564, 1565,
  1566,
];

export const demoObjectsInfo = {
  1469: {
    objectType: 1,
    typeOfWork: 1,
    fund: 1,
    type: 2,
  },
  1554: {
    objectType: 3,
    typeOfWork: 0,
    fund: 0,
    type: 4,
  },
  1555: {
    objectType: 1,
    typeOfWork: null,
    fund: null,
    type: 2,
  },
  1553: {
    objectType: 4,
    typeOfWork: null,
    fund: null,
    type: 3,
  },
  1556: {
    objectType: 4,
    typeOfWork: null,
    fund: null,
    type: 3,
  },
  1557: {
    objectType: 3,
    typeOfWork: 1,
    fund: 0,
    type: 4,
  },
  1558: {
    objectType: 2,
    typeOfWork: 0,
    fund: 0,
    type: 2,
  },
  1559: {
    objectType: 1,
    typeOfWork: 1,
    fund: 1,
    type: 2,
  },
  1560: {
    objectType: 3,
    typeOfWork: 0,
    fund: null,
    type: 4,
  },
  1561: {
    objectType: 2,
    typeOfWork: null,
    fund: 0,
    type: 4,
  },
  1562: {
    objectType: 3,
    typeOfWork: 1,
    fund: null,
    type: 2,
  },
  1563: {
    objectType: 2,
    typeOfWork: null,
    fund: null,
    type: 3,
  },
  1564: {
    objectType: 1,
    typeOfWork: 1,
    fund: 0,
    type: 2,
  },
  1565: {
    objectType: 3,
    typeOfWork: null,
    fund: 1,
    type: 3,
  },
  1566: {
    objectType: 0,
    typeOfWork: null,
    fund: null,
    type: 3,
  },
};
