import { GeoJson } from './geo-json';
import { GeoJsonObject } from 'geojson';
import { useEnableObjectFiltersSelector } from '../../../../state/selector';
import { getMacroRegionColor } from '../../../../utils';
import macroRegion1 from '../api/macro-region-1.json';
import macroRegion2 from '../api/macro-region-2.json';
import macroRegion3 from '../api/macro-region-3.json';
import macroRegion4 from '../api/macro-region-4.json';
import macroRegion5 from '../api/macro-region-5.json';
import russia from '../api/russia.json';
import L from 'leaflet';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { IS_PUBLIC } from '../../../../constants';

export const GeoJsonWrapper = () => {
  const { macro_region: macroRegion } = useEnableObjectFiltersSelector();
  const map = useMap();

  useEffect(() => {
    if (map) {
      if (macroRegion.length) {
        map.getContainer().style.setProperty('filter', 'brightness(90%)');
      } else {
        map.getContainer().style.setProperty('filter', 'brightness(100%)');
      }
    }
  }, [macroRegion]);

  const getData = (macro_region: number | string) => {
    if (macro_region === Number(1)) {
      return macroRegion1;
    }
    if (macro_region === Number(2) && !IS_PUBLIC) {
      return macroRegion2;
    }
    if (macro_region === Number(3)) {
      return macroRegion3;
    }
    if (macro_region === Number(4)) {
      return macroRegion4;
    }
    if (macro_region === Number(5)) {
      return macroRegion5;
    }
    return '';
  };

  return (
    <>
      {macroRegion.length
        ? macroRegion.map((regionIndex: number) => {
            return (
              <GeoJson
                key={`${regionIndex}${Math.random() * (1 - 50) + 10}`}
                data={getData(regionIndex) as GeoJsonObject}
                fillColor={getMacroRegionColor(regionIndex)}
                fillOpacity={0.4}
                weight={1}
                color={getMacroRegionColor(regionIndex)}
              />
            );
          })
        : null}

      <GeoJson
        className={'map-overlay'}
        data={russia as GeoJsonObject}
        invert={true}
        color={'#D82600'}
        stroke={false}
        fillColor={'#CFCFCF'}
        fillOpacity={0.7}
        weight={6}
        interactive={false}
        worldLatLngs={[
          L.latLng([90, 360]),
          L.latLng([90, -180]),
          L.latLng([-90, -180]),
          L.latLng([-90, 360]),
        ]}
        coordsToLatLng={(coords) => {
          if (coords[0] > -160) {
            return L.latLng(coords[1], coords[0]);
          } else {
            return L.latLng(coords[1], coords[0] + 360);
          }
        }}
      />
    </>
  );
};
