import { MouseEvent } from 'react';
import styles from './object-card.module.scss';
import { ObjectCardProps } from './object-card.types';
import { Button, H4Tag, SpanTag } from '../common';
import { getMainSidebarImageUrl } from '../../utils';


export const ObjectCardMobile = ({ 
  object, 
  setDeleteModal = () => {},  
  isDeleteObject = false,
}: ObjectCardProps) => {
  const photo =  getMainSidebarImageUrl(object);

  return (
    <>
      {
        photo && (
          <div 
            className={styles['card-mobile-image']} 
            style={{ backgroundImage: `url(${photo})` }}
          />
        )
      }
      <div 
        className={styles['card-mobile-info']}
      >
        <H4Tag 
          className={styles['card-header-title']}
          weight={3}
        >
          {object.name}
        </H4Tag>
        <SpanTag 
          className={styles['card-header-subtitle']}
          type={'caption'} 
          x={3} 
          weight={1}
        >
          {object.place}
        </SpanTag>
        
        {
          isDeleteObject && (
            <Button
              className={styles['card-mobile-info-object-info-delete-button']}
              elementType={'button'}
              style={{ marginTop: 12 }}
              typeBtn={'danger'}
              children={'Удалить объект'}
              onClick={(e: MouseEvent) => {
                e.stopPropagation();
                setDeleteModal(true);
              }}
              showLeftIcon
              leftIcon={ <i className={'icon icon-Trashcan icon-middle'} />}
            />
          )
        }

      </div>
    </>
  );
};
