import styles from './analytics-view.module.scss';
import { Button, HeaderContainer } from '../../common';
import { setAnalyticsViewOpen } from '../../../state';
import { useAppDispatch } from '../../../state/selector';
import { useBreakpointsComparison } from '../../../hooks';
import { ScreenSize } from '../../../constants';
import { useSidebarState } from '../../../history-state/selectors';


export const AnalyticsViewHeader = () => {
  const dispatch = useAppDispatch();
  const [, handleChangeSidebarState] = useSidebarState();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);

  const closeHandler = () => {
    dispatch(setAnalyticsViewOpen(false));
    handleChangeSidebarState(true);
  };

  return (
    <HeaderContainer
      isSidebarOpened={true}
      className={styles['content-wrapper-container']}
    >
      <div
        style={{ justifyContent: 'start' }}
        className={`${styles['header-wrapper']} ${styles['content-wrapper-start']}`}
      ></div>
      <div className={styles['header-wrapper']}></div>
      <div className={styles['right-btns-wrapper']}>
        <div className={styles['view-switcher-wrapper']}></div>
        <Button
          size={isTablet ? 's' : 'l'}
          style={{ width: 'fit-content' }}
          onClick={closeHandler}
          elementType={'button'}
          typeBtn={'elevation'}
          text={'Закрыть'}
          showLeftIcon={true}
          leftIcon={<i className={`icon icon-close-3 icon-${isTablet ? 'tiny' : 'middle'}`} />}
        />
      </div>
    </HeaderContainer>
  );
};
