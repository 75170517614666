export const getMacroRegionColor = (region: number | string) => {
  if (region === Number(1)) {
    return '#EE2D62';
  }
  if (region === Number(2)) {
    return '#F8931F';
  }
  if (region === Number(3)) {
    return '#4EC6DF';
  }
  if (region === Number(4)) {
    return '#45A040';
  }
  if (region === Number(5)) {
    return '#8D2dEE';
  }
  return '#94AAAF';
};
