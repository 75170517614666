import { SidebarZonesProps } from './sidebar-zones.types';
import styles from './sidebar-zones.module.scss';
import { MonitoringViewItem } from '../monitoring-view-item';
import { useParams } from 'react-router-dom';
import { SidebarContainer } from '../common';


export const SidebarZones = ({ lineContent, groupContent, ...props } : SidebarZonesProps) => {
  const { lineID } = useParams();

  return (
    <SidebarContainer className={styles.sidebar}>
      <div 
        className={styles['content-wrapper']}
        {...props}
      >
        <div
          className={styles['sidebar-header']}
        />
        <div className={styles['levels-cards']}>
          {
            lineContent.length ? lineContent.map((el: any) => {
              return <MonitoringViewItem 
                key={el.id}
                object={el}
                isGroup={false}
                isActive={Number(lineID) === Number(el.id)}
              />;
            }) : null
          }
        </div>
      </div>
    </SidebarContainer>
  );
};
