import React, { SyntheticEvent, useState } from 'react';
import { useAppDispatch, useDeleteObjectMutation } from '../../state';
import { IDeleteObjectProps } from './object-delete.types';
import { useObjectUpdateErrorSelector } from '../../state/selector';
import { Button } from '../common';
import styles from './object-delete.module.scss';
import { SnackBar } from '../common/snack-bar/snack-bar';
import { useBreakpointsComparison } from '../../hooks';
import { ScreenSize } from '../../constants';


export const ObjectDelete = ({ title = '', objectId, onClose, onCloseSidebar }:IDeleteObjectProps): JSX.Element => {

  const dispatch = useAppDispatch();
  const [onOpenSnackBar, setOnOpenSnackBar] = useState<boolean>(false);
  const [animationStatus, setAnimationStatus] = useState<string>('modal-wrapper-open');
  const [deleteObject] = useDeleteObjectMutation();
  const isMobile = useBreakpointsComparison(ScreenSize.MP);

  const handleDelete = (e: boolean) => {
    setAnimationStatus('modal-wrapper-close');
    setTimeout(() => {
      setOnOpenSnackBar(false);
      if (onClose) {
        onClose(false);
      }
      deleteObject({ id: objectId });
      if (onCloseSidebar) {
        onCloseSidebar();
      }
    }, 100);
  };

  return (
    <div className={styles['modal-wrapper']}>
      <div className={styles['modal-wrapper-background']} />
      <div className={` ${!onOpenSnackBar ? styles['modal-wrapper-window'] : styles[animationStatus]}`}>
        {!onOpenSnackBar && <div className={styles['object-delete-wrapper']}>
          <div className={styles['object-delete-wrapper-head']}>
            <div className={styles['object-delete-wrapper-head-title']}>
              Удаление объекта
            </div>
            <div className={styles['object-delete-wrapper-head-text']}>
              <div>Вы уверены, что хотите удалить объект:</div>
              <div className={styles['object-delete-wrapper-head-text-label']}>{title}</div>
            </div>
          </div>
          <div className={styles['object-delete-wrapper-buttons']}>
            <Button
              className={styles['object-delete-wrapper-buttons-close']}
              onClick={() => {
                if (onClose) {
                  onClose(false);
                }
              }}
              elementType={'button'}
              typeBtn={'uncolored'}
              size={'s'}
              children={isMobile ? 'Оставить' : 'Оставить объект'}
            />
            <Button
              className={styles['object-delete-wrapper-buttons-delete']}
              onClick={() => {
                setOnOpenSnackBar(true);
              }}
              elementType={'button'}
              typeBtn={'danger'}
              size={'s'}
              children={isMobile ? 'Удалить' : 'Удалить объект'}
            />
          </div>
        </div>}
        <SnackBar onOpen={onOpenSnackBar}
          onDelete={(e) => {
            handleDelete(e);
          }}
          onClose={onClose}
          title={'Объект удалён'}
          titleButton={'Вернуть'}
          typeBtn={'uncolored'}
          duration={5}
        />
      </div>
    </div>
  );
};
