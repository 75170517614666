import { useCallback, useEffect, useState } from 'react';
import styles from './viewing-content.module.scss';
import { CONTENT_TYPES } from '../../constants';
import { nanoid } from 'nanoid';
import { BottomSheet } from '../common/bottom-sheet';
import { Button, SpanTag } from '../common';
import type { ViewingContentMobileProps } from './viewing-content.types';
import { ContentTypes } from '../../types/monitoring';


const types = CONTENT_TYPES as Record<string, any>;

export const ViewingContentMobile = ({
  handleClickContentBtn,
  activeView,
  isContentBtnDisabled,
  avaibleTypes = [],
}: ViewingContentMobileProps) => {
  const [isOpened, setOpened] = useState<boolean>(false);
  const [isNoContentToolTipActive, setNoContentToolTipActive] = useState<boolean>(false);

  const showTooltipTimeout = useCallback(() => {
    if (isNoContentToolTipActive) return;

    setNoContentToolTipActive(true);
    const timeout = setTimeout(() => setNoContentToolTipActive(false), 3000);

    return () => clearTimeout(timeout);
  }, [isNoContentToolTipActive]);

  const openBottmSheetHandler = () => {
    if (avaibleTypes.length === 1 && avaibleTypes[0].contentType === activeView) {
      showTooltipTimeout();
    } else {
      setOpened(!isOpened);
    }
  };

  const selectItemHandler = (type: ContentTypes) => {
    handleClickContentBtn && handleClickContentBtn(type);
    setOpened(false);
  };

  
  return (
    <>
      <div className={styles['viewing-content-container']}>
        <Button
          size={'s'}
          elementType={'button'}
          typeBtn={'elevation'}
          showLeftIcon={true}
          isActive={!isContentBtnDisabled}
          onClick={openBottmSheetHandler}
          leftIcon={activeView && types[activeView] && types[activeView].ICON}
        >
          { activeView && types[activeView] && types[activeView].NAME }
        </Button>

        <BottomSheet
          open={isOpened}
          onDismiss={() => setOpened(!isOpened)}
          title={'Переключение типа контента'}
          blocking
        >
          <ul className={styles['viewing-content-container-mobile-list']}>

            {Object.entries(types).map((item) => {
              const isDisabled = !Boolean(isContentBtnDisabled && isContentBtnDisabled(item[1]?.TYPE));

              return  isDisabled ? null : (
              <li>
                <Button
                  key={nanoid()}
                  className={styles['viewing-content-container-mobile-list-button']}
                  onClick={() => selectItemHandler(item[1].TYPE)}
                  size={'m'}
                  elementType={'button'}
                  typeBtn={'uncolored'}
                  showLeftIcon={true}
                  isActive={item[1].TYPE === activeView}
                  leftIcon={item[1].ICON}
                >
                  {item[1].NAME}
                </Button>
              </li>
              );
            })}

          </ul>
        </BottomSheet>
      </div>
      <SpanTag
        x={3.5}
        weight={3}
        className={`
            ${styles['no-content-tooltip']}
            ${
              isNoContentToolTipActive
                ? styles['no-content-tooltip-active']
                : ' '
    }
            `}
      >
                За выбранную дату нет других типов контента
      </SpanTag>
    </>
  );
};
