import styles from './login-page.module.scss';
import loginPageBackground from '../../images/login-page-background.jpg';
import { LoginView } from '../../components';
import { useLoginUserMutation } from '../../state';
import type { LoginValues } from '../../types';
import { IS_PUBLIC } from '../../constants';
import { useEffect, useState } from 'react';

interface ErrorType {
  data: {
    message: string;
  };
  status: number;
}
export const LoginPage = (): JSX.Element => {
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [loginUser, { isLoading, error }] = useLoginUserMutation();

  const submitHandler = async (values: LoginValues) => {
    try {
      await loginUser(values);
    } catch (e) {}
  };

  useEffect(() => {
    setIsPublic(IS_PUBLIC);
  }, []);

  return (
    <div
      className={`${styles['login-wrapper']} ${
        isPublic ? styles['login-wrapper-public'] : ''
      }`}
    >
      {!isPublic && (
        <img
          src={loginPageBackground}
          alt="Фоновая картинка"
          className={styles['login-background']}
        />
      )}
      <LoginView
        error={error ? (error as ErrorType).data?.message : false}
        onLoginSubmit={submitHandler}
        isFormDisabled={isLoading}
      />
    </div>
  );
};
