import { memo, useLayoutEffect, useState } from 'react';
import { SidebarFilterProps } from './sidebar-filter.types';
import styles from './sidebar-filter.module.scss';
import { Button, FilterCheckbox, FilterGroup, H3Tag } from '../common';
import { FilterBottomPanel } from './filter-bottom-panel';
import {
  macroRegions,
  programs,
  demoPrograms,
  realisationStatuses,
  typeOfWork,
  demoTypeOfWork,
  demoObjectType,
  demoFund,
} from './data';
import { useBreakpointsComparison } from '../../hooks';
import { IS_PUBLIC, ScreenSize } from '../../constants';
import { useIsDemoUser } from '../../state';

const setFunction = (value: number, arr: number[]): number[] => {
  if (arr.includes(value)) {
    const newState = [...arr];
    newState.splice(
      arr.findIndex((el: number) => el === value),
      1,
    );
    return [...newState];
  } else {
    return [...arr, value];
  }
};

export const SidebarFilter = ({
  editorMode = false,
  onFilter,
  externalMacroRegions = [],
  externalProgram = [],
  externalStartOfBuilding = [],
  externalTypeOfWork = [],
  externalRealisationStatus = [],
  // DEMO //////////////////////////////////////////////////////////////////////
  externalDemoObjectTypes = [],
  externalDemoFund = [],
  // DEMO //////////////////////////////////////////////////////////////////////
  loading = false,
  externalShowOnlyFavorite = false,
  children,
}: SidebarFilterProps) => {
  const isDemoUser = useIsDemoUser();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const isMobile = useBreakpointsComparison(ScreenSize.MP);

  const [selectedMacroRegions, setSelectedMacroRegions] = useState<number[]>(
    () => externalMacroRegions,
  );
  const [selectedProgram, setSelectedProgram] = useState<number[]>(() => externalProgram);
  const [selectedStartOfBuilding, setStartOfBuilding] = useState<number[]>(
    () => externalStartOfBuilding,
  );
  const [selectedTypeOfWork, setTypeOfWork] = useState<number[]>(
    () => externalTypeOfWork,
  );
  // DEMO //////////////////////////////////////////////////////////////////////
  const [selectedDemoObjectTypes, setSelectedDemoObjectTypes] = useState<number[]>(
    () => externalDemoObjectTypes,
  );
  const [selectedDemoFund, setSelectedDemoFund] = useState<number[]>(
    () => externalDemoFund,
  );
  // DEMO //////////////////////////////////////////////////////////////////////

  const [selectedRealisationStatus, setSelectedRealisationStatus] = useState<number[]>(
    () => externalRealisationStatus,
  );
  const [showOnlyFavorite, setShowOnlyFavorite] = useState<boolean>(
    () => externalShowOnlyFavorite,
  );

  const handleSelectMacroRegion = (region: number) => {
    const newRegions = setFunction(region, selectedMacroRegions);
    setSelectedMacroRegions(newRegions);
    onFilter(
      newRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const handleSelectStartOfBuilding = (year: number) => {
    const newStartDate = setFunction(year, selectedStartOfBuilding);
    setStartOfBuilding(newStartDate);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      newStartDate,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const handleSelectTypeOfWork = (type: number) => {
    const newTypeOfWork = setFunction(type, selectedTypeOfWork);
    setTypeOfWork(newTypeOfWork);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      newTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const handleSelectProgram = (program: number) => {
    const newProgram = setFunction(program, selectedProgram);
    setSelectedProgram(newProgram);
    onFilter(
      selectedMacroRegions,
      newProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const handleSelectRealisation = (status: number) => {
    const newStatus = setFunction(status, selectedRealisationStatus);
    setSelectedRealisationStatus(newStatus);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      newStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const handleSelectFavorite = (showFavorite: boolean) => {
    setShowOnlyFavorite(showFavorite);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const removeAllMacroFilters = () => {
    setSelectedMacroRegions([]);
    onFilter(
      [],
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const removeAllPrograms = () => {
    setSelectedProgram([]);
    onFilter(
      selectedMacroRegions,
      [],
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const removeAllDates = () => {
    setStartOfBuilding([]);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      [],
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const removeAllTypes = () => {
    setTypeOfWork([]);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      [],
      selectedRealisationStatus,
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  const removeAllRealisationStatus = () => {
    setSelectedRealisationStatus([]);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      [],
      showOnlyFavorite,
      // DEMO ////////////////////////////////////
      selectedDemoObjectTypes,
      selectedDemoFund,
    );
  };

  useLayoutEffect(() => {
    setSelectedProgram(externalProgram);
    setSelectedMacroRegions(externalMacroRegions);
    setSelectedRealisationStatus(externalRealisationStatus);
    setTypeOfWork(externalTypeOfWork);
    setSelectedDemoObjectTypes(externalDemoObjectTypes);
    setSelectedDemoFund(externalDemoFund);
  }, [
    externalProgram,
    externalMacroRegions,
    externalRealisationStatus,
    externalTypeOfWork,
    externalDemoObjectTypes,
    externalDemoFund,
  ]);

  const resetAllFilters = () => {
    setSelectedProgram([]);
    setSelectedMacroRegions([]);
    setSelectedRealisationStatus([]);
    setStartOfBuilding([]);
    setTypeOfWork([]);
    setShowOnlyFavorite(false);
    setSelectedDemoObjectTypes([]);
    setSelectedDemoFund([]);
    // onFilter([], [], [], [], [], false);
    // DEMO ////////////////////////////////////
    onFilter([], [], [], [], [], false, [], []);
  };

  // DEMO ///////////////////////////////////////////////////////////////////////////////////////////////

  const handleSelectDemoObjectType = (type: number) => {
    const newTypes = setFunction(type, selectedDemoObjectTypes);
    setSelectedDemoObjectTypes(newTypes);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      newTypes,
      selectedDemoFund,
    );
  };

  const removeAllDemoObjectTypes = () => {
    setSelectedDemoObjectTypes([]);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      [],
      selectedDemoFund,
    );
  };

  const handleSelectDemoFund = (fond: number) => {
    const newFund = setFunction(fond, selectedDemoFund);
    setSelectedDemoFund(newFund);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      selectedDemoObjectTypes,
      newFund,
    );
  };

  const removeAllDemoFund = () => {
    setSelectedDemoFund([]);
    onFilter(
      selectedMacroRegions,
      selectedProgram,
      selectedStartOfBuilding,
      selectedTypeOfWork,
      selectedRealisationStatus,
      showOnlyFavorite,
      selectedDemoObjectTypes,
      [],
    );
  };

  // DEMO ///////////////////////////////////////////////////////////////////////////////////////////////

  const showedMacroRegions = macroRegions.filter((el) => {
    if (IS_PUBLIC && !isDemoUser) {
      return el.id !== 2 && el.show;
    }
    return el.show;
  });
  const showedPrograms = !isDemoUser ? programs.filter((el) => el.show) : demoPrograms;
  const showedRealisationStatuses = realisationStatuses.filter((el) => el.show);
  const showTypeOfWork = !isDemoUser ? typeOfWork.slice(1) : demoTypeOfWork;

  return (
    <div className={styles['content-wrapper']}>
      <div className={styles.header}>
        <Button
          size={isMobile ? 's' : 'm'}
          elementType={'button'}
          onClick={() => handleSelectFavorite(!showOnlyFavorite)}
          typeBtn={'secondary'}
          text={'Показывать только избранные'}
          isActive={showOnlyFavorite}
          showRightIcon={true}
          rightIcon={
            <i className={`icon icon-star icon-${isTablet ? 'tiny' : 'middle'}`}></i>
          }
        />
      </div>
      <div className={styles.body}>
        <div className={styles['body-content-wrapper']}>
          <div className={styles.title}>
            <H3Tag weight={3}>Макрорегионы</H3Tag>
            {selectedMacroRegions.length ? (
              <Button
                className={styles['btn-clear']}
                onClick={removeAllMacroFilters}
                elementType={'button'}
                size="s"
                typeBtn={'uncolored'}
                text={'Очистить'}
                justifyContent={'end'}
              />
            ) : null}
          </div>

          <FilterGroup rowLength={2} style={{ padding: 0 }}>
            {showedMacroRegions.map(({ text, leftBorderColor, id }, i) => (
              <FilterCheckbox
                disabled={loading}
                handleChange={() => handleSelectMacroRegion(Number(id))}
                key={i}
                leftBorderColor={leftBorderColor}
                isChecked={selectedMacroRegions.includes(id)}
              >
                {text}
              </FilterCheckbox>
            ))}
          </FilterGroup>
        </div>
        <div className={styles['body-content-wrapper']}>
          <div className={styles.title}>
            <H3Tag weight={3}>Программа</H3Tag>
            {selectedProgram.length ? (
              <Button
                onClick={removeAllPrograms}
                className={styles['btn-clear']}
                elementType={'button'}
                size="s"
                typeBtn={'uncolored'}
                text={'Очистить'}
                justifyContent={'end'}
              />
            ) : null}
          </div>
          <FilterGroup style={{ padding: 0, width: '100%' }}>
            {showedPrograms.map(({ text, id }, i) => (
              <FilterCheckbox
                disabled={loading}
                handleChange={() => handleSelectProgram(id)}
                key={i}
                isChecked={selectedProgram.includes(id)}
              >
                {text}
              </FilterCheckbox>
            ))}
          </FilterGroup>
        </div>
        <div className={styles['body-content-wrapper']}>
          <div className={styles.title}>
            <H3Tag weight={3}>Тип работ</H3Tag>
            {selectedTypeOfWork.length ? (
              <Button
                onClick={removeAllTypes}
                className={styles['btn-clear']}
                elementType={'button'}
                size="s"
                typeBtn={'uncolored'}
                text={'Очистить'}
                justifyContent={'end'}
              />
            ) : null}
          </div>
          <FilterGroup style={{ padding: 0, width: '100%' }}>
            {showTypeOfWork.map(({ text, id }, i) => (
              <FilterCheckbox
                disabled={loading}
                handleChange={() => handleSelectTypeOfWork(id)}
                key={i}
                isChecked={selectedTypeOfWork.includes(id)}
              >
                {text}
              </FilterCheckbox>
            ))}
          </FilterGroup>
        </div>
        {isDemoUser && (
          <div className={styles['body-content-wrapper']}>
            <div className={styles.title}>
              <H3Tag weight={3}>Тип объекта</H3Tag>
              {selectedDemoObjectTypes.length ? (
                <Button
                  onClick={removeAllDemoObjectTypes}
                  className={styles['btn-clear']}
                  elementType={'button'}
                  size="s"
                  typeBtn={'uncolored'}
                  text={'Очистить'}
                  justifyContent={'end'}
                />
              ) : null}
            </div>
            <FilterGroup style={{ padding: 0, width: '100%' }}>
              {demoObjectType.map(({ text, id }, i) => (
                <FilterCheckbox
                  disabled={loading}
                  handleChange={() => handleSelectDemoObjectType(id)}
                  key={i}
                  isChecked={selectedDemoObjectTypes.includes(id)}
                >
                  {text}
                </FilterCheckbox>
              ))}
            </FilterGroup>
          </div>
        )}
        {isDemoUser && (
          <div className={styles['body-content-wrapper']}>
            <div className={styles.title}>
              <H3Tag weight={3}>Фонд</H3Tag>
              {selectedDemoFund.length ? (
                <Button
                  onClick={removeAllDemoFund}
                  className={styles['btn-clear']}
                  elementType={'button'}
                  size="s"
                  typeBtn={'uncolored'}
                  text={'Очистить'}
                  justifyContent={'end'}
                />
              ) : null}
            </div>
            <FilterGroup style={{ padding: 0, width: '100%' }}>
              {demoFund.map(({ text, id }, i) => (
                <FilterCheckbox
                  disabled={loading}
                  handleChange={() => handleSelectDemoFund(id)}
                  key={i}
                  isChecked={selectedDemoFund.includes(id)}
                >
                  {text}
                </FilterCheckbox>
              ))}
            </FilterGroup>
          </div>
        )}
        {editorMode && (
          <div className={styles['body-content-wrapper']}>
            <div className={styles.title}>
              <H3Tag weight={3}>Статус реализации</H3Tag>
              {selectedRealisationStatus.length > 0 && (
                <Button
                  onClick={removeAllRealisationStatus}
                  className={styles['btn-clear']}
                  elementType={'button'}
                  size="s"
                  typeBtn={'uncolored'}
                  text={'Очистить'}
                  justifyContent={'end'}
                />
              )}
            </div>
            <FilterGroup rowLength={2} style={{ padding: 0, width: '100%' }}>
              {showedRealisationStatuses.map(({ text, id }, i) => (
                <FilterCheckbox
                  disabled={loading}
                  handleChange={() => handleSelectRealisation(id)}
                  key={i}
                  isChecked={selectedRealisationStatus.includes(id)}
                >
                  {text}
                </FilterCheckbox>
              ))}
            </FilterGroup>
          </div>
        )}

        {children}
      </div>
      <FilterBottomPanel
        editorMode={editorMode}
        onReset={resetAllFilters}
        loading={loading}
        isResetButtonActive={
          !!externalMacroRegions.length ||
          !!externalProgram.length ||
          !!externalRealisationStatus.length ||
          !!externalStartOfBuilding.length ||
          !!externalTypeOfWork.length ||
          showOnlyFavorite ||
          // DEMO //////////////////////////////////////
          !!externalDemoObjectTypes.length ||
          !!externalDemoFund.length
        }
      ></FilterBottomPanel>
    </div>
  );
};
