import styles from './view-wrapper.module.scss';
import { Portal } from '../../common';
import type { ViewWrapperProps } from './view-wrapper.types';


export const ViewWrapper = ({ children, className, ...props }: ViewWrapperProps) => {
  return (
    <Portal 
      className={styles['portal-container']}
      parentSelector={'.map-container'}
      {...props}
    >
      {children}
    </Portal>
  );
};
