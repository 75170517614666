import { DemoObjectInfo, ObjectInfo } from '../../components/object-info';
import styles from './sidebar-object-info.module.scss';
import { ObjectInfoBox } from '../object-info-box';
import type { SidebarObjectInfoProps } from './sidebar-object-info.types';
import { PhotosSwitcher } from '../photos-switcher';
import { PhotoType } from '../photos-switcher/photos-switcher.types';
import { SidebarSelectEditField } from '../sidebar-select-edit-field/sidebar-select-edit-field';
import { macroRegions, programs, startDate, typeOfWork } from '../sidebar-filter/data';
import { getMappedGalleryObject } from '../../utils/get-mapped-gallery-object';
import { ObjectButtons } from '../object-buttons';
import { Button } from '../common';
import { SidebarObjectInfoHeading } from './api';
import { useBreakpointsComparison } from '../../hooks';
import { useState } from 'react';
import { ScreenSize } from '../../constants';
import { useIsDemoUser } from '../../state';

export const SidebarObjectInfo = ({
  object,
  onCloseClick,
  onImageClick,
  editorMode,
  onEditField,
  isObjectUpdating = false,
  onChangeObject,
  isMonitoringActive = false,
  handleOpenAnalytics,
  flyToObject,
  isAnalyticsViewOpen,
}: SidebarObjectInfoProps): JSX.Element => {
  const isDemoUser = useIsDemoUser();
  const media = getMappedGalleryObject(object?.media, object.name, object.id);
  const handleImageClick = (item: PhotoType) => onImageClick(item);
  const isMobile = useBreakpointsComparison(ScreenSize.MP);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  return (
    <>
      <div className={styles['bg-element']}>
        {!isDemoUser ? (
          <ObjectInfo
            object={object}
            editorMode={editorMode}
            isMonitoringActive={isMonitoringActive}
            dividerTextLength={
              window.innerWidth >= 1370
                ? 50
                : window.innerWidth >= 1024
                ? 33
                : window.innerWidth >= 768
                ? 22
                : window.innerWidth >= 430
                ? 50
                : 35
            }
          />
        ) : (
          <DemoObjectInfo
            object={object}
            editorMode={editorMode}
            isMonitoringActive={isMonitoringActive}
            dividerTextLength={
              window.innerWidth >= 1370
                ? 50
                : window.innerWidth >= 1024
                ? 33
                : window.innerWidth >= 768
                ? 22
                : window.innerWidth >= 430
                ? 50
                : 35
            }
          />
        )}
      </div>
      <div>
        <div className={styles['buttons-container']}>
          <ObjectButtons
            object={object}
            editorMode={editorMode}
            flyToObject={flyToObject}
            setDeleteModal={setDeleteModal}
          />
        </div>
        {!isMobile && (
          <div className={styles['buttons-container']}>
            <Button
              className={styles['object-info-button']}
              elementType={'button'}
              style={{ marginTop: 8 }}
              typeBtn={'secondary'}
              children={'Текущий статус работ'}
              showLeftIcon
              onClick={handleOpenAnalytics}
              size={'s'}
              leftIcon={
                isAnalyticsViewOpen && <i className={'icon icon-close-3 icon-tiny'} />
              }
            />
          </div>
        )}
      </div>
      <PhotosSwitcher
        className={styles['photo-switcher']}
        onImageClick={handleImageClick}
        photoList={media}
        editorMode={editorMode}
      />
      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.descriptionObject}
        content={object?.description}
        type="description"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
      />
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.programValue}
          isLoading={isObjectUpdating}
          content={object.program_id}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'program_id'}
          values={programs}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.startOfBuilding}
          isLoading={isObjectUpdating}
          content={object.start_of_building ? String(object.start_of_building) : '0'}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'start_of_building'}
          values={startDate}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.typeOfWork}
          isLoading={isObjectUpdating}
          content={object.type_of_work ? String(object.type_of_work) : '0'}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'type_of_work'}
          values={typeOfWork}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.monitoringId}
          isLoading={isObjectUpdating}
          content={object.monitoring_project_id}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'monitoring_project_id'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.macroRegionValue}
          isLoading={isObjectUpdating}
          content={object.macro_region}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'macro_region'}
          values={macroRegions}
        />
      )}
      {editorMode && (
        <ObjectInfoBox
          editorMode={editorMode}
          title={SidebarObjectInfoHeading.realizationPeriod}
          content={object?.realization_period}
          type="realization_period"
          onEditField={onEditField}
          isLoading={isObjectUpdating}
        />
      )}
      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.volumeWorks}
        content={object?.needs}
        type="needs"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
      />

      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.name}
          isLoading={isObjectUpdating}
          content={object.name}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'name'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.owner}
          isLoading={isObjectUpdating}
          content={object.owner}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'owner'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.possibility}
          isLoading={isObjectUpdating}
          content={object.common_popularity}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'common_popularity'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.employeeVisits}
          isLoading={isObjectUpdating}
          content={object.population}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'population'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.necessaryInvest}
          isLoading={isObjectUpdating}
          content={object.money_needs}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'money_needs'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.distanceDistrictCenter}
          isLoading={isObjectUpdating}
          content={object.distance}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'distance'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.place}
          isLoading={isObjectUpdating}
          content={object.place}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'place'}
          inputComponent={'input'}
        />
      )}

      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.dangerZone}
        type="delete_object"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
        content={''}
        objectId={object.id}
        objectTitle={object.name}
        onCloseSidebar={onCloseClick}
      />
    </>
  );
};
