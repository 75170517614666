import React, { MouseEvent, useState } from 'react';
import { ObjectButtonsProps } from './object-buttons.types';
import styles from './object-buttons.module.scss';
import { Button, SpanTag } from '../common';
import {
  toggleCoordsEditor,
  useAppDispatch,
  useDeleteObjectSelector,
  useToggleFavoriteUserItemMutation,
} from '../../state';
import { useFavoriteObjectsSelector } from '../../state/selector';
import { useBreakpointsComparison } from '../../hooks';
import { shareObject } from '../../utils';
import { ScreenSize } from '../../constants';


export const ObjectButtons = ({
  editorMode,
  object,
  type = 'sidebar',
  setDeleteModal = () => {},
  flyToObject,
}: ObjectButtonsProps): JSX.Element => {
  const [toggleFavoriteUserItem, { isLoading: toggleFavoriteButtonLoading }] =
    useToggleFavoriteUserItemMutation();
  const favoriteItems = useFavoriteObjectsSelector();
  const dispatch = useAppDispatch();
  const [isShareToolTipActive, setShareToolTipActive] = useState<boolean>(false);
  const isDeleteObject = useDeleteObjectSelector();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
  const isMobile = useBreakpointsComparison(ScreenSize.MP);


  const shareButtonHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (window && object) {
      shareObject(object.id);
      setShareToolTipActive(true);
      setTimeout(() => setShareToolTipActive(false), 3000);
    }
  };

  const toggleFavorite = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (object) {
      toggleFavoriteUserItem({ id: object.id });
    }
  };

  const isShowOnMapButtonVisible = () => {
    if (!editorMode && type === 'sidebar') return true;
    if (editorMode && isTablet) return true;
    if (!editorMode && !isDeleteObject) return true;
    return false;
  };


  return (
    <>
      {isShowOnMapButtonVisible() && (
        <>
          <Button
            className={styles['object-info-buttons-group-button-display']}
            elementType={'button'}
            typeBtn={'secondary'}
            children={'Показать на карте'}
            size={type === 'sidebar' || isTablet ? 'm' : 'l'}
            showLeftIcon
            leftIcon={
              type === 'sidebar'
                ? <i className={'icon icon-map-pin icon-tiny'} />
                : <i className={'icon icon-map-pin icon-middle'} />
            }
            onClick={flyToObject}
            style={{ gap: 4 }}
          />
        </>
      )}
      {((editorMode && !isTablet && !isDeleteObject) || (editorMode && !isTablet && type === 'sidebar')) && (
        <Button
          size={type === 'sidebar' || isTablet ? 'm' : 'l'}
          className={styles['object-info-buttons-group-button-display']}
          elementType={'button'}
          typeBtn={'secondary'}
          children={'Задать положение'}
          onClick={() => dispatch(toggleCoordsEditor())}
          showLeftIcon
          leftIcon={
            type === 'sidebar'
              ? <i className={'icon icon-map-pin icon-tiny'} />
              : <i className={'icon icon-map-pin icon-middle'} />
          }
          style={{ gap: 4 }}
        />
      )}
      {isDeleteObject && type !== 'sidebar' ? (
          <Button
            className={styles['object-info-buttons-group-button-display']}
            elementType={'button'}
            typeBtn={'danger'}
            children={'Удалить объект'}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setDeleteModal(true);
            }}
            showLeftIcon
            leftIcon={ <i className={'icon icon-Trashcan icon-middle'} />}
          />
      ) : (
          <>
            <Button
              size={type === 'sidebar' || isTablet ? 'm' : 'l'}
              className={styles['object-info-buttons-group-button-favorite']}
              elementType={'button'}
              typeBtn={
                object && favoriteItems.includes(object.id)
                  ? 'primary-score'
                  : 'secondary'
              }
              children={
                type === 'sidebar' || (type === 'card' && isTablet)
                  ? (
                    object && favoriteItems.includes(object.id)
                      ? 'Из избранного'
                      : 'В избранное'
                  ) : ''
              }
              isActive={object && favoriteItems.includes(object.id)}
              onlyIcon={isMobile || !(type === 'card' && isTablet)}
              showLeftIcon
              disabled={toggleFavoriteButtonLoading}
              onClick={toggleFavorite}
              leftIcon={
                type === 'sidebar'
                  ? <i className={'icon icon-star icon-tiny'} />
                  : <i className={'icon icon-star icon-middle'} />
              }
            />
            {
              !isTabletLandscape && (
                <p className={styles['object-info-buttons-group-share-container']}>
                  <Button
                    size={type === 'sidebar' || isTablet ? 'm' : 'l'}
                    className={
                  type === 'sidebar'
                    ? styles['object-info-buttons-group-share-container-button']
                    : ''
                    }
                    elementType={'button'}
                    typeBtn={'secondary'}
                    showLeftIcon
                    onlyIcon
                    leftIcon={<i className={'icon icon-share icon-low'} />}
                    onClick={shareButtonHandler}
                  />
                  <SpanTag
                    className={`
            ${styles['object-info-buttons-group-share-container-tooltip']}
            ${
              isShareToolTipActive
                ? styles['object-info-buttons-group-share-container-tooltip-active']
                : ' '
            }
            `}
                  >
                Ссылка скопирована
                  </SpanTag>
                </p>
              )
            }
          </>
      )}
    </>
  );
};
