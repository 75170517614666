import { useAppSelector } from './selector';
import { ObjectInterface, ObjectType } from '../../types';
import { useEditorModeStateSelector, useFavoriteObjectsSelector } from './user-selectors';
import { commonFilterObject } from '../../utils/common-filter-object';
import { useSelectedObjectSelector } from '../../history-state/selectors';
import { useMemo } from 'react';
import { DEMO_OBJECTS_IDS } from '../../__demo';

export const useObjectByIdSelector = (itemId: number | null) => {
  const { items } = useAppSelector(({ objects }) => objects);

  return useMemo(() => {
    return items.find((el) => el.id === itemId);
  }, [itemId, items]);
};

export const useEnableObjectFiltersSelector = () => {
  return useAppSelector(({ objects }) => objects.filters);
};

export const useObjectsItemsSelector = () =>
  useAppSelector(({ objects }) => objects.items);

export const useOjectByIdSelector = (objectId: number | string | null | undefined) => {
  const items = useObjectsItemsSelector();

  if (!objectId) return null;

  const object = items.find((item: ObjectInterface) => item.id === Number(objectId));

  return object || null;
};

export const useObjectsTypesSelector = (): ObjectType[] =>
  useAppSelector(({ objects }) => objects.types);

export const useObjectsProgramsSelector = () =>
  useAppSelector(({ objects }) => objects.programs);

export const useObjectMonitoringProjectIdSelector = (objectId: string | undefined) => {
  const { items } = useAppSelector(({ objects }) => objects);

  if (!objectId) return null;

  const object = items.find((el) => {
    return el.id === Number(objectId);
  });

  return object ? Number(object.monitoring_project_id) : null;
};

export const useObjectAnalyticsByObjectIdSelector = (objectId: string | undefined) => {
  const { items } = useAppSelector(({ objects }) => objects);

  if (!objectId) return null;

  const object = items.find((el) => {
    return el.id === Number(objectId);
  });

  return object ? object.analytics : null;
};

export const useObjectByProjectIdSelector = (projectID: string | undefined) => {
  const { items } = useAppSelector(({ objects }) => objects);

  if (!projectID) return null;

  const object = items.find((el) => {
    return Number(el.monitoring_project_id) === Number(projectID);
  });

  return object ? object : null;
};

export const useStateSelectedObject = () =>
  useAppSelector(({ objects }) => objects.selectedItem);

export const useFilteredObjectsSelector = () => {
  const { items, filters } = useAppSelector(({ objects }) => objects);
  const favorites = useFavoriteObjectsSelector();
  const isEditMode = useEditorModeStateSelector();
  const objectID = useSelectedObjectSelector();

  // DEMO ///////////////////////////////////////////
  const itemsWithoutDemoObjects = items.filter(({ id }) => {
    if (DEMO_OBJECTS_IDS.includes(id)) return false;
    return true;
  });
  //////////////////////////////////////////////////////

  return commonFilterObject(
    itemsWithoutDemoObjects,
    filters.macro_region,
    filters.program,
    filters.status,
    filters.start_of_building,
    filters.type_of_work,
    favorites,
    filters.showOnlyFavorite,
    objectID ? objectID : null,
    isEditMode,
    // DEMO ///////////////////////////////////////////
    filters.demoObjectTypes,
    filters.demoFund,
  );
};

export const useObjectUpdateErrorSelector = () =>
  useAppSelector(({ objects }) => objects.errors.updateObject);

// DEMO ///////////////////////////////////////////

export const useGetDemoObjectsSelector = () => {
  const { items, filters } = useAppSelector(({ objects }) => objects);
  const favorites = useFavoriteObjectsSelector();
  const isEditMode = useEditorModeStateSelector();
  const objectID = useSelectedObjectSelector();

  const demoItems = items.filter(({ id }) => {
    return DEMO_OBJECTS_IDS.includes(id);
  });

  return commonFilterObject(
    demoItems,
    filters.macro_region,
    filters.program,
    filters.status,
    filters.start_of_building,
    filters.type_of_work,
    favorites,
    filters.showOnlyFavorite,
    objectID ? objectID : null,
    isEditMode,
    // DEMO ///////////////////////////////////////////
    filters.demoObjectTypes,
    filters.demoFund,
  );

  // useAppSelector(({ objects }) => {
  //   const { items } = objects;

  //   const filteredItems = items.filter(({ id }) => {
  //     return DEMO_OBJECTS_IDS.includes(id);
  //   });

  //   return commonFilterObject(
  //     filteredItems,
  //     filters.macro_region,
  //     filters.program,
  //     filters.status,
  //     filters.start_of_building,
  //     filters.type_of_work,
  //     favorites,
  //     filters.showOnlyFavorite,
  //     objectID ? objectID : null,
  //     isEditMode,
  //     // DEMO ///////////////////////////////////////////
  //     filters.demoObjectTypes,
  //     filters.demoFund,
  //   );
  // });
};
