import { SearchDropdownProps } from './search-dropdown.types';
import { useBreakpointsComparison } from '../../../hooks';
import { SearchDropdownDesktop } from './search-dropdown.desktop';
import { ScreenSize } from '../../../constants';


export const SearchDropdown = (props: SearchDropdownProps) => {
  const isTablet = useBreakpointsComparison(ScreenSize.TP);

  return !isTablet ? (
    <SearchDropdownDesktop 
      {...props}
    />
  ) : <></>;
};
