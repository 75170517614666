import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './sidebar.module.scss';
import styles from './sidebar.module.scss';
import { ISidebarProps } from './sidebar.types';
import { StatusSwitcher } from '../status-switcher';
import Spinner from '../common/spinner/spinner';
import { useGetProjectSelector, useIsDemoUser } from '../../state';
import { SidebarMonitoring } from '../';
import bg from '../../images/object-bg-image.png';
import demoBg from '../../images/demo-object-bg-image.png';
import {
  getGroupData,
  getImageUrl,
  getSortedProjectData,
  sortMonitoringContentItems,
} from '../../utils';
import { useIsAnalyticsViewOpenSelector } from '../../state';
import { ProjectData } from '../../types/monitoring';
import { ObjectInterface } from '../../types';
import { SidebarHeader } from './sidebar-header';
import { SidebarImage } from './sidebar-image';
import { SidebarSwitchTabButtons } from './sidebar-switch-tab-buttons';
import { useBreakpointsComparison } from '../../hooks';
import { DemoSidebarObjectInfo, SidebarObjectInfo } from '../sidebar-object-info';
import { ScreenSize } from '../../constants';

export const Sidebar = ({
  object,
  onCloseClick,
  onImageClick,
  editorMode,
  onEditField,
  isObjectUpdating = false,
  onChangeObject,
  flyToObject,
  isMonitoringActive = false,
  group,
  setGroup,
  goBackHandler = () => {},
  handleOpenAnalytics,
}: ISidebarProps): JSX.Element => {
  const [tabId, setTabId] = useState<number>(0);
  const isDemoUser = useIsDemoUser();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const project = useGetProjectSelector();
  const { groupID } = useParams();
  const objectIdRef = useRef<number | null | undefined>(null);
  const isAnalyticsViewOpen = useIsAnalyticsViewOpenSelector();

  const handleChangeStatus = (value: boolean, id: number) => {
    return onChangeObject({
      id: id,
      type: 'real_status',
      value: value ? '2' : '1',
    });
  };

  const objectInfoSelectorHandler = useCallback(() => {
    setTabId(0);
  }, [tabId]);

  const monitoringSelectorHandler = useCallback(() => {
    setTabId(1);
  }, [tabId]);

  const selectSidebarImage = (
    groupID: string | undefined,
    project: ProjectData | null,
    object: ObjectInterface | undefined,
  ): string => {
    if (groupID && project) {
      const group = getGroupData(project, Number(groupID));
      if (group && group.image) {
        return group.image.src;
      }
    }
    if (object && object.thumbnail) {
      return object.thumbnail;
    }
    if (object && object.media && object.media.length && !object.thumbnail) {
      return getImageUrl(object.media[0].path);
    }
    return !isDemoUser ? bg : demoBg;
  };
  const sidebarImage = selectSidebarImage(groupID, project, object);

  useEffect(() => {
    if (groupID && project) {
      !tabId && setTabId(1);
      const group = getGroupData(project, Number(groupID));
      group && setGroup(group);
    } else {
      group && setGroup(null);
    }
  }, [groupID, project, object]);

  useEffect(() => {
    if (objectIdRef.current && objectIdRef.current !== object?.id) {
      tabId && setTabId(0);
    }
    objectIdRef.current = object?.id;
  }, [object]);

  useEffect(() => {
    return () => {
      group && setGroup(null);

      objectIdRef.current = null;
    };
  }, []);

  return (
    <div className={styles.sidebar}>
      {object ? (
        <>
          {!isTablet && (
            <div className={styles['fill-width-bg-element']}>
              <SidebarHeader
                title={object?.name}
                subtitle={group ? group.name : object.place}
                onCloseClick={onCloseClick}
              />
            </div>
          )}
          <SidebarImage
            image={sidebarImage}
            alt={object.name}
            goBackHandler={!!group && !isTablet ? goBackHandler : null}
          />
          <div className={styles['buttons-container']}>
            <SidebarSwitchTabButtons
              isMonitoringActive={!!tabId}
              isMonitoringDisabled={!isMonitoringActive}
              setInfo={objectInfoSelectorHandler}
              setMonitoring={monitoringSelectorHandler}
            />
          </div>
          <StatusSwitcher
            className={styles['sidebar-status-switcher']}
            editorMode={editorMode}
            loading={isObjectUpdating}
            checked={object && Number(object.real_status) === 2}
            onChangeStatus={(value) => handleChangeStatus(value, object.id)}
          />
          {tabId === 0 && (
            <SidebarObjectInfo
              object={object}
              editorMode={editorMode}
              onCloseClick={onCloseClick}
              onImageClick={onImageClick}
              onEditField={onEditField}
              isObjectUpdating={isObjectUpdating}
              onChangeObject={onChangeObject}
              isMonitoringActive={isMonitoringActive}
              handleOpenAnalytics={handleOpenAnalytics}
              isAnalyticsViewOpen={isAnalyticsViewOpen}
              flyToObject={flyToObject}
            />
          )}
          {tabId === 1 && project && (
            <SidebarMonitoring
              isGroup={!!groupID}
              items={
                !groupID
                  ? getSortedProjectData(project)
                  : sortMonitoringContentItems(group ? group.lines : [])
              }
            />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};
