import { useDebouncedEffect } from './use-debounced-effect';


export const useDeviceWidthTracker = (callback: () => void) => {
  useDebouncedEffect(() => {
    const updateSize = (): void => {
      callback();
    };
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, [], 200);
};
