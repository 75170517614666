import styles from './card-view.module.scss';
import {
  setDeleteObject,
  setGalleryObjectId,
  useAppDispatch,
  useDeleteObjectSelector,
  useFilteredObjectsSelector,
} from '../../../state';
import { ObjectCard } from '../../object-card';
import {
  useEditorModeStateSelector,
  useGetDemoObjectsSelector,
  useIsDemoUser,
} from '../../../state/selector';
import { useSelectObject } from '../../../hooks/depended/useSelectObject';
import { ObjectInterface } from '../../../types';
import { getObjectPriorityList } from './utils';
import { objectPriorityList } from '../../../constants';
import { useSidebarState, useViewModeSelector } from '../../../history-state/selectors';
import { BottomSheet } from '../../common/bottom-sheet';
import { useFlyToObjectOnMap } from '../../../hooks';

export const CardViewMobile = ({ isLoading = false }: { isLoading: boolean }) => {
  const isDemoUser = useIsDemoUser();
  const dispatch = useAppDispatch();
  const demoObjects = useGetDemoObjectsSelector();
  const IMobjects = useFilteredObjectsSelector();
  const isEditor = useEditorModeStateSelector();
  const [isSidebarOpen, setSidebarOpen] = useSidebarState();
  const [selectedObjectId, setSelectedObjectId] = useSelectObject();
  const list = isDemoUser
    ? demoObjects
    : getObjectPriorityList(objectPriorityList, IMobjects);
  const [viewMode, setViewMode] = useViewModeSelector();
  const isDeleteObject = useDeleteObjectSelector();
  const flyTo = useFlyToObjectOnMap();

  const selectObjectHandler = (object: ObjectInterface) => {
    if (isDeleteObject) return;
    const { coords } = object;
    flyTo([coords.coordinates[0], coords.coordinates[1]]);
    setViewMode('map');
  };

  return (viewMode === 'list' || isDeleteObject) && !isLoading ? (
    <BottomSheet
      open={true}
      onDismiss={() => {
        setViewMode('map');
        isDeleteObject && dispatch(setDeleteObject(false));
      }}
      isActive={false}
      initialHeight={'max'}
      closeButton={false}
    >
      <div className={styles.container}>
        {list.length
          ? list.map((item: ObjectInterface) => {
              return (
                <ObjectCard
                  key={item.id}
                  object={item}
                  editorMode={isEditor}
                  role="redactor"
                  onImageClick={(image) => dispatch(setGalleryObjectId(image))}
                  selected={selectedObjectId === item.id}
                  onClick={() => {
                    selectObjectHandler(item);
                    !isDeleteObject && setSelectedObjectId(item.id);
                  }}
                  onCloseSidebar={() => setSelectedObjectId(selectedObjectId)}
                />
              );
            })
          : null}
      </div>
    </BottomSheet>
  ) : null;
};
