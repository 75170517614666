import styles from './filter-group.module.scss';
import { FilterGoupProps } from './filter-group.types';


export const FilterGroup = ({ 
  rowLength = 1,
  direction = 'row',
  children,
  style, 
  className,
  ...props
}: FilterGoupProps): JSX.Element => {
  return (
    <ul 
      className={
        `${styles.container} 
        ${styles[`container-row-length-${rowLength}`]} 
        ${className || ''}`
      }
      style={{ flexDirection: direction, ...style }}
      {...props}
    >
      {children}
    </ul>
  );
};
