
import { MonitoringViewItem } from '../../monitoring-view-item';
import styles from './analytics-view.module.scss';
import type { AnalyticsViewProps } from './analytics-view.types';
import { H1Tag } from '../../common';
import { ViewWrapper } from '../view-wrapper';
import { AnalyticsViewHeader } from './analytics-view-header';
import { AnalyticsViewTable } from './analytics-view-table';


export const AnalyticsView = ({ objectId, editable }: AnalyticsViewProps) => {
  return (
    <ViewWrapper>
      <div className={styles.container}>
        <AnalyticsViewHeader />
        <div className={styles.table}>
          <AnalyticsViewTable 
            objectId={objectId} 
            editable={editable}
          />
        </div>
      </div>
    </ViewWrapper>
  );
};

