import { FlyToGroupProps } from '../types/utilsProps';

export { levelList } from './level-list';
export { filterParamsUrl } from './filter-params-url';
export { getDeviceWidthObj } from './get-device-width-obj';
export { getCssVariableName } from './get-css-variable-name';
export { formatObjectToQueryString } from './format-object-to-query-string';
export { loadIcons } from './load-icons';
export { history } from './history';

export { filteredObjectsCounter } from './filtered-objects-counter';
export { isElementVisible } from './element-visible';
export { getMacroRegionColor } from './get-macro-region-color';
export { profileValidationSchema } from './validation';
export {
  convertDateToYYYYMMDD,
  convertDateToDDMMYYYY,
  convertDateToddMM,
} from './convert-date';
export { deleteEmptyKeysFromObject } from './delete-empty-keys';
export { capitalize } from './capitalize';
export { getImageUrl } from './get-image-url';
export * from './get-project-data';
export { formatDate } from './format-date';
export * from './share-object';

export * from './simplify-arr';
export * from './get-mapped-gallery-object';

const getBoundsFromGroup = (group: Record<string, any>[]) => {
  if (group.length === 0) {
    return [];
  }

  let maxLongitude = parseFloat(group[0].gps.long);
  let minLongitude = parseFloat(group[0].gps.long);
  let maxLatitude = parseFloat(group[0].gps.lat);
  let minLatitude = parseFloat(group[0].gps.lat);

  group.forEach((item) => {
    if (parseFloat(item.gps.lat) < minLatitude) {
      minLatitude = parseFloat(item.gps.lat);
    }
    if (parseFloat(item.gps.long) < minLongitude) {
      minLongitude = parseFloat(item.gps.long);
    }
    if (parseFloat(item.gps.lat) > maxLatitude) {
      maxLatitude = parseFloat(item.gps.lat);
    }
    if (item.gps.long > maxLongitude) {
      maxLongitude = parseFloat(item.gps.long);
    }
  });

  const data: [[number, number], [number, number]] = [
    [minLatitude, minLongitude],
    [maxLatitude, maxLongitude],
  ];

  return data;
};
const prepareMapBoundsAndZoom = ({ mainGroup, groupForCenter, map }: any) => {
  if (mainGroup === undefined || mainGroup.length === 0) {
    return;
  }
  if (!groupForCenter) {
    groupForCenter = mainGroup;
  }

  const boundsForZoom = getBoundsFromGroup(mainGroup);
  const boundsForCenter = getBoundsFromGroup(groupForCenter);

  const zoom = map.getBoundsZoom(boundsForZoom) as number;

  const center: [number, number] = [
    (boundsForCenter[0][0] + boundsForCenter[1][0]) / 2,
    (boundsForCenter[0][1] + boundsForCenter[1][1]) / 2,
  ];

  return { center, zoom: isFinite(zoom) ? zoom : 18 };
};

export const flyToGroup = ({
  mainGroup,
  groupForCenter,
  map,
  offset = -3,
}: FlyToGroupProps) => {
  const bounds = prepareMapBoundsAndZoom({ mainGroup, groupForCenter, map });
  if (bounds) {
    const { zoom, center } = bounds;
    map.flyTo(center, zoom + offset, { duration: 0.5 });
  }
};

export const setViewToGroup = ({ mainGroup, groupForCenter, map }: FlyToGroupProps) => {
  const bounds = prepareMapBoundsAndZoom({ mainGroup, groupForCenter, map });
  if (bounds) {
    const center = bounds.center;
    const zoom = bounds.zoom <= 18 ? bounds.zoom : 18;
    map.flyTo(center, zoom);
  }
};
