import styles from './zoom-buttons.module.scss';
import { ButtonGroup, Button } from '../common';
import type { ZoomButtonsProps } from './zoom-buttons.types';
import { useBreakpointsComparison } from '../../hooks';
import { memo } from 'react';
import { ScreenSize } from '../../constants';


export const ZoomButtons = memo(({
  zoomInHandler,
  zoomOutHandler,
  zoomInDisabled = false,
  zoomOutDisabled = false,
  showToTheCenterButton = false,
  toTheCenterOfTheMapHandler = () => {},
  ...props
}: ZoomButtonsProps) => {
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  return (
    <div className={styles.wrapper} {...props}>
      <ButtonGroup 
        className={styles.container} 
        direction={'column'}>
        <Button
          className={`
            ${styles['wrapper-button'] }
            ${styles['wrapper-container-zoom-button-in']}
          `}
          onClick={zoomInHandler as () => void}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon
          leftIcon={<i className={`icon icon-plus icon-${isTabletLandscape ? 'tiny' : 'middle'}`} />}
          disabled={zoomInDisabled}
        />
        <div className={styles['h-line']} />
        <Button
          className={`
            ${styles['wrapper-button'] }
            ${styles['wrapper-container-zoom-button-out']}
          `}
          onClick={zoomOutHandler as () => void}
          elementType={'button'}
          typeBtn={'uncolored'}
          showLeftIcon
          leftIcon={<i className={`icon icon-minus icon-${isTabletLandscape ? 'tiny' : 'middle'}`} />}
          disabled={zoomOutDisabled}
        />
      </ButtonGroup>
      {
        showToTheCenterButton ? (
          <Button
            className={`
              ${styles['wrapper-button'] }
              ${styles['wrapper-go-to-the-conter-button'] }
            `}
            onClick={toTheCenterOfTheMapHandler}
            typeBtn={'elevation'}
            showLeftIcon
            leftIcon={<i className={`icon icon-aim icon-${isTabletLandscape ? 'tiny' : 'middle'}`} />}

          />
        ) : null
      }
    </div>
  );
});
