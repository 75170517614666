import { memo } from 'react';
import { Button, FooterContainer } from '../common';
import { EditorMenu } from '../editor-menu';
import { ClusterSwitcher } from '../cluster-switcher';
import { 
  useAppDispatch, 
  setClusterMarkerView, 
  useIsClusterMarkerViewSelector, 
  useIsUserAdmit,
} from '../../state';
import { useSidebarState, useViewModeSelector } from '../../history-state/selectors';
import styles from './footer.module.scss';
import { useBreakpointsComparison } from '../../hooks';
import { useSelectObject } from '../../hooks/depended/useSelectObject';
import { ScreenSize } from '../../constants';


export const Footer = memo(function FooterMemo() {
  const dispatch = useAppDispatch();
  const isClusterMarkerViewSelected = useIsClusterMarkerViewSelector();
  const isEditorMode = useIsUserAdmit();
  const [, setSelectedObjectId] = useSelectObject();
  const [, setSidebarOpen] = useSidebarState();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
  const [, setViewMode] = useViewModeSelector();

  return (
    <FooterContainer>
      <div className={styles.wrapper}>
        {
          isTablet && (
            <>
              <Button
                size={'s'}
                className={styles['wrapper-filters']}
                style={{ padding: '10px 12px' }}
                onClick={() => {
                  setSelectedObjectId(null);
                  setSidebarOpen(true);
                }}
                elementType={'button'}
                typeBtn={'elevation'}
              >
                Фильтры
              </Button>
              <Button
                className={styles['wrapper-view-swither']}
                size={'s'}
                onlyIcon
                showLeftIcon
                leftIcon={<i className={'icon icon-list icon-tiny'} />}
                style={{ width: 'fit-content' }}
                onClick={() => {
                  setSelectedObjectId(null);
                  setViewMode('list');
                }}
                elementType={'button'}
                typeBtn={'elevation'}
              />
            </>
          )
        }
        {
          isEditorMode && (
            <EditorMenu
              className={styles['wrapper-editor']}
            />
          )
        }
        {
          !isTabletLandscape && (
            <ClusterSwitcher
              className={styles['wrapper-cluster-swither']}
              checked={!isClusterMarkerViewSelected}
              onToggle={() => dispatch(setClusterMarkerView(!isClusterMarkerViewSelected))}
            />
          )
        }
      </div>
    </FooterContainer>
  );
});
