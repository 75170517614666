import { TileLayer } from 'react-leaflet';
import { useRef } from 'react';
import L, { TileLayer as TileLayerType } from 'leaflet';
import { MapTileLayerProps } from './map-view.types';


const TileLayerElement = TileLayer as any;

export const MapTileLayer = ({tileUrl}: MapTileLayerProps) => {
  const mapLayerRef = useRef<TileLayerType | null>(null);

  return (
    <>
      <TileLayerElement
        ref={mapLayerRef}
        bounds={new L.LatLngBounds(new L.LatLng(-89.98, -180), new L.LatLng(89.98, 180))}
        url={String(tileUrl)}
      />
    </>
  );
};
