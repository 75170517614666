import styles from './monitoring-view.module.scss';
import { H4Tag } from '../common';
import type { SidebarMonitoringProps } from './monitoring-view.types';
import { MonitoringViewItem } from '../monitoring-view-item';
import { getLastDateForGroup } from '../../utils';


export const SidebarMonitoring = ({
  isGroup,
  items,
}: SidebarMonitoringProps) => {

  return (
    <div className={styles.container}>
      <H4Tag weight={3}>
        {
        !isGroup ? (
          'Локации объекта'
        ) : 'Зоны'
        }
      </H4Tag>
      <ul className={styles['items-list']}>
        {
        items.length ?
          items.map((item) => {
            return (
              <MonitoringViewItem
                key={item.id}
                object={item}
                isGroup={'lines' in item}
                lastDate={'lines' in item ? getLastDateForGroup(item.lines) : null}
                isActive={false}
              />
            );
          },
          ) : null
        }
      </ul>
    </div>
  );
};
