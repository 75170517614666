import { Button } from '../button';
import { H3Tag, PTag } from '../';
import styles from './button-sheet-header.module.scss';
import type { BottomSheetHeaderProps } from './bottom-sheet.types';


export const ButtonSheetHeader = ({
  title,
  subtitle,
  backButton,
  closeButton = true,
  onBack = () => {},
  onClose = () => {},
  fullscreen = false,
  className = '',
}: BottomSheetHeaderProps) => {

  return ( 
    <div className={`
            ${styles.container}
            ${backButton ? styles['container-back-button-includes'] : ''}
            ${fullscreen ? styles['container-fullscreen'] : ''}
            ${className}
        `}>
      {backButton && (
        <Button
          className={styles['container-back-button']}
          typeBtn={'uncolored'}
          elementType={'button'}
          size={'s'}
          showLeftIcon
          onlyIcon
          leftIcon={<i className="icon icon-arrow-1-left icon-tiny" />}
          onClick={onBack}
        />
      )}
      {title 
        ? (

        <>
          <H3Tag 
            weight={3} 
            className={styles['container-title']}
          >{title}
          </H3Tag>
          <PTag 
            type={'subheader'} 
            weight={1} 
            className={styles['container-subtitle']}
          >
            {subtitle}
          </PTag>
        </>

        )
        : <></>
      }
      {closeButton && (
        <Button
          className={styles['container-close-button']}
          typeBtn={'uncolored'}
          elementType={'button'}
          size={'s'}
          showLeftIcon
          onlyIcon
          leftIcon={<i className="icon icon-close-3 icon-tiny" />}
          onClick={onClose}
        />
      )}
    </div>
  );
};
