import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import styles from './search.module.scss';
import { SearchProps } from './search.types';
import { SearchDropdown, SearchInput } from '../common';
import { useBreakpointsComparison, useComponentVisible } from '../../hooks';
import { buildSearchResults } from './utils';
import { useSelectObject } from '../../hooks/depended/useSelectObject';
import { useEditorModeStateSelector } from '../../state';
import { useFlyToObjectOnMap } from '../../hooks';
import { SearchDropdownMobile } from '../common/search-dropdown/search-dropdown.mobile';
import { ScreenSize } from '../../constants';
import { ObjectInterface } from '../../types';


export const Search = ({ className, children, items = [], ...props }: SearchProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isEditor = useEditorModeStateSelector();
  const [searchRef, showSuggestions, setShowSuggestions] = useComponentVisible(() => false);
  const [inputQueryValue, setInputValue] = useState<string>('');
  const { firstSuggestionId, suggestions, currentStringResult } = buildSearchResults(items, inputQueryValue, isEditor);
  const [, setSelectedObjectId] = useSelectObject();
  const [activeSuggestion, setSuggestion] = useState(0); // активный индекс в массиве
  const [, setActiveSuggestionId] = useState<number | null>( // id объекта
    firstSuggestionId,
  );
  const [isFocused, setFocused] = useState<boolean>(false);
  const flyTo = useFlyToObjectOnMap();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);


  const onInputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (
      inputQueryValue[inputQueryValue.length - 1] === ' '
      && (e.nativeEvent as any).data === ' '
    ) {
      return;
    }
    if (
      !inputQueryValue.length && (e.nativeEvent as any).data === ' '
    ) {
      return;
    }
    setInputValue(val);
  };

  const resetInputValue = () => {
    setInputValue('');
  };

  const showDropdownOnInputFocus = () => {
    setFocused(true);
    setShowSuggestions(true);
  };

  const hideDropdownOnLooseInputFocus = () => {
    setFocused(false);
    setShowSuggestions(false);
  };

  const setPlaceholder = (arrow?: 'up' | 'down' | 'initial' | '', value?: string) => {
    if (arrow === 'up') {
      return inputRef?.current?.setAttribute('placeholder', suggestions[activeSuggestion - 1]?.name || '');
    }
    if (arrow === 'down') {
      return inputRef?.current?.setAttribute('placeholder', suggestions[activeSuggestion + 1]?.name || '');
    }
    if (arrow === 'initial') {
      return inputRef?.current?.setAttribute('placeholder', 'Объект, инициатор, адрес');
    }
    if (value) {
      return inputRef?.current?.setAttribute('placeholder', value);
    }

  };

  const handleMouseEnterOnSearchSuggestion = (idx: number) => {
    setSuggestion(idx);
    setPlaceholder('', suggestions[idx]?.name);
  };


  const resetOnBlur = () => {
    setPlaceholder('initial');
  };


  useEffect(() => {
    inputQueryValue && setShowSuggestions(true);
    setSuggestion(0);
    inputQueryValue && setPlaceholder('', suggestions[activeSuggestion]?.name);
    inputQueryValue === '' && setPlaceholder('initial');
  }, [inputQueryValue]);

  useEffect(() => {
    isFocused || setPlaceholder('initial');
    isFocused || setInputValue('');
  }, [isFocused]);


  const handleKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {

    const { code } = e;
    if (code === 'Enter') {
      flyTo(suggestions[activeSuggestion].coords.coordinates as [number, number]);
      setSelectedObjectId(suggestions[activeSuggestion].id);
      hideDropdownOnLooseInputFocus();
      resetInputValue();
      setFocused(false);
      inputRef?.current?.blur();
    }

    if (code === 'Escape') {
      resetInputValue();
      setPlaceholder('initial');
    }

    if (code === 'Tab') {
      setInputValue(suggestions[activeSuggestion]?.name);
      e.preventDefault();
    }

    if (code === 'ArrowUp') {
      isFocused && activeSuggestion > 0 && setSuggestion(activeSuggestion - 1);
      activeSuggestion > 0 && setActiveSuggestionId(suggestions[activeSuggestion].id);
      setPlaceholder('up');
    }

    if (code === 'ArrowDown') {
      isFocused && (activeSuggestion < suggestions.length - 1) && activeSuggestion < 5
        ? setSuggestion(activeSuggestion + 1)
        : setSuggestion(0);
      activeSuggestion < suggestions.length &&
        setActiveSuggestionId(suggestions[activeSuggestion].id);
      setShowSuggestions(true);
      setPlaceholder('down');
    }
  };

  return (
    <div className={styles.container} ref={searchRef}>
      {
        showSuggestions && isTablet ? <></> : (
          <SearchInput
            onBlur={resetOnBlur}
            inputRef={inputRef}
            onKeyDown={handleKeyboardEvent}
            onFocus={showDropdownOnInputFocus}
            resetInputValue={resetInputValue}
            placeholder={'Объект, инициатор , адрес'}
            onChange={onInputChangeHandler}
            value={inputQueryValue}
            suggestedName={inputQueryValue === '' ? inputRef.current?.placeholder : currentStringResult(suggestions[activeSuggestion], inputQueryValue)}
          />
        )
      }

      {/* Desktop version */}
      {showSuggestions && !isTablet && (
        <div className={styles['dropdown-container']}>
          {Array.isArray(items) && (
            <SearchDropdown
              inputQueryValue={inputQueryValue}
              handleMouseEnterOnSearchSuggestion={handleMouseEnterOnSearchSuggestion}
              activeSuggestion={activeSuggestion}
              hideDropdown={hideDropdownOnLooseInputFocus}
              items={suggestions}
            />
          )}
        </div>
      )}
      {/* Mobile version */}
      {showSuggestions && isTablet && (
        <SearchDropdownMobile 
          inputQueryValue={inputQueryValue}
          handleMouseEnterOnSearchSuggestion={handleMouseEnterOnSearchSuggestion}
          activeSuggestion={activeSuggestion}
          hideDropdown={hideDropdownOnLooseInputFocus}
          items={suggestions}
          onBlur={resetOnBlur}
          inputRef={searchRef}
          onKeyDown={handleKeyboardEvent}
          resetInputValue={resetInputValue}
          placeholder={'Объект, инициатор , адрес'}
          onChange={onInputChangeHandler}
          value={inputQueryValue}
        />
      )}
    </div>
  );
};
