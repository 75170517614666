import type { FC } from 'react';
import type { IThumbnailSlide } from '../../swiper/gallery.types'; 
import styles from './thumbnail.module.scss';


export const Thumbnail: FC<IThumbnailSlide> = ({ thumbnail, isActive }) => {
  return (
    <div
      className={`${styles.thumbnail} ${
        isActive ? styles.thumbnail_active : styles.thumbnail_inactive
      }`}
    >
      <img
        src={thumbnail}
        alt={'thumbnail'}
        className={styles['thumbnail-image']}
      />
    </div>
  );
};
