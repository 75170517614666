import L from 'leaflet';
import { getMacroType, average, getHeatType } from './';
import type { MarkerClusterGroup as  MarkerClusterGroupType } from 'leaflet';


export const createClusterCustomIcon = (cluster: MarkerClusterGroupType) => {
  const rateAVG: number = average(
    cluster.getAllChildMarkers().map((marker) => (marker.options as Record<string, unknown>).rate),
  );

  const heatType = getHeatType(rateAVG);

  const region = average(
    cluster.getAllChildMarkers().map((marker) => (marker.options as Record<string, unknown>).macro_region),
  );

  const color = getMacroType(Math.round(Number(region)));

  return L.divIcon({
    html: `<div>${cluster.getChildCount()}</div>`,
    className: `marker-cluster-custom macro-${color}`,
    iconSize: L.point(40, 40, true),
  });
};

export const createClusterCustomMacroIcon = (color: number) => (cluster: MarkerClusterGroupType) => {
  const macroView = getMacroType(Number(color));

  return L.divIcon({
    html: `<div>${cluster.getChildCount()}</div>`,
    className: `marker-cluster-custom macro-${macroView}`,
    iconSize: L.point(40, 40, true),
  });
};