export const batchUpdateHistory = (
  array: any,
  searchParams: URLSearchParams,
  setSearchParams: (nextInit: URLSearchParams, navigateOptions?: {
    replace?: boolean | undefined;
    state?: any;
  } | undefined) => void,
): null => {
  Array.isArray(array) ? array.forEach(([name, value]) => {
    if (Array.isArray(value)) {
      if (value && value.length) {
        searchParams.set(name, value.toString());
      } else {
        searchParams.delete(name);
      }
    } else {
      if (value && value.length) {
        searchParams.set(name, value.toString());
      } else {
        searchParams.delete(name);
      }
    }
  }) : null;
  setSearchParams(searchParams);
  return null;
};
export default batchUpdateHistory;
