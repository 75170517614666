import { FilterBottomPanelProps } from './filter-bottom-panel.types';
import styles from './filter-bottom-panel.module.scss';
import { Button } from '../../common';


export const FilterBottomPanel = ({ children, editorMode, onReset, loading, isResetButtonActive = false } : FilterBottomPanelProps) => {
  return  <div className={styles['filter-bottom-panel']}>
    <Button 
      onClick={onReset} 
      elementType={'button'} 
      size="m" 
      typeBtn={'secondary'} 
      text={'Очистить все'} 
      justifyContent={'center'}
      disabled={!isResetButtonActive}
    />
  </div>;
};
