import { BrowserRouter as Router } from 'react-router-dom';
import { Router as Routes } from '../../router';
import { useDebouncedEffect } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../state';
import { identifyTheDevice } from '../../state';
import { Helmet } from 'react-helmet';
import { IS_PUBLIC } from '../../constants';
import favicon192 from '../../icons/favicon/android-chrome-192x192.png';
import favicon180 from '../../icons/favicon/apple-touch-icon.png';
import favicon32 from '../../icons/favicon/favicon-32x32.png';
import favicon16 from '../../icons/favicon/favicon-16x16.png';

export const App = () => {
  const dispatch = useAppDispatch();
  const { accessToken } = useAppSelector(({ user }) => user.token);

  useDebouncedEffect(() => {
    const updateSize = (): void => {
      dispatch(identifyTheDevice(document.body.clientWidth));
    };
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      {IS_PUBLIC && (
        <Helmet>
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link
            rel="apple-touch-icon"
            type="image/png"
            sizes="180x180"
            href={favicon180}
          />
          <link rel="android-chrome" type="image/png" sizes="192x192" href={favicon192} />
        </Helmet>
      )}
      <Routes isAuth={!!accessToken} />
    </>
  );
};
