import { MouseEvent, useMemo } from 'react';
import type {
  VideoPlayerProps,
  VideoPlayerRef,
  IReactPlayerOnProgress,
} from './video-player.types';
import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
} from 'react';

import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

import { useThrottle, useOnScreen, useBreakpointsComparison } from '../../hooks';
import { PlayerHUD } from './HUD/player-hud';
import { VideoWrapper } from './video-wrapper';
import styles from './video-player.module.scss';
import classnames from 'classnames';
import { ScreenSize } from '../../constants';
import { PausedModal } from './HUD/paused-modal';


export const VideoPlayer = React.memo(
  React.forwardRef<VideoPlayerRef, VideoPlayerProps>(
    (
      {
        showVolumeChanger = false,
        alwaysShowHUD = true,
        src,
        embedUrl,
        pictureInPicture = false,
        playing = false,
        light = false,
        loop = true,
        playbackRate = 1,
        muted = true,
        containerClassName = '',
        customCover,
        customHUD,
        customFullScreen = false,
        fullscreen = false,
        videoWidth = '100%',
        videoHeight = 'auto',
        additionalLeftButtons,
        startFrom = 0,
        onFullscreen: onFullScreenProp = () => {},
        onReady: onReadyProp = () => {},
        onNext = () => {},
        onPrevious = () => {},
        onProgress = () => {},
        onPlaying = () => {},
      },
      ref,
    ) => {
      const playerRef = useRef<ReactPlayer>(null);
      const timer = useRef<NodeJS.Timeout | null>(null);
      const HUDtimer = useRef<NodeJS.Timeout | null>(null);
      const wrapper = useRef<HTMLDivElement>(null);
      const pauseHiddenRef = useRef<NodeJS.Timeout>();
      const cursoreInRef = useRef<boolean>(false);
      const hideCursoreRef = useRef<NodeJS.Timeout>();

      const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
      const isTablet = useBreakpointsComparison(ScreenSize.TP);
      const isMobile = useBreakpointsComparison(ScreenSize.MP);
      const inScreen = useOnScreen(wrapper);

      const [playingState, setPlayingState] = useState<boolean>(false);
      const [seeking, setSeeking] = useState<boolean>(false);
      const [volume, setVolume] = useState<number>(0.8);
      const [mutedState, setMutedState] = useState<boolean>(muted);
      const [loaded, setLoaded] = useState<number>(0);
      const [played, setPlayed] = useState<number>(0);
      const [duration, setDuration] = useState<number>(0);
      const [isFullscreen, setIsFullScreen] = useState<boolean>(false);
      const [showHUD, setShowHUD] = useState<boolean>(false);
      const [pausedHiddenHUD, setPausedHiddenHUD] = useState<boolean>(false);
      const [isReady, setIsReady] = useState<boolean>(false);
      const [viewedSent, setViewedSent] = useState<boolean>(false);
      const [cursoreHidden, setCursoreHidden] = useState<boolean>(false);


      useEffect(() => setMutedState(muted), [muted]);
      useEffect(() => setPlayingState(playing), [playing]);

      const errorHandler = useCallback((error: Error) => {
        /**
         * "DOMException: play() failed because the user didn't interact with the document first."
         * Не включает автовоспроизведение, если пользователь не выполнял никаких действий на странице
         */
        if (error instanceof DOMException && error.name === 'NotAllowedError') {
          setPlayingState(false);
        }
        console.error(error);
      }, []);

      // Временное отображение худа
      const toggleHUD = useCallback(() => {
        // сбрасываем таймер
        if (HUDtimer.current) {
          clearTimeout(HUDtimer.current);
          HUDtimer.current = null;
        }
        setShowHUD(true);

        // отображаем худ и ставим наймер на 2 секунды
        if (true) {
          setShowHUD(true);
          HUDtimer.current = setTimeout(() => {
            setShowHUD(false);
            HUDtimer.current = null;
          }, 2000);
        } else {
          // худ отображается без ограничения по времени
          setShowHUD(true);
        }
      }, [playingState]);
      // Прогресс просмотра и загрузки
      const handleProgress = useCallback(
        (val: IReactPlayerOnProgress) => {
          if (val.played > 0.96 && !viewedSent) {
            setViewedSent(true);
          }
          setLoaded(val.loaded);
          setPlayed(val.played);
          onProgress(val);
        },
        [viewedSent],
      );
      // Установка продолжительности видео
      const handleDuration = useCallback((duration: number) => {
        setDuration(duration);
      }, []);

      // Обработка нажатия на кнопку play
      const handlePlay = useCallback(
        (e: MouseEvent<Element> | null = null) => {
          if (e !== null) {
            e.preventDefault();
            e.stopPropagation();
          }
          setPlayingState(true);
        },
        [duration],
      );
      // Окончание видео
      const handelEnded = useCallback(() => {
        setPlayingState(loop);
      }, []);
      // Обработка кнопки плей в hud
      const handleHUDPlay = useCallback(
        (e: MouseEvent<Element> | null = null, val?: boolean) => {
          if (e !== null) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (val !== undefined) {
            setPlayingState(val);
          } else {
            setPlayingState((prev) => !prev);
          }
        },
        [],
      );
      // Перемотка
      const onPlayedChange = useCallback((value: number) => {
        playerRef.current?.seekTo(value);
        setPlayed(value);
      }, []);
      // Изменение громкости
      const onVolumeChange = useCallback(
        (value: number) => setVolume(value),
        [],
      );
      // Скачать видео
      const onDownload = useCallback(() => {
        const a = document.createElement('a');
        a.href = embedUrl;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }, [embedUrl]);
      // Открытие/закрытие полноэранного режима
      const openFullScreen = useCallback(() => {
        if (!wrapper.current) return;
        screenfull.request(wrapper.current);
        // При закрытии устанавлием стейт в false (чтобы корректно отробатывал esc)
        screenfull.onchange((_) => {
          if (!screenfull.isFullscreen) {
            onFullScreenProp(false);
            setIsFullScreen(false);
          }
        });
        setIsFullScreen(true);
      }, [wrapper, setIsFullScreen]);
      const closeFullScreen = useCallback(() => {
        screenfull.exit();
        onFullScreenProp(false);
        setIsFullScreen(false);
      }, [setIsFullScreen]);
      const onFullScreen = useCallback(() => {
        if (onFullScreenProp) {
          onFullScreenProp(!isFullscreen);
          if (customFullScreen) return;
        }
        // если открыто на полный экран, закрываем
        if (screenfull.isFullscreen) {
          closeFullScreen();
        // eslint-disable-next-line @typescript-eslint/brace-style
        }
        // если не открыто, открываем
        else {
          openFullScreen();
        }
      }, [
        customFullScreen,
        isFullscreen,
        onFullScreenProp,
        openFullScreen,
        closeFullScreen,
      ]);
      const onSeekStart = useCallback(() => {
        setSeeking(true);
        setPlayingState(false);
      }, []);
      const onSeek = useCallback((val: number) => {
        playerRef.current?.seekTo(val);
      }, []);
      const onSeekEnd = useCallback(() => {
        setSeeking(false);
        setPlayingState(true);
      }, []);
      // Отключение/включение звука
      const onMute = useCallback(() => setMutedState((prev) => !prev), []);
      // Клик по контейнеру
      const onContainerClick = useCallback(
        (e: MouseEvent<Element>) => {
          if (e.target instanceof HTMLVideoElement) {
            setPlayingState((prev) => !prev);
          } else {
            toggleHUD();
          }
        },
        [playingState],
      );

      /**
       * Обработка двойного и одинарного нажатия
       * двойным нажатием считается два подряд нажатия с разницей не более 150мс
       */
      const containerClickHandler = useCallback(
        (event: MouseEvent<Element>) => {
          if (!timer.current) {
            // при одинарном нажатии
            timer.current = setTimeout(() => {
              onContainerClick(event);
              timer.current = null;
            }, 300);
          } else {
            // при двойном нажатии
            clearTimeout(timer.current);
            timer.current = null;
            onFullScreen();
          }
        },
        [timer.current, onFullScreen, onContainerClick],
      );

      const onReady = useCallback(() => {
        if (!isReady && playerRef.current) {
          if (startFrom) playerRef.current.seekTo(startFrom, 'seconds');
          setIsReady(true);
          onReadyProp();
        }
      }, [startFrom, isReady]);

      const handleStart = () => {
        onReady();
      };

      const onContenerMouseEnter = useCallback(() => {
        cursoreInRef.current = true;
        if (pauseHiddenRef.current) clearTimeout(pauseHiddenRef.current);
        setPausedHiddenHUD(false);
      }, []);
      const onContenerMouseLeave = useCallback(() => {
        cursoreInRef.current = false;
        if (!playingState) {
          pauseHiddenRef.current = setTimeout(
            () => setPausedHiddenHUD(true),
            1200,
          );
        }
      }, [playingState]);
      const hidePausedHUD = useCallback(() => {
        !playerRef.current?.props.playing && setPausedHiddenHUD(true);
      }, []);
      const checkMouseMove = useCallback(() => {
        if (!playerRef.current?.props.playing && cursoreInRef.current) {
          clearTimeout(pauseHiddenRef.current);
          setPausedHiddenHUD(false);

          pauseHiddenRef.current = setTimeout(hidePausedHUD, 1200);
        }
      }, []);
      const checkMouseMoveThrottle = useThrottle(checkMouseMove, 200);
      const changeCursore = useCallback(
        (style: 'none' | 'default') => () => {
          if (style === 'none') {
            if (playerRef.current?.props.playing) {
              if (wrapper.current) wrapper.current.style.cursor = 'none';
              setCursoreHidden(true);
            }
          } else {
            if (wrapper.current) wrapper.current.style.cursor = 'default';
            setCursoreHidden(false);
          }
        },
        [],
      );
      const hideCursore = useCallback(() => {
        if (playerRef.current?.props.playing) {
          clearTimeout(hideCursoreRef.current);
          changeCursore('default')();

          hideCursoreRef.current = setTimeout(changeCursore('none'), 3000);
        }
      }, []);
      const hideCursoreThrottle = useThrottle(hideCursore, 200);

      useEffect(() => {
        return () => {
          document.removeEventListener('mousemove', checkMouseMoveThrottle);
          document.removeEventListener('mousemove', hideCursoreThrottle);
        };
      }, []);

      useEffect(() => {
        if (inScreen) {
          document.addEventListener('mousemove', checkMouseMoveThrottle);

          return () => {
            document.removeEventListener('mousemove', checkMouseMoveThrottle);
          };
        }
      }, [inScreen]);

      useEffect(() => {
        if (isTabletLandscape) {
          toggleHUD();
        }

        if (playingState) {
          onPlaying();
          setPausedHiddenHUD(false);
          if (fullscreen) {
            hideCursore();
          }
        } else {
          checkMouseMoveThrottle();
        }
      }, [playingState]);

      useEffect(() => {
        if (customFullScreen) {
          setIsFullScreen(fullscreen);
        }

        if (inScreen && fullscreen) {
          hideCursoreThrottle();
          document.addEventListener('mousemove', hideCursoreThrottle);
          document.addEventListener('mousedown', changeCursore('default'));

          return () => {
            clearTimeout(hideCursoreRef.current);
            changeCursore('default')();
            document.removeEventListener('mousemove', hideCursoreThrottle);
            document.removeEventListener('mousedown', changeCursore('default'));
          };
        }
      }, [fullscreen]);

      useImperativeHandle(
        ref,
        () => ({
          openFullscreen: openFullScreen,
          closeFullscreen: closeFullScreen,
          seekTo: (time) => playerRef.current?.seekTo(time / 1000, 'seconds'),
          seekStart: onSeekStart,
          seekEnd: onSeekEnd,
        }),
        [openFullScreen, closeFullScreen, onSeekStart, onSeekEnd],
      );

      return (
        <div
          ref={wrapper}
          className={classnames(
            styles['video-player-container'],
            playingState && styles['video-player-container_playing'],
            styles['video-player-container_show-hud-always'],
            styles['video-player-container_show-hud-auto'],
            isMobile
              ? !showHUD && styles['video-player-container_show-hud-hidden']
              : alwaysShowHUD
                ? styles['video-player-container_show-hud-always']
                : styles['video-player-container_show-hud-auto'],
            isFullscreen && styles['video-player-container_fullscreen'],
            isFullscreen &&
              cursoreHidden && [
              styles['video-player-container_show-hud-hidden'],
              styles['video-player-container_show-hud-hidden_playing'],
            ],
            containerClassName,
          )}
          onClick={containerClickHandler}
          onMouseLeave={onContenerMouseLeave}
          onMouseEnter={onContenerMouseEnter}
        >
          {
            !(playingState || seeking) && <PausedModal />
          }
          {
            customCover &&
              React.createElement(customCover, {
                playing: playingState,
              })
          }
          <ReactPlayer
            wrapper={VideoWrapper}
            ref={playerRef}
            width={videoWidth}
            height={videoHeight}
            url={src}
            pip={pictureInPicture}
            playing={playingState}
            controls={false}
            light={light}
            volume={volume}
            loop={true}
            muted={mutedState}
            onError={errorHandler}
            onPlay={handlePlay}
            onStart={handleStart}
            onEnded={handelEnded}
            onDuration={handleDuration}
            onProgress={handleProgress}
            onReady={onReady}
            progressInterval={250}
            playbackRate={playbackRate}
            config={{ file: { attributes: { preload: 'none' } } }}
          />
          {customHUD ? (
            React.createElement(customHUD, {
              type: !isTabletLandscape ? 'desktop' : 'mobile',
              fullscreen: isFullscreen,
              loaded: loaded,
              played: played,
              volume: volume,
              mute: mutedState,
              duration: duration,
              playing: playingState,
              seeking: seeking,
              onSeek: onSeek,
              onSeekStart: onSeekStart,
              onSeekEnd: onSeekEnd,
              onMute: onMute,
              onPlay: handleHUDPlay,
              onDownload: onDownload,
              onFullScreen: onFullScreen,
              onVolumeChange: onVolumeChange,
              onPlayedChange: onPlayedChange,
              onNext: onNext,
              onPrevious: onPrevious,
              showVolumeChanger: showVolumeChanger,
            })
          ) : (
            <PlayerHUD
              additionalLeftButtons={additionalLeftButtons}
              pausedHidden={pausedHiddenHUD}
              showVolumeChanger={showVolumeChanger}
              type={!isTabletLandscape ? 'desktop' : 'mobile'}
              fullscreen={isFullscreen}
              loaded={loaded}
              played={played}
              volume={volume}
              mute={mutedState}
              duration={duration}
              playing={playingState || seeking}
              seeking={seeking}
              onSeek={onSeek}
              onMute={onMute}
              onPlay={handleHUDPlay}
              onDownload={onDownload}
              onFullScreen={onFullScreen}
              onVolumeChange={onVolumeChange}
              onPlayedChange={onPlayedChange}
              onNext={onNext}
              onPrevious={onPrevious}
              onSeekStart={onSeekStart}
              onSeekEnd={onSeekEnd}
            />
          )}
        </div>
      );
    },
  ),
);
