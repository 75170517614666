export const MAP_PATH = process.env.REACT_APP_TILE_PATH;

export enum Map {
  CUSTOM_WMS_LAYER = 'https://dev.map.urc.dev.kraud.ru:5443/geoserver/cite/wms',
  MAP_PATH = 'https://tile.interactivemap.ru/tile/{z}/{x}/{y}.png',
  MIN_ZOOM = 3,
  MAX_ZOOM = 15,
  FLY_TO_ZOOM_VALUE = 15,
  DEFAULT_ZOOM = 3,
  DEFAULT_LAT = 61,
  DEFAULT_LNG = 86,
  MINI_MAP_DEFAULT_ZOOM = 16,
  MINI_MAP_MIN_ZOOM = 5,
}
