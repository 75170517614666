import { memo, useRef } from 'react';
import './content.style.scss';
import { createClusterCustomIcon } from '../../../utils';
import type { MarkerClusterGroup as MarkerClusterGroupType } from 'leaflet';
import { MarkerClusterGroup } from '../../../utils';
// import MarkerClusterGroup from "../../../react-leaflet-markercluster
import { MarkerContent } from './marker-content';
import type { ClusterContentProps } from './content.types';


const MarkerClusterGroupElement = MarkerClusterGroup as any;

export const ClusterContent = memo(({ items, types }: ClusterContentProps) => {
  const clusterGroupRer = useRef<MarkerClusterGroupType | null>(null);
 
  return (
    <MarkerClusterGroupElement
      ref={clusterGroupRer}
      maxClusterRadius={80}
      iconCreateFunction={createClusterCustomIcon}
      showCoverageOnHover={false}
    >
      <MarkerContent
        items={items}
        types={types}
      />
    </MarkerClusterGroupElement>
  );
});
