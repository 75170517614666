export const SidebarObjectInfoHeading = {
  possibility: 'Вместимость храма: ',
  employeeVisits: 'Количество сотрудников ПАО «Газпром»: ',
  necessaryInvest: 'Общий объем пожертвований: ',
  volumeWorks: 'Общий объём и перечень работ: ',
  distanceDistrictCenter: 'До административного здания филиала: ',
  owner: 'Инициатор: ',
  place: 'Местоположение: ',
  macroRegionValue: 'Макрорегион: ',
  programValue: 'Программа: ',
  typeOfWork: 'Тип строительства: ',
  realizationPeriod: 'Срок реализации: ',
  monitoringId: 'Id Мониторинг проекта: ',
  startOfBuilding: 'Старт работ: ',
  name: 'Имя: ',
  descriptionObject: 'Описание объекта:',
  dangerZone: 'Опасная зона: ',
};
