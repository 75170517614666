import styles from './cluster-switcher.module.scss';
import { ClusterSwitchButton } from './cluster-switcher-button';
import type { ClusterSwitcherProps } from './cluster-switcher.types';


export const ClusterSwitcher = ({ className = '', onToggle, checked }: ClusterSwitcherProps) => {

  const switcherHandler = (value: boolean) => {
    if (checked === value) {
      return;
    }
    onToggle(value);
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <ClusterSwitchButton 
        checked={checked}
        onToggle={() => switcherHandler(false)}
      >
        Кластеры
      </ClusterSwitchButton>
      <ClusterSwitchButton
        checked={!checked}
        onToggle={() => switcherHandler(true)}
      >
        Точки
      </ClusterSwitchButton>
    </div>
  );
};
