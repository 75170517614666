import styles from '../profile-menu-map-page.module.scss';
import { ProfileMenuMapPageTriggerProps } from '../profile-menu-map-page.types';
import { Profile } from '../../../common';


export const ProfileMenuTrigger = ({ 
  isMenuOpened, 
  src, 
  onClick = () => {}, 
} : ProfileMenuMapPageTriggerProps ) => {
  return (
    <div 
      className={styles['trigger-avatar-container']}
      onClick={onClick}
    >
      <Profile isProfileMenuOpened={isMenuOpened} src={src}/>
    </div>
  );
};
