import styles from './header-map.module.scss';
import { HeaderMapProps } from './header-map.types';
import { Button, HeaderContainer } from '../../common';
import { ViewSwitcher } from '../../view-switcher';
import { MapProfileMenu as ProfileMenu } from '../..';
import { useDeleteObjectSelector, useUserProfileInfo } from '../../../state/selector';
import { SearchWrapper } from '../../search/search-wrapper';
import { setDeleteObject, useAppDispatch } from '../../../state';
import { useViewModeSelector } from '../../../history-state/selectors';
import { useBreakpointsComparison } from '../../../hooks';
import { ScreenSize } from '../../../constants';
import defaultAvatart from '../../../images/default_avatar.png';

export const HeaderMap = ({
  children,
  showSideBar,
  isSidebarOpened,
  editorMode,
  role,
  view,
  onSelectViewMode,
  selectedObject,
  isLoading,
  isScrolling,
}: HeaderMapProps) => {
  const user = useUserProfileInfo();
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
  const dispatch = useAppDispatch();
  const isDeleteObject = useDeleteObjectSelector();
  const [, setViewMode] = useViewModeSelector();

  return (
    <HeaderContainer
      isScrolling={isScrolling}
      isLoading={isLoading}
      view={view}
      isSidebarOpened={isSidebarOpened}
    >
      <div className={styles.wrapper}>
        <div className={styles['wrapper-user']}>
          {!isDeleteObject ? (
            <ProfileMenu
              src={`${
                !user?.profile_image
                  ? defaultAvatart
                  : `${process.env.REACT_APP_BACKEND_IMAGE_PATH as string}${
                      user?.profile_image
                    }`
              }`}
              name={user?.name}
              email={user?.email}
            />
          ) : (
            <Button
              className={styles['content-wrapper-start-button']}
              elementType={'button'}
              typeBtn={'elevation'}
              size={'l'}
              children={'Назад'}
              showLeftIcon
              leftIcon={<i className={'icon icon-arrow-1-left icon-middle'} />}
              onClick={() => {
                setViewMode('map');
                dispatch(setDeleteObject(false));
              }}
            />
          )}
        </div>
        <div className={styles['wrapper-search']}>
          <SearchWrapper />
        </div>
        <div className={styles['wrapper-buttons']}>
          {!isDeleteObject && (
            <div
              className={`${styles['view-switcher-wrapper']} ${
                view === 'list' ? styles['view-switcher-list-view-bs'] : ''
              }`}
            >
              <ViewSwitcher
                role={role}
                editorMode={editorMode}
                view={view}
                onSelectViewMode={onSelectViewMode}
              />
            </div>
          )}
          <div
            className={`${styles['view-switcher-btn-wrapper']} ${
              view === 'list' ? styles['view-switcher-list-view-bs'] : ''
            }`}
          >
            {!selectedObject && (
              <Button
                size={isTabletLandscape ? 'm' : 'l'}
                style={{ width: 'fit-content' }}
                onlyIcon={isSidebarOpened}
                onClick={showSideBar}
                elementType={'button'}
                typeBtn={'elevation'}
                text={'Фильтры'}
                showLeftIcon
                leftIcon={
                  isSidebarOpened ? (
                    <i
                      className={`icon icon-arrow-2-right icon-${
                        isTabletLandscape ? 'tiny' : 'middle'
                      }`}
                    />
                  ) : (
                    <i
                      className={`icon icon-arrow-2-left icon-${
                        isTabletLandscape ? 'tiny' : 'middle'
                      }`}
                    />
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};
