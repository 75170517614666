import { TextElementFabric } from '../../utils';
import type { TextElementProps } from './text-element.types';


export const PTag = (props: TextElementProps): JSX.Element => {
  return (
    <TextElementFabric tag='p' {...props}>
      {props.children}
    </TextElementFabric>
  );
};
