import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';


export const mapApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),

  endpoints: (builder) => ({
    getEnv: builder.mutation<Record<string, string>, void>({
      query() {
        return {
          url: '/env.json',
        };
      },
    }),
  }),
});

export const {
  useGetEnvMutation,
} = mapApi;
