import { useState } from 'react';
import type { ObjectCardContainerProps } from './object-card.types';
import { Card } from '../common';
import { useBreakpointsComparison } from '../../hooks';
import { ObjectCardDesktop } from './object-card.desktop';
import { ObjectCardMobile } from './object-card.mobile';
import { ObjectCardTablet } from './object-card.tablet';
import { ObjectDelete } from '../object-delete/object-delete';
import styles from './object-card.module.scss';
import { useDeleteObjectSelector } from '../../state';
import { ScreenSize } from '../../constants';

export const ObjectCard = ({
  selected = false,
  onClick = () => {},
  object,
  onCloseSidebar,
  ...props
}: ObjectCardContainerProps) => {
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const isDeleteObject = useDeleteObjectSelector();

  console.log('is demo user: ');

  const ObjectCard = () => {
    if (isTablet) {
      return (
        <ObjectCardMobile
          setDeleteModal={setDeleteModal}
          object={object}
          isDeleteObject={isDeleteObject}
          {...props}
        />
      );
    }
    if (isTabletLandscape) {
      return (
        <ObjectCardTablet setDeleteModal={setDeleteModal} object={object} {...props} />
      );
    }
    return (
      <ObjectCardDesktop
        setDeleteModal={setDeleteModal}
        onCloseSidebar={onCloseSidebar}
        object={object}
        {...props}
      />
    );
  };

  return (
    <>
      {deleteModal && (
        <ObjectDelete
          title={object.name}
          objectId={object.id}
          onClose={(e) => setDeleteModal(e)}
          onCloseSidebar={onCloseSidebar}
        />
      )}

      <Card selected={selected} onClick={onClick} className={styles.card}>
        <ObjectCard />
      </Card>
    </>
  );
};
