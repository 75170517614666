import styles from './search-item.module.scss';
import { SearchItemProps } from './search-item.types';
import { SpanTag } from '../text';
import { useSelectObject } from '../../../hooks/depended/useSelectObject';
import { useFlyToObjectOnMap } from '../../../hooks';
import { ObjectInterface } from '../../../types';
import cn from 'classnames';
import { useState } from 'react';


export const SearchItem = ({
  onMouseEnter,
  name,
  place,
  initiator,
  className,
  children,
  item,
  hideDropdown,
  isActiveSuggestion,
  inputQueryValue,
  searchItemContainerRef,
  handleMouseEnterOnSearchSuggestion,
  idx,
  ...props
}: SearchItemProps) => {
  const [, setSelectedObjectId] = useSelectObject();
  const flyTo = useFlyToObjectOnMap();
  const [onTouchStartSelectedItem, setOnTouchStartSelectedItem] = useState<number | null>(null);
  const lowerCaseAndTrimmedValue = inputQueryValue?.toLowerCase().trim();

  const handleClickOnSuggestion = (suggestion: ObjectInterface) => {
    flyTo(suggestion.coords.coordinates as [number, number]);
    setSelectedObjectId(suggestion.id);
    hideDropdown && hideDropdown();
  };

  const searchItemClasses = cn(styles['search-item'], {
    [styles['search-item-active']]: isActiveSuggestion,
  });

  const checkIfFieldincludesValue = (field?: string) => {
    const tooltipWordsIdx: number[] = [];

    field &&
      inputQueryValue &&
      field.toLowerCase()?.trim().includes(inputQueryValue?.toLowerCase().trim()) &&
      field
        ?.toLowerCase()
        .trim()
        .split(' ')
        .map(
          (word, idx) =>
            word.includes(inputQueryValue?.toLowerCase().trim()) &&
            tooltipWordsIdx.push(idx),
        );
    return tooltipWordsIdx;
  };

  
  const getMatchedFirstWord = ({ val, idxs, fieldArr, title,
  }: { val?: string, idxs: number[] | [], fieldArr?: string[], title?: string }) => {
    let beginning: string | undefined  = '';
    let middle: string | undefined  = '';
    let end: string | undefined = '';
    
    if (title === 'Название') {
      beginning = fieldArr?.slice(0, Number(idxs[0])).join(' ');
      middle = fieldArr && fieldArr[idxs[0]];
      end = fieldArr?.slice(idxs[0] + 1).join(' ');
      return {
        beginning,
        middle,
        end,
      };
    }
    beginning = fieldArr?.slice(idxs[0] - 2, Number(idxs[0])).join(' ');
    middle = fieldArr && fieldArr[idxs[0]];
    end = fieldArr?.slice(idxs[0] + 1, idxs[idxs.length - 1] + 3).join(' ');
    return {
      beginning,
      middle,
      end,
    };
  };
  
  
  const makeAutoCompletes = (val?: string, field?: string, title?: string) => {
    const idxs = checkIfFieldincludesValue(field);
    const fieldArr = field?.split(' ');

    if (title === 'Название') {
      const { beginning,
        middle,
        end } = getMatchedFirstWord({ val, idxs, fieldArr, title: 'Название' });

      return (
        <SpanTag className={styles['search-item-text']}>
          {beginning}
          <SpanTag className={styles['search-item-text-bold']}> {middle}</SpanTag>
          {' '}
          {end}
        </SpanTag>
      );
    }
    const { beginning,
      middle,
      end } = getMatchedFirstWord({ val, idxs, fieldArr });

    return (
      <SpanTag className={styles['search-item-search-tooltip']}>
        {title}: ... {beginning}
        <SpanTag className={styles['search-item-search-tooltip-bold']}>
          {' '}
          {middle}
        </SpanTag>
        {' '}
        {end} ...
      </SpanTag>
    );
  };

  const defauldSuggestions = (field: string) => {
    const startIndexOfMatched = lowerCaseAndTrimmedValue && field.split(' ').map(word => word.toLowerCase()).join(' ').indexOf(lowerCaseAndTrimmedValue);
    const substrOfQueryVal =  (field : string) =>  Number(startIndexOfMatched) >= 0 && field.slice(Number(startIndexOfMatched)).split(' ').slice(0, 4).join(' ');

    return substrOfQueryVal(field);
  };
  
  const renderSearchTooltips = () => {

    return (
      <>
        {checkIfFieldincludesValue(name).length > 0 ? (
          makeAutoCompletes(lowerCaseAndTrimmedValue, name, 'Название')
        ) : (<>
          <SpanTag className={styles['search-item-text']}>{name}</SpanTag>
          {lowerCaseAndTrimmedValue && lowerCaseAndTrimmedValue.split(' ').length >= 2 && initiator?.toLowerCase().trim().includes(lowerCaseAndTrimmedValue) && <SpanTag className={styles['search-item-search-tooltip']}>
            <>Инициатор: ... {defauldSuggestions(initiator)} ...</>
          </SpanTag>}
          {lowerCaseAndTrimmedValue && lowerCaseAndTrimmedValue.split(' ').length >= 2 &&  place?.toLowerCase().trim().includes(lowerCaseAndTrimmedValue) && <SpanTag className={styles['search-item-search-tooltip']}>
            <> Адрес: ... {defauldSuggestions(place)} ...</>
          </SpanTag>}
        </>
        )}
        {checkIfFieldincludesValue(initiator).length > 0 &&
          makeAutoCompletes(lowerCaseAndTrimmedValue, initiator, 'Инициатор')}
        {checkIfFieldincludesValue(place).length > 0 &&
          makeAutoCompletes(lowerCaseAndTrimmedValue, place, 'Адрес')}
      </>
    );
  };

  return (
    <div
      onMouseEnter={() =>
        handleMouseEnterOnSearchSuggestion && handleMouseEnterOnSearchSuggestion(idx)
      }
      ref={searchItemContainerRef}
      className={searchItemClasses}
      onClick={() => handleClickOnSuggestion(item)}
      onTouchStart={() => setOnTouchStartSelectedItem(item.id)}
      onTouchEnd ={() => {
        if (onTouchStartSelectedItem === item.id) {
          return  handleClickOnSuggestion(item);
        }
      }}
    >
      {renderSearchTooltips()}
    </div>
  );
};
