import { useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';
import type { PortalProps } from './portal.types';


export const Portal = ({ 
  children, 
  className = '', 
  parentSelector = '#root', 
  as = 'div',
  ...props 
}: PortalProps) => {
  const el = useMemo(() => document.createElement(as), []);
  const parent: HTMLDivElement | null = document.querySelector(parentSelector);

  useEffect(() => {
    if (className) className.split(' ').forEach((item) =>  el.classList.add(item));

    parent?.appendChild(el);

    return () => {
      parent?.removeChild(el);
    };
  }, [parent, el, parentSelector, className]);

  return parent && createPortal(children, el);
};
