import { useState } from 'react';
import styles from './profile-menu-map-page.module.scss';
import { ProfileMenuMapPageProps } from './profile-menu-map-page.types';
import { ProfileMenuTrigger } from './components';
import { BottomSheet } from '../../common';
import { ProfileMenuButtons } from './components';


export const ProfileMenuMapPageMobile = ({ 
  src = 'https://avatars.mds.yandex.net/get-zen_doc/5262161/pub_61cb1853c97cb1018f443f83_61cb43b819129b335b14c1d9/scale_1200', 
  name = 'Name',
  email = 'johndoe@example.com' } : ProfileMenuMapPageProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  
  return  (
    <div className={styles['profile-menu']}>
      <ProfileMenuTrigger
        src={src}
        isMenuOpened={isMenuOpened}
        onClick={() => {
          setIsMenuOpened(!isMenuOpened);
        }}
      />
      <BottomSheet
        open={isMenuOpened}
        onDismiss={() => {
          setIsMenuOpened(false);
        }}
        title={name}
        subtitle={email}
        isActive={false}
        blocking
      >
        <div className={styles['profile-menu-mobile-content']}>
          <ProfileMenuButtons isMobile />
        </div>
      </BottomSheet>
    </div>
  );

};
