import React, { useEffect, useRef, useState, memo } from 'react';
import { IObjectInfoBoxProps } from './object-info-box.types';
import styles from './object-info-box.module.scss';
import { Button } from '../common';
import { ObjectInfoCard } from '../object-info-card/object-info-card';
import { IconProgress } from '../sidebar-filter/progress-icon';
import { ObjectDelete } from '../object-delete/object-delete';
import { PTag } from '../common';

export const ObjectInfoBox = ({
  title = '',
  content = '',
  editorMode,
  type,
  onEditField,
  isLoading = false,
  objectId,
  objectTitle = '',
  onCloseSidebar,
}: IObjectInfoBoxProps): JSX.Element => {
  const [isTextContentOpened, setTextContentOpened] = useState<boolean>(false);
  const [isTextContentLarge, setIsTextContentLarge] = useState<boolean | null>(false);
  const refContent = useRef<HTMLParagraphElement | null>(null);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (refContent.current) {
      setIsTextContentLarge(
        refContent.current.children[0].scrollHeight >
          refContent.current.children[0].clientHeight,
      );
    }

    return () => {
      setTextContentOpened(false);
      setIsTextContentLarge(false);
    };
  }, [isTextContentLarge, content]);

  if (
    (!content && !editorMode) ||
    (typeof content === 'string' && content.length <= 7 && !editorMode)
  ) {
    return <></>;
  }

  return (
    <>
      {deleteModal && (
        <ObjectDelete
          title={objectTitle}
          objectId={objectId}
          onClose={(e) => setDeleteModal(e)}
          onCloseSidebar={onCloseSidebar}
        />
      )}
      <ObjectInfoCard
        title={
          <PTag type={'subheader'} weight={1} style={{ margin: 0 }}>
            {title}
          </PTag>
        }
        content={
          <div ref={refContent}>
            <PTag
              type={'paragraph'}
              // ref={refContent}
              x={3.5}
              weight={1}
              className={`${styles['object-info-box-content-text']} ${
                isTextContentLarge && isTextContentOpened
                  ? styles['object-info-box-content-text-opened']
                  : ''
              }`}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        }
        footer={
          <>
            {!editorMode && isTextContentLarge && (
              <Button
                size={'s'}
                elementType={'button'}
                typeBtn={'secondary'}
                children={isTextContentOpened ? 'Скрыть' : 'Читать подробнее'}
                showLeftIcon
                leftIcon={<i className={'icon icon-read icon-middle'} />}
                onClick={() => setTextContentOpened(!isTextContentOpened)}
              />
            )}
            {editorMode && type !== 'delete_object' && (
              <Button
                elementType={'button'}
                typeBtn={'secondary'}
                disabled={isLoading}
                children={'Редактировать'}
                size={'s'}
                showLeftIcon
                leftIcon={
                  isLoading ? (
                    <IconProgress stroke={'#000'} />
                  ) : (
                    <i className={'icon icon-edit-2 icon-middle'} />
                  )
                }
                onClick={() => type && onEditField && onEditField(type)}
              />
            )}
            {editorMode && type === 'delete_object' && (
              <Button
                elementType={'button'}
                typeBtn={'danger'}
                disabled={isLoading}
                children={'Удалить объект'}
                showLeftIcon
                leftIcon={
                  isLoading ? (
                    <IconProgress stroke={'#000'} />
                  ) : (
                    <i className={'icon icon-Trashcan icon-middle'} />
                  )
                }
                onClick={() => {
                  setDeleteModal(true);
                }}
              />
            )}
          </>
        }
      />
    </>
  );
};
