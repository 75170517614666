import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import type { RootState, TokenType } from '../../types';
import { logout, login } from '../';
import { userApi } from './user-api';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';


const BASE_URL = process.env.REACT_APP_BACKEND_API_PATH as string;


const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set(
      'X-Requested-With', 'XMLHttpRequest',
    );

    const token = (getState() as RootState).user.token.accessToken;
    if (token) {
      headers.set(
        'Authorization', `Bearer ${token}`,
      );
    }
    return headers;
  },
});


const mutex = new Mutex();

export const authCheckQuery: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          { 
            method: 'POST',
            credentials: 'include', 
            url: '/auth/refresh/token',
            body: { refresh_token: (api.getState() as RootState).user.token.refreshToken },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          api.dispatch(login(refreshResult.data as TokenType));
          api.dispatch(userApi.endpoints.getUserInfo.initiate());

          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logout());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};


export const api = createApi({
  baseQuery: authCheckQuery,

  tagTypes: ['Map', 'User', 'Object'],

  endpoints: () => ({}),
});

