import styles from './object-card.module.scss';
import type { ObjectCardProps } from './object-card.types';
import { H4Tag, SpanTag } from '../common';
import { getMainSidebarImageUrl } from '../../utils';
import { ObjectButtons } from '../object-buttons';
import { ObjectInfo } from '../object-info';


export const ObjectCardTablet = ({ 
  object,
  editorMode = false,
  onCloseSidebar,
  setDeleteModal = () => {},
  flyToHandler,
}: ObjectCardProps) => {
  const photo = getMainSidebarImageUrl(object);

  return (
    <>
      {
        photo && (
          <div 
            className={styles['card-tablet-image']} 
            style={{ backgroundImage: `url(${photo})` }}
          />
        )
      }
      <div className={styles['card-tablet-container']}>
        <span>
          <H4Tag 
            className={styles['card-header-title']}
            weight={3}
          >
            {object.name}
          </H4Tag>
          <SpanTag 
            className={styles['card-header-subtitle']}
            type={'caption'} 
            x={3} 
            weight={1}
          >
            {object.place}
          </SpanTag>
        </span>
        <ObjectInfo 
          type="card" 
          onCloseSidebar={onCloseSidebar} 
          object={object} 
          editorMode={editorMode} 
        />
        <div className={styles['card-tablet-container-buttons']}>
          <ObjectButtons
            editorMode={editorMode}
            object={object}
            type={'card'}
            setDeleteModal={setDeleteModal}
            flyToObject={flyToHandler}
          />
        </div>
      </div>
    </>
  );
};
