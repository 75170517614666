import type { ObjectInterface } from '../../../../types';


type ObjectPriorityItem = Record<number, number>;


export const getObjectPriorityList = (prioriList: ObjectPriorityItem, objectList: ObjectInterface[]) => {
  const resultArray = new Array(Object.keys(prioriList).length);

  objectList.forEach((item: ObjectInterface) => {
    if (prioriList[item.id]) {
      return resultArray[prioriList[item.id]] = item;
    }
    return resultArray.push(item);
  });

  return resultArray;
};
