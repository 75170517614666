import type { IObjectInfoDesktopProps } from './object-info.types';
import styles from './object-info.module.scss';
import { ToolTip } from '../common';
import { getContentValues } from './utils';
import { Heading } from '../../constants';
import { PTag, SpanTag } from '../common';

export const ObjectInfoDesktop = ({
  object,
  dividerTextLength = 40,
}: IObjectInfoDesktopProps): JSX.Element => {
  const contentValues = getContentValues(object);

  return (
    <>
      {contentValues.passability ? (
        <ToolTip
          isVisible={
            Number(contentValues?.passability?.length) +
              Number(Heading?.passability?.length) >
            dividerTextLength
          }
          content={contentValues.passability}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.passability}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.passability}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {contentValues.employeeVisits ? (
        <ToolTip
          isVisible={
            Number(contentValues?.employeeVisits?.length) +
              Number(Heading?.employeeVisits?.length) >
            dividerTextLength
          }
          content={contentValues.employeeVisits}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.employeeVisits}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.employeeVisits}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {contentValues.necessaryInvest ? (
        <ToolTip
          isVisible={
            Number(contentValues?.necessaryInvest?.length) +
              Number(Heading?.necessaryInvest?.length) >
            dividerTextLength
          }
          content={contentValues.necessaryInvest}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.necessaryInvest}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.necessaryInvest}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {contentValues.distanceDistrictCenter ? (
        <ToolTip
          isVisible={
            Number(contentValues?.distanceDistrictCenter?.length) +
              Number(Heading?.distanceDistrictCenter?.length) >
            dividerTextLength
          }
          content={contentValues.distanceDistrictCenter}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.distanceDistrictCenter}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.distanceDistrictCenter}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {contentValues.owner ? (
        <ToolTip
          isVisible={
            Number(contentValues.owner.length) + Number(Heading?.owner?.length) >
            dividerTextLength
          }
          content={contentValues.owner}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.owner}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.owner}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {contentValues.macroRegionValue ? (
        <ToolTip
          isVisible={
            Number(contentValues?.macroRegionValue?.length) +
              Number(Heading?.macroRegionValue?.length) >
            dividerTextLength
          }
          content={contentValues.macroRegionValue}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.macroRegionValue}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.macroRegionValue}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      {contentValues.programValue ? (
        <ToolTip
          isVisible={
            Number(contentValues.programValue.length) +
              Number(Heading?.programValue?.length) >
            dividerTextLength
          }
          content={contentValues.programValue}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.programValue}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
            >
              {contentValues.programValue}
            </SpanTag>
          </PTag>
        </ToolTip>
      ) : null}
      <ToolTip
        isVisible={
          Number(contentValues.typeOfWork!.length) + Number(Heading?.typeOfWork?.length) >
          dividerTextLength
        }
        content={contentValues.typeOfWork}
      >
        <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
          {Heading.typeOfWork}
          <SpanTag
            x={3.5}
            weight={1}
            className={styles['object-info-content-title-value']}
          >
            {contentValues.typeOfWork}
          </SpanTag>
        </PTag>
      </ToolTip>

      {contentValues.realizationPeriod ? (
        <ToolTip
          isVisible={
            Number(contentValues.realizationPeriod.length) +
              Number(Heading.realizationPeriod.length) >
            dividerTextLength
          }
          content={contentValues.realizationPeriod.replace(/<[^>]*>/g, '')}
        >
          <PTag x={3.5} weight={2} className={styles['object-info-content-title']}>
            {Heading.realizationPeriod}
            <SpanTag
              x={3.5}
              weight={1}
              className={styles['object-info-content-title-value']}
              dangerouslySetInnerHTML={{ __html: contentValues.realizationPeriod.trim() }}
            />
          </PTag>
        </ToolTip>
      ) : null}
    </>
  );
};
