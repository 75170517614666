export const objectPriorityList = {
  1349: 1,
  1345: 2,
  1343: 3,
  1341: 4,
  1469: 5,
  1344: 6,
  1348: 7,
  300: 8,
  1360: 9,
  1468: 10,
  20: 11,
  1475: 12,
  8: 13,
  257: 14,
  1466: 15,
  71: 16,
  282: 17,
  1465: 18,
  89: 19,
  26: 20,
  1375: 21,
  1358: 22,
  85: 23,
  1464: 24,
  59: 25,
  1363: 26,
  1359: 27,
  1471: 28,
  1470: 29,
  1473: 30,
  1472: 31,
  98: 32,
  816: 33,
  1429: 34,
  1318: 35,
  1506: 36,
  1467: 37,
  1271: 38,
};