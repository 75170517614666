import styles from './object-card.module.scss';
import { ObjectCardProps } from './object-card.types';
import { H4Tag, SpanTag } from '../common';
import { DemoObjectInfo, ObjectInfo } from '../object-info';
import { PhotosSwitcher } from '../photos-switcher';
import emptyPhotoListImage from '../../images/object-bg-image.png';
import demoEmptyPhotoListImage from '../../images/demo-object-bg-image.png';
import { getMappedGalleryObject } from '../../utils/get-mapped-gallery-object';
import { ObjectButtons } from '../object-buttons';
import { useIsDemoUser } from '../../state';

export const ObjectCardDesktop = ({
  object,
  editorMode = false,
  role = 'user',
  onImageClick,
  onCloseSidebar,
  setDeleteModal = () => {},
  flyToHandler,
}: ObjectCardProps) => {
  const photoList = getMappedGalleryObject(object?.media, object.name, object.id);
  const isDemoUser = useIsDemoUser();

  return (
    <>
      <div className={styles['card-header']}>
        <H4Tag className={styles['card-header-title']} weight={3}>
          {object.name}
        </H4Tag>
        <SpanTag
          className={styles['card-header-subtitle']}
          type={'caption'}
          x={3}
          weight={1}
        >
          {object.place}
        </SpanTag>
      </div>
      {photoList.length ? (
        <PhotosSwitcher
          type="card"
          onImageClick={(item) => onImageClick(item)}
          photoList={photoList}
          editorMode={editorMode}
          role={role}
        />
      ) : (
        <div
          className={styles['card-empty-photo-list']}
          style={{
            backgroundImage: `url(${
              !isDemoUser ? emptyPhotoListImage : demoEmptyPhotoListImage
            })`,
          }}
        />
      )}
      <div className={styles['card-info']}>
        <div className={styles['card-container']}>
          <DemoObjectInfo
            type="card"
            onCloseSidebar={onCloseSidebar}
            object={object}
            role={role}
            editorMode={editorMode}
          />
        </div>
        <div className={styles['card-container']}>
          <ObjectButtons
            setDeleteModal={setDeleteModal}
            editorMode={editorMode}
            object={object}
            type={'card'}
            flyToObject={flyToHandler}
          />
        </div>
      </div>
    </>
  );
};
