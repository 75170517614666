import { memo, useEffect, useMemo, useRef } from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { addLineFeed, checkObjMacro, getObjectIcon } from '../../../utils';
import './marker.style.scss';
import type { LeafletMouseEvent, Marker as MarkerType } from 'leaflet';
import type { MarkerProps } from './marker.type';


const MarkerElement = Marker as typeof Marker & any;
const TooltipElement = Tooltip as typeof Tooltip & any;

const tooltipOffsets = {
  left: [-34, -20],
  right: [30, -15],
  top: [0, -30],
  bottom: [0, 30],
};

export const ObjectMarker = memo(function ObjectMarkerMemo({
  item,
  type,
  selected = false,
  disabled = false,
  onClick,
  isStar = false,
  isHeat = false,
  macro = '',
  isMobile,
  dotsView,
  showName,
}: MarkerProps) {
  const markerRef = useRef<MarkerType | null>(null);

  const eventHandler = useMemo(
    () => ({
      click: () => {
        !disabled &&
        onClick &&
        onClick(Number(item.id));
      },
      mouseout: (e: LeafletMouseEvent) => e.target.closePopup(),
      mouseover: (e: LeafletMouseEvent) => e.target.openPopup(),
      
    }),
    [disabled, selected, onClick, item, isMobile],
  );

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setIcon(
        getObjectIcon(
          type,
          item,
          40,
          selected,
          disabled,
          isHeat,
          macro,
          dotsView,
          showName,
        ),
      );
    }
  }, [type, item, selected, disabled, isStar, isHeat, macro, dotsView, showName]);

  const checkedObj = checkObjMacro(item);

  return useMemo(() => {
    if (item) {
      return (
        <MarkerElement
          className={'number-icon'}
          position={[item.coords.coordinates[0], item.coords.coordinates[1]]}
          eventHandlers={eventHandler}
          ref={markerRef}
          rate={item?.rating?.rate ?? 0}
          macro_region={item?.macro_region ?? 0}
          zIndexOffset={disabled ? 0 : 1000}
        >
          {!isMobile && (
            <TooltipElement offset={tooltipOffsets.right} opacity={1}>
              {checkedObj ? (
                <div className={'leaflet-tooltip-content'}>
                  <div className={'leaflet-tooltip-content-name'}>
                    {addLineFeed(item?.name)}
                  </div>
                  <div className={'leaflet-tooltip-content-place'}>
                    {addLineFeed(item?.place)}
                  </div>
                </div>
              ) : (
                'В работе'
              )}
            </TooltipElement>
          )}
        </MarkerElement>
      );
    }
    return <></>;
  }, [item, eventHandler, isMobile, selected]);
}, (prevState, nextState): boolean => {
  if (prevState.selected !== nextState.selected) return false;
  if (prevState.item !== nextState.item) return false;
  if (prevState.dotsView !== nextState.dotsView) return false;
  if (prevState.type !== nextState.type) return false;
  return true;
});
