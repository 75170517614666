import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import type { RootState, TokenType } from '../../types';
import { logout, login } from '../';
import { userApi } from './user-api';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { formatObjectToQueryString } from '../../utils/format-object-to-query-string';
import { ThunkResult } from '@reduxjs/toolkit/dist/query/core/buildThunks';


const BASE_URL = process.env.REACT_APP_BACKEND_API2_PATH as string;


interface IdDateQuery {
  lineID: number,
  contentDate: string,
}

interface IdDateQueryWithType extends IdDateQuery {
  type: string,
}


const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set(
      'Content-Type', 'application/json; charset=UTF-8',
    );
    headers.set(
      'X-Requested-With', 'XMLHttpRequest',
    );
    headers.set(
      'if-none-match', 'W/"3b-fPkzLeKQ4F+xO4uNzYqAMJrKU8Y"',
    );
    headers.set(
      'cookie', '_ga=GA1.1.1907659617.1660055177; _ym_d=1660055178; _ym_uid=16600551781002203434; connect.sid=s%3Ad9uY5PtgGS0EjdvHkZ_q5dWxGQHJ3HuW.9FRjMjfHFmiUipeqzzI65sn26giEzlJM4y0CWFFEmfc; _ym_isad=1; _ym_visorc=w; _ga_0RQVM2PCEN=GS1.1.1660134949.4.1.1660136323.0',
    );

    return headers;
  },
});


export const monitoringApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/api-ext',
  }),

  tagTypes: ['Companies'],

  endpoints: (builder) => ({
    getCompanies: builder.mutation({
      query() {
        return {
          url: '/user/api/companies',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getProject: builder.mutation({
      query(id: number) {
        return {
          url: `/user/api/projects/${id}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getContentTags: builder.mutation<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        return {
          url: `/user/api/lines/${lineID as number}/?date=${contentDate as string}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getPanorama: builder.mutation<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        return {
          url: `/user/api/lines/${lineID as number}/content/panorama?dateFrom=${contentDate as string}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'], 
    }),
    getLineScheme: builder.query<any, any>({
      query({ lineID, contentType, contentDate }: any): any {
        return {
          url: `/user/api/lineScheme/?${formatObjectToQueryString({ lineId: lineID, contentType, date: contentDate })}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getCalendarByType: builder.query<IdDateQuery, any>({
      query({ lineID, contentType }: { lineID: number, contentType: string }): any {
        return {
          url: `/user/api/lines/${lineID}/content/${contentType.toLowerCase()}/calendarByType`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getLineContentTabs: builder.query<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        const contentDateParam = contentDate ? `date=${contentDate as number}` : '';

        return {
          url: `/user/api/lines/${lineID as number}/contentsTabs?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getAllLinesByProject: builder.mutation<IdDateQueryWithType, ThunkResult>({
      query({ projectID }: any): any {
       
        return {
          url: `/user/api/projects/${projectID as number}/lines/`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineContentByType: builder.mutation<IdDateQueryWithType, ThunkResult>({
      query({ lineID, type, contentDate }: IdDateQueryWithType) {
        const contentDateParam = contentDate ? `date=${contentDate}` : '';

        return {
          url: `/user/api/lines/${lineID}/content/${type.toLowerCase()}?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineContent: builder.mutation<IdDateQuery, ThunkResult>({
      query({ lineID, contentDate }: IdDateQuery) {
        const contentDateParam = contentDate ? `date=${contentDate}` : '';

        return {
          url: `/user/api/lines/${lineID}/content/lineContents?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
  }),
});

export const {
  useGetCompaniesMutation,
  useGetLineSchemeQuery,
  useGetPanoramaMutation,
  useGetLineContentMutation,
  useGetLineContentTabsQuery,
  useGetLineContentByTypeMutation,
  useGetCalendarByTypeQuery,
  useGetProjectMutation,
} = monitoringApi;


// https://media-dev.interactivemap.ru/api-ext/user/api/lineScheme/?lineId=1&contentType=PANORAMA&date=2022-08-29&
// https://media-dev.interactivemap.ru/api-ext/user/api/lines/1/content/lineContents?date=2022-08-29

