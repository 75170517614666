import styles from './profile.module.scss';
import { ProfileProps } from './profile.types';
import { Avatar } from '../avatar';


export const Profile = ({ children, isProfileMenuOpened = false, src } : ProfileProps) => {
  
  return (
    <div className={styles['profile-container']}>
      <Avatar src={src}/>
    </div>
  );
};
