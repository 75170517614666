import type { MapViewType } from '../../../types';
import { PhotoType } from '../../../components/photos-switcher/photos-switcher.types';

 
export const windowsInitialState: WindowsInitialStateType = {
  isCreateObjectModalOpen: false,
  isInfoTableFullScreen: false,
  isInfoOpen: false,
  isSidebarOpen: true,
  isMonitoringOpen: window && window.location.pathname.includes('project/'),
  isInfoTablePanelOpen: false,
  isAnalyticsViewOpen: false,
  isObjectImageShow: true,
  editField: null,
  isOpenCoordsEditor: false,
  galleryObjectId: null,
  mapView: 'map',
  blockingPartOfPage: {
    imagePart: false,
    textPart: false,
    creationPanelPart: false,
  },
  isShowCalendar: false,
  isDeleteObject: false,
};
  
interface WindowsInitialStateType {
  isCreateObjectModalOpen: boolean,
  isInfoTableFullScreen: boolean,
  isInfoOpen: boolean,
  isMonitoringOpen: boolean,
  isSidebarOpen: boolean,
  isInfoTablePanelOpen: boolean,
  isAnalyticsViewOpen: boolean,
  isObjectImageShow: true,
  editField: string | null,
  mapView: MapViewType,
  isOpenCoordsEditor: boolean,
  galleryObjectId: PhotoType | null,
  blockingPartOfPage: BlockingPartOfPage,
  isShowCalendar: boolean,
  isDeleteObject: boolean,
}
  
interface BlockingPartOfPage {
  imagePart: boolean,
  textPart: boolean,
  creationPanelPart: boolean,
}