import React from 'react';
import styles from './mini-carousel.module.scss';
import { MiniCarouselProps } from './mini-carousel.types';
import cn from 'classnames';
import { IconCamera, IconEdit } from './icons';


export const MiniCarousel = ({
  imageListRef,
  imageListItemRef,
  editorMode = false,
  photoList,
  onImageClick,
  onCarouselScroll,
  type = 'sidebar',
}: MiniCarouselProps): JSX.Element => {
  
  const imageListClassses = cn(styles['mini-carousel-container-image-list'], {
    [styles['sidebar-list']]: type === 'sidebar',
    [styles['justify-center']]: (photoList && photoList.length < 4 && type === 'sidebar') || photoList?.length === 1,
  });
  
  const imageItemClasses = cn({
    [styles['mini-carousel-container-image-list-item']]: type === 'sidebar',
    [styles['mini-carousel-container-image-list-card-item']]: type === 'card',
  });
  
  return (
    <div className={imageListClassses}>
      <ul ref={imageListRef} className={styles['mini-carousel-container-image-list']}
        onScroll={onCarouselScroll}
      >
        {photoList?.length
          ? photoList.map((item) => {
            const { image, alt, id } = item;

            return (
              <li
                ref={imageListItemRef}
                key={id}
                onClick={() => onImageClick(item)}
                className={imageItemClasses}
              >
                <img 
                  src={image} 
                  alt={alt} key={id}
                  onLoad={(e: any) => {
                    if (e.target.naturalHeight > e.target.naturalWidth) {
                      e.target.className = styles['mini-carousel-container-image-list-card-item-vertical'];
                    }
                  }}
                />
                {
                editorMode 
                  ? <IconEdit 
                    className={styles['icon-edit']}
                  /> 
                  :
                  <IconCamera 
                    className={styles['icon-camera']}
                  />
                }
              </li>
            );
          })
          : null}
      </ul>
    </div>
  );
};
