import './content.style.scss';
import type { ContentWrapperProps } from './content.types';
import {
  useIsClusterMarkerViewSelector,
  useObjectsTypesSelector,
  useFilteredObjectsSelector,
  useIsDemoUser,
  useGetDemoObjectsSelector,
} from '../../../../state';
import { ClusterContent } from './cluster-content';
import { MarkerContent } from './marker-content';
import { useMemo } from 'react';

export const ContentWrapper = ({ distanceValue }: ContentWrapperProps) => {
  const isDemoUser = useIsDemoUser();
  const items = useFilteredObjectsSelector();
  const demoItems = useGetDemoObjectsSelector();
  const types = useObjectsTypesSelector();
  const isClusterMarkerView = useIsClusterMarkerViewSelector();

  return useMemo(() => {
    if (isClusterMarkerView) {
      return <ClusterContent items={!isDemoUser ? items : demoItems} types={types} />;
    }

    return (
      <MarkerContent
        items={!isDemoUser ? items : demoItems}
        types={types}
        dotsView={distanceValue === 'far'}
        showName={distanceValue === 'close'}
      />
    );
  }, [isDemoUser, items, demoItems]);
};
