import { useState, useEffect, ChangeEvent } from 'react';
import './coords-panel.module.scss';
import styles from './coords-panel.module.scss';
import { H4Tag, Input } from '../common';
import { Button } from '../common';
import { ICoordsPanelProps } from './coords-panel.types';
import {
  useAppDispatch,
  toggleCoordsEditor,
  useUpdateObjectCoordsMutation,
  
} from '../../state';
import {
  useClickPosition,
  useEditorModeStateSelector,
  useOpenCoordsEditor,
} from '../../state/selector';
import { useAppObjects } from '../../state/selector/selector';
import { useSelectedObjectSelector } from '../../history-state/selectors';
import { useBreakpointsComparison } from '../../hooks';
import { ScreenSize } from '../../constants';


export const CoordsPanel = ({}: ICoordsPanelProps): JSX.Element => {
  const objects = useAppObjects();
  const isEditMode = useEditorModeStateSelector();
  const isOpenCoordsEditor = useOpenCoordsEditor();
  const clickPosition = useClickPosition();
  const objectId = useSelectedObjectSelector();
  const dispatch = useAppDispatch();
  const isTableLandcape = useBreakpointsComparison(ScreenSize.TL);

  const [latValue, setLatValue] = useState<number>(0);
  const [lngValue, setLngValue] = useState<number>(0);


  const [updateObjectCoords] = useUpdateObjectCoordsMutation();

 
  const object: any = objects ? objects.find((item: { id: number; }) => (item.id === Number(objectId))) : false;

  const { coords } = object ? object : false;

  const changeLatHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setLatValue(Number(e.target.value));
  };

  const changeLngHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setLngValue(Number(e.target.value));
  };


  useEffect(() => {
    if (coords) {
      setLatValue(coords.coordinates[0]);
      setLngValue(coords.coordinates[1]);
    }
    if (!isOpenCoordsEditor) {
      setLatValue(0);
      setLngValue(0);
    }
  }, [isOpenCoordsEditor]);

  useEffect(() => {
    if (clickPosition) {
      const { lat, lng } = clickPosition;
      setLatValue(lat);
      setLngValue(lng);
    }
  }, [clickPosition]);

  const onClose = () => dispatch(toggleCoordsEditor());

  const handleSave = () => {
    updateObjectCoords({ value: [latValue, lngValue], id: objectId });
    onClose();
  };

  return (
    <div className={styles['coords-panel']}>
      {isOpenCoordsEditor && isEditMode && (
        <div className={styles['coords-panel-container']}>
          <div className={styles['coords-panel-header']}>
            <H4Tag
              className={styles['coords-panel-header-title']}
              weight={3}
            >
                Редактор координат
            </H4Tag>
            <Button 
              onlyIcon={true}
              className={styles['coords-panel-header-close_button']}
              onClick={onClose}
              elementType={'button'}
              typeBtn={'primary'}
              size={'s'}
              showLeftIcon
              leftIcon={<i className={'icon icon-close-3 icon-tiny'}/>}
            />
          </div>
          <div className={styles['coords-panel-text']}>
            <Input
              label={'Широта:'}
              value={String(latValue)}
              onChange={changeLatHandler}
              className={styles['coords-panel-input']}
            />
          </div>
          <div className={styles['coords-panel-text']}>
            <Input
              label={'Долгота:'}
              value={String(lngValue)}
              onChange={changeLngHandler}
              className={styles['coords-panel-input']}
            />
          </div>
          <div className={styles['coords-panel-save']}>
            <Button
              elementType={'button'}
              typeBtn={'primary'}
              size={'s'}
              onClick={handleSave}
              text={'Сохранить'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
