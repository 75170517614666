import { Navigate, Routes, Route } from 'react-router-dom';
import {
  GROUP_LINE_PATH,
  LINE_PATH,
  MAIN_PATH,
  AUTH_PATH,
  AUTH_MONITORING_PATH,
  PROFILE_PATH,
  // PROJECT_PATH,
  // GROUP_PATH,
  OBJECT_PATH,
} from '../constants';
import { LoginPage, MapPage, ProfilePage } from '../pages';
import { MediaPage } from '../pages/media-page/media-page';
import { MonitoringLogin } from '../pages/monitoring-login-page';

export const Router = ({ isAuth }: { isAuth: boolean }) => {
  if (isAuth) {
    return (
      <Routes>
        <Route path={MAIN_PATH} element={<MapPage />} />
        <Route path={`${OBJECT_PATH}/*`} element={<MapPage />} />
        <Route path={AUTH_MONITORING_PATH} element={<MonitoringLogin />} />
        <Route path={`${MAIN_PATH}${PROFILE_PATH}/*`} element={<ProfilePage />} />
        {[LINE_PATH, GROUP_LINE_PATH].map((item, index) => (
          <Route key={index} path={item} element={<MediaPage />}>
            <Route path={':contentDate'} element={<MediaPage />}>
              <Route path={':contentType'} element={<MediaPage />}>
                <Route path={':contentID'} element={<MediaPage />} />
              </Route>
            </Route>
          </Route>
        ))}

        <Route path={'/*'} element={<Navigate to={`${MAIN_PATH}`} />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path={`${AUTH_PATH}`} element={<LoginPage />} />
      <Route path={`${AUTH_MONITORING_PATH}/*`} element={<MonitoringLogin />} />
      <Route path={'/*'} element={<Navigate to={AUTH_PATH} />} />
    </Routes>
  );
};
