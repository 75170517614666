import { memo } from 'react';
import styles from './filter-checkbox.module.scss';
import { FilterCheckboxProps } from './filter-checkbox.types';
import { getCssVariableName } from '../../../utils';


export const FilterCheckbox = memo<FilterCheckboxProps>(({
  handleChange,
  isChecked  = false,
  leftBorderColor = '',
  children,
  onClick,
  style,
  disabled = false,
  className = '',
}: FilterCheckboxProps): JSX.Element => {
  return (
    <li
      onClick={onClick}
      className={`${styles.container} ${className}`}>
      <label
        className={`${styles.label} ${isChecked ? styles.activeLabel : ''}`}
        style={{ ...style }}
      >
        {children}
        <input
          disabled={disabled}
          checked={isChecked}
          onChange={()=> handleChange()}
          className={styles['label-input']}
          type="checkbox" />
        <span className={styles['label-checkmark']} />
        {
          !!leftBorderColor && (
            <span
              className={styles['label-left-border']}
              style={{ backgroundColor: styles[getCssVariableName(leftBorderColor)] }}
            />
          )
        }
      </label>
    </li>
  );
});
