import { SetStateAction, useEffect, useState } from 'react';
import { ObjectInfoBox } from '../object-info-box';
import { SidebarSelectEditFieldProps } from './sidebar-select-edit-field.types';
import { Button, Input, PTag } from '../common';
import { ObjectInfoCard } from '../object-info-card/object-info-card';
import { IconProgress } from '../sidebar-filter/progress-icon';
import { SelectInput } from '../common/select-input/select-input';
 

export const SidebarSelectEditField = ({
  editorMode,
  title,
  type,
  values,
  content,
  onChangeObject,
  objectId,
  isLoading,
  inputType = 'text',
  inputComponent = 'select',
}: SidebarSelectEditFieldProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  
  const [value, setValue] = useState<any>(() => content);
  
  useEffect(() => {
    setValue(content);
  }, [content]);
  
  const handleSave = () => {
    onChangeObject({ id: objectId, value, type }).then(r => {
      setIsEditing(false);
    }).catch(() => {
      setValue(content);
    });
  };
  
  const viewContent = values && values.find(el => el.id === Number(content));
  
  
  const selectRenderEdit = (inputComponent: string) => {
    switch (inputComponent) {
      case 'select':
        return (
          <SelectInput value={value} onChange={(e) => setValue(e.target.value)}>
            {values && values.map((item) => {
              return <option key={item.id} value={item.id}>{item.text}</option>;
            })}
          </SelectInput>
        );
      case 'input':
      default:
        return (
          <Input value={value} onChange={(event: { target: { value: SetStateAction<string | undefined>; }; }) => setValue(event.target.value)} />
        );
    }
  };
  const selectRenderContent = (inputComponent: string) => {
    switch (inputComponent) {
      case 'select':
        return viewContent?.text ? viewContent.text : 'Не установлено';
      case 'input':
      default:
        return value ? value : 'Не установлено';
    }
  };
  
  return (
    <>
      {
        isEditing ? (
          <ObjectInfoCard 
            title={
              <PTag
                type={'subheader'}
                weight={1}
                style={{margin: 0}}
              >{title}
              </PTag>
            } 
            footer={<Button
              elementType={'button'}
              typeBtn={'secondary'}
              children={'Сохранить'}
              showLeftIcon
              disabled={isLoading}
              onClick={handleSave}
            />} 
          >
            {isLoading ? <IconProgress stroke={'#000000'} /> : selectRenderEdit(inputComponent)}
          </ObjectInfoCard>
        ) : (
          <ObjectInfoBox
            editorMode={editorMode}
            title={title}
            isLoading={isLoading}
            content={(selectRenderContent(inputComponent))}
            type={type}
            onEditField={() => setIsEditing(true)}
          />
        )
      }
    </>
  );
};
