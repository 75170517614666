import styles from './password-input.module.scss';
import type { PasswordInputProps } from './password-input.types';
import { useState } from 'react';
import { SpanTag } from '../text';


export const PasswordInput = ({
  error = '',
  label = '',
  disabled = false,
  ...props
}: PasswordInputProps): JSX.Element => {
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);

  return (
    <label className={styles['input-field-label']}>
      <SpanTag
        className={`${styles['input-field-label-text']} ${error ? styles.error : ''}`}
        type={'subheader'}
        weight={1}
      >{label}</SpanTag>
      <span className={styles['input-field-input-wrapper']}>
        <input
          name="password"
          type={isPasswordVisible ? 'text' : 'password'}
          className={`${styles['input-field-input']} ${error ? styles.error : ''}`}
          disabled={disabled}
          autoComplete={'current-password'}
          {...props}
          onFocus={(e) => {
            props.onChange && props.onChange(e);
          }}
        />
        <i
          className={`${styles['input-field-input-icon-password']} ${
            isPasswordVisible
              ? styles['input-field-input-icon-password-hide']
              : styles['input-field-input-icon-password-show']
          }`}
          onClick={() => setPasswordVisible && setPasswordVisible(!isPasswordVisible)}
        />
      </span>
    </label>
  );
};
