import React, { useLayoutEffect, useState } from 'react';
import { filterObjects, setClusterMarkerView, useAppDispatch } from '../../state';
import {
  useEditorModeStateSelector,
  useFavoriteObjectsSelector,
} from '../../state/selector/user-selectors';
import { useAppObjects } from '../../state/selector/selector';
import { SidebarFilter } from './sidebar-filter';
import { useEnableObjectFiltersSelector } from '../../state/selector';
import { BottomSheet } from '../common/bottom-sheet';
import { SidebarContainer } from '../common';
import {
  useSelectedObjectSelector,
  useSidebarState,
} from '../../history-state/selectors';
import { useBreakpointsComparison } from '../../hooks';
import { ClusterSwitcher } from '../cluster-switcher';
import { useIsClusterMarkerViewSelector } from '../../state/selector/user-selectors';
import styles from './sidebar-filter.module.scss';
import { ScreenSize } from '../../constants';

export const SidebarFilterWrapper = () => {
  const dispatch = useAppDispatch();
  const isEditorMode = useEditorModeStateSelector();
  const objects = useAppObjects();
  const filters = useEnableObjectFiltersSelector();
  const isTablet = useBreakpointsComparison(ScreenSize.TP);
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);
  const [loading, setLoading] = useState<boolean>(false);
  const favorites = useFavoriteObjectsSelector();
  const [isSidebarOpen, setSidebarOpen] = useSidebarState();
  const selectedObjectId = useSelectedObjectSelector();
  const isClusterMarkerViewSelected = useIsClusterMarkerViewSelector();

  const submitFiltersHandler = (
    selectedMacroRegions: number[],
    selectedProgram: number[],
    selectedStartOfBuilding: number[],
    selectedTypeOfWork: number[],
    selectedRealisationStatus: number[],
    showOnlyFavorite: boolean,
    // DEMO /////////////////////////////////////
    demoObjectTypes: number[],
    demoFund: number[],
    // DEMO /////////////////////////////////////
  ) => {
    dispatch(
      filterObjects({
        macro_region: selectedMacroRegions,
        program: selectedProgram,
        start_of_building: selectedStartOfBuilding,
        type_of_work: selectedTypeOfWork,
        status: selectedRealisationStatus,
        showOnlyFavorite,
        demoObjectTypes,
        demoFund,
      }),
    );
  };

  useLayoutEffect(() => {
    dispatch(
      filterObjects({
        macro_region: [],
        program: [],
        start_of_building: [],
        type_of_work: [],
        status: [],
        showOnlyFavorite: false,
        demoObjectTypes: [],
        demoFund: [],
      }),
    );
  }, [isEditorMode, dispatch]);

  useLayoutEffect(() => {
    setLoading(!(objects && objects.length > 0));
  }, [objects]);

  return (
    <>
      {isSidebarOpen && !selectedObjectId && !isTablet && (
        <SidebarContainer>
          <SidebarFilter
            objects={objects}
            favorites={favorites}
            editorMode={isEditorMode}
            loading={loading}
            onFilter={submitFiltersHandler}
            externalShowOnlyFavorite={filters.showOnlyFavorite}
            externalMacroRegions={filters.macro_region}
            externalStartOfBuilding={filters.start_of_building}
            externalTypeOfWork={filters.type_of_work}
            externalProgram={filters.program}
            externalRealisationStatus={filters.status}
            // DEMO ////////////////////////////////
            externalDemoObjectTypes={filters.demoObjectTypes}
            externalDemoFund={filters.demoFund}
          >
            {isTabletLandscape && (
              <ClusterSwitcher
                className={styles['body-cluster-swither']}
                checked={!isClusterMarkerViewSelected}
                onToggle={() =>
                  dispatch(setClusterMarkerView(!isClusterMarkerViewSelected))
                }
              />
            )}
          </SidebarFilter>
        </SidebarContainer>
      )}

      <BottomSheet
        open={isSidebarOpen && !selectedObjectId && isTablet}
        onDismiss={() => {
          setSidebarOpen(false);
        }}
        initialHeight={'mid'}
        closeButton={false}
        blocking={false}
        isActive
      >
        <SidebarFilter
          objects={objects}
          favorites={favorites}
          editorMode={isEditorMode}
          loading={loading}
          onFilter={submitFiltersHandler}
          externalShowOnlyFavorite={filters.showOnlyFavorite}
          externalMacroRegions={filters.macro_region}
          externalStartOfBuilding={filters.start_of_building}
          externalTypeOfWork={filters.type_of_work}
          externalProgram={filters.program}
          externalRealisationStatus={filters.status}
          // DEMO ////////////////////////////////
          externalDemoObjectTypes={filters.demoObjectTypes}
          externalDemoFund={filters.demoFund}
        >
          <ClusterSwitcher
            className={styles['body-cluster-swither']}
            checked={!isClusterMarkerViewSelected}
            onToggle={() => dispatch(setClusterMarkerView(!isClusterMarkerViewSelected))}
          />
        </SidebarFilter>
      </BottomSheet>
    </>
  );
};
