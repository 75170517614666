import { useNavigate } from 'react-router-dom';
import { useAppDispatch, logout } from '../../../state'; 
import { Button } from '../../common';


export const ProfileMenuUserPageDesktop = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        elementType={'button'}
        size={'m'}
        typeBtn={'primary'}
      >
            Закрыть
      </Button>
      <Button
        elementType={'button'}
        size={'m'}
        typeBtn={'danger'}
        showLeftIcon
        onClick={() => dispatch(logout())}
        leftIcon={<i className={'icon icon-log-out icon-middle'} />}
      >
            Выход
      </Button>
    </>
  );

};
